import React,{useState, useEffect} from 'react'
// import {Link} from 'react-router-dom'
import { isUserExist } from '../../../services/userServices';


const UsersList = () => {
    const [usersList, setUsersList] = useState([]);
    useEffect( () => {
        async function fetchData() {
            const response = await isUserExist();
            setUsersList(response.data)
          }
          fetchData();
      },[])
  return (
    <div className='py-5 px-4'>
        <div className='bg-slate-200  py-3 px-5  flex justify-between'>
            <p className='text-lg font-semibold'>
            Users List
            </p> 
        </div>
        <div className="overflow-x-auto relative shadow-md ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs font-bold text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            Avatar
                        </th>
                        <th scope="col" className="py-3 px-6">
                            User Name
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Email
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {usersList && usersList.map( (u,i) => (
                        <tr key={i} className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td className='flex justify-center'>
                        <img src={u.avatar} alt="avatar" className='h-[50px] w-[50px] p-2 rounded-full' />

                        </td>
                        <td className="py-4 px-6">
                            {u.username}
                        </td>
                        <td className="py-4 px-6">
                            {u.email}
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default UsersList