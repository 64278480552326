import React from 'react'
import MenuOptions from "../../sections/menuOptions"
import ProgramNav from "./programNav"
import {programs} from '../../../data/programs'
import { useParams } from 'react-router-dom'
import _ from "lodash"


const FacultyPage = ({children}) => {
  const {slug} = useParams()

  const program = _.find(programs[0].lists, ['slug', slug])
  const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';
  const md = backet + '/others/medical+students.PNG';

  return (
      <div className=''>
        <div className='relative  xl:h-[558px] md:h-[558px]'>
            <img src={program.img} className='w-full  object-cover bg-center xl:h-[558px] md:h-[558px] h-[300px]' alt="cover"/>
            <div className='absolute  top-[75%]  w-full md:h-[15%] xl:h-[15%] h-[22%] bg-white opacity-70 flex justify-center items-center'></div>
            <p className='uppercase   md:text-6xl xl:text-7xl xs:text-xl sm:text-3xl font-bold text-[#0843aafe] text-center absolute top-[78%] w-full'>
                {program.program}
              </p>
        </div>
        <div className='bg-[#0843aafe] h-[2px] w-full'></div>
          <div className="h-full flex  flex-col justify-center w-full  overflow-hidden  text-[#0843aafe] md:space-x-2 ">
            <div className='flex justify-center h-auto '>
            <ProgramNav id={slug} />
            </div>
            <div className=' w-full h-auto  font-sans sm:mx-4 xs:mx-4'>
            <div className=''>
              {children}
            </div>
            </div>
            </div>
      </div>
   
  )
}

export default FacultyPage