import React from 'react'

const Commitees = () => {

  return (
    <div className=' h-screen'>
      <div className='flex flex-col items-center h-screen justify-center py-20'>

        <p className='font-semibold text-3xl my-3'>This page is under construction..</p>
        <p>Commitees page is commig soon....</p>
      <div className='flex justify-center items-center w-full '>
          
      </div>
      </div>
    </div>
  )
}

export default Commitees