import React, { useState } from 'react'
import {MdPayment} from 'react-icons/md'
import {BiDollar} from 'react-icons/bi'
import {FaUserGraduate} from 'react-icons/fa'




const Admission = ({children}) => {
    const menus = [
        { id: 1, menu: "Tuition Fees For Local Students", link: "localtuition", icon: <BiDollar/> },
        { id: 2, menu: "Tuition Fees For International Students", link: "intenationaltuition" , icon: <BiDollar/>},
        { id: 3, menu: "Payment Procedure" , link: "paymentprocedure", icon: <MdPayment/>},
        { id: 4, menu: "Alumni" , link: "alumni", icon: <FaUserGraduate />},
      ]
      
 

  return (
      <div className='grid grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 sm:gap-y-5 xs:gap-y-5 gap-2 justify-items-center items-center  w-full  mt-5 mx-5 py-3 px-3'>
         {
          menus.map( m => (
            <div key={m.id} className='flex flex-col justify-center items-center w-[300px]'>
                <p className='  py-3 px-3 rounded-full text-white text-[50px] bg-[#0863AA] w-fit'>
                {m.icon}
                </p>
              <a target="_blank" rel="noopener noreferrer"
                key={m.id} href={`/admision/${m.link}`}
                className=" font-sans text-center text-sm  text-[#0863AA]  font-serif w-fit font-semibold md:text-xl  py-3 md:px-2  ">
                    {m.menu}
              </a>
              </div>
          ))
        }
        
        </div>
  )
}

export default Admission