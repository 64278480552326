import _ from "lodash";

import HM from "../assets/images/ourpartners/hu.png";
import MU from "../assets/images/ourpartners/mu.jpeg";
import a_u_w_cover from "../assets/images/ourpartners/a_u_w_cover.jpg";
import PRiME_Wordmark from "../assets/images/ourpartners/PRiME_Wordmark-PMS+655+C_next_gen-01.png";
import uom from "../assets/images/ourpartners/uom.png";
import uoj from "../assets/images/ourpartners/uoj.png";
import ki from "../assets/images/ourpartners/ki_sweeden.png";
import palermo from "../assets/images/ourpartners/palermo_university.png";
import IUA from "../assets/images/ourpartners/IUA.jpg";
import usim from "../assets/images/ourpartners/usim.png";
import cust from "../assets/images/ourpartners/Cairo_University_Crest.png";
import Benha from "../assets/images/ourpartners/Benha_University_Logo.png";
import ain from "../assets/images/ourpartners/Ain_Shams_logo.png";
import mmust from "../assets/images/ourpartners/mmust.jpg";
import bulent from "../assets/images/ourpartners/Bülent_Ecevit_University_Logo.png";
import cankiri from "../assets/images/ourpartners/cankiri.png";
import kenyata from "../assets/images/ourpartners/Kenyatta_University_logo.png";
import kazan from "../assets/images/ourpartners/kazan_university.png";
import au_egypt from "../assets/images/ourpartners/au_egypt.jpeg";
import meu from "../assets/images/ourpartners/meu.jpg";
import ninu from "../assets/images/ourpartners/ninu.jpeg";
import saglik from "../assets/images/ourpartners/saglik-bilimleri-logo-en.png";
import umea from "../assets/images/ourpartners/umea-university.png";
import sust from "../assets/images/ourpartners/sust.jpg";
import uppsala from "../assets/images/ourpartners/Uppsala_universitet_logo.jpg";
import uqu from "../assets/images/ourpartners/uqu.png";

import comsats from "../assets/images/ourpartners/comsats.jpg";
import isuislamabad from "../assets/images/ourpartners/isuislamabad.jpg";
import bahria from "../assets/images/ourpartners/bahria-universit.png";
import djabuti from "../assets/images/ourpartners/djabuti.jpg";
import JigJiga from "../assets/images/ourpartners/jigjiga univesity.jpg";
import genthernburg from "../assets/images/ourpartners/genthernburg.png";
import havana from "../assets/images/ourpartners/havana.png";
import Khartoum from "../assets/images/ourpartners/khartum.png";
import nairobi from "../assets/images/ourpartners/Uni-nairobi.jpg";
import arid from "../assets/images/ourpartners/arid-agriculture-university.png";
import agakhan from "../assets/images/ourpartners/agakhan.png";
import istanbul from "../assets/images/ourpartners/istanbul-medinia.jpg";
import OMU_logo from "../assets/images/ourpartners/omu-logo.jpg";

// organizational partners.
import comstech from "../assets/images/ourpartners/organizations/comstech.png";
import mgfoundation from "../assets/images/ourpartners/organizations/mgfoundation.png";
import usaid from "../assets/images/ourpartners/organizations/usaid.png";
import hormuudSalamFoundation from "../assets/images/ourpartners/organizations/hormuudSalamFoundation.png";
import shag from "../assets/images/ourpartners/organizations/shag.png";
import who from "../assets/images/ourpartners/organizations/who.png";
import iom from "../assets/images/ourpartners/organizations/iom.png";
import tika from "../assets/images/ourpartners/organizations/tika.png";
import turkish_embassy from "../assets/images/ourpartners/organizations/turkish-embassy.jpg";
import unicef from "../assets/images/ourpartners/organizations/unicef.png";

//local institutions.

import Abrar from "../assets/images/ourpartners/local/Abrar Univerity.jpg";
import Aden from "../assets/images/ourpartners/local/Aden Adde International University.jpg";
import Alarabiya from "../assets/images/ourpartners/local/Al Arabia University.png";
import Alhayat from "../assets/images/ourpartners/local/Al Hayaat University.jpg";
import Alimra from "../assets/images/ourpartners/local/Al Imra International University.jpg";
import Almaas from "../assets/images/ourpartners/local/Almaas University.jpg";
import Capital from "../assets/images/ourpartners/local/Capital University.jpeg";
import Central from "../assets/images/ourpartners/local/Central University For Science and Technology.jpg";
import City from "../assets/images/ourpartners/local/City University.jpg";
import Daha from "../assets/images/ourpartners/local/Daha International University.jpg";
import Darul from "../assets/images/ourpartners/local/Darul Hikma University.png";
import Frontier from "../assets/images/ourpartners/local/Frontier University.jpg";
import Gedo from "../assets/images/ourpartners/local/Gedo University.jpg";
import Golden from "../assets/images/ourpartners/local/Golden University.jpg";
import Himilo from "../assets/images/ourpartners/local/Himilo University.jpg";
import Hope from "../assets/images/ourpartners/local/Hope University.jpg";
import hormuud from "../assets/images/ourpartners/local/hormuud.jpg";
import Horseed from "../assets/images/ourpartners/local/Horseed University.png";
import Imamshafi from "../assets/images/ourpartners/local/Imam Shafi i University.jpg";
import IMAM from "../assets/images/ourpartners/local/IMAM University.jpg";
import Islamic from "../assets/images/ourpartners/local/Islamic University.jpg";
import Jamhuriya from "../assets/images/ourpartners/local/Jamhuriya University.jpg";
import Jazeera from "../assets/images/ourpartners/local/Jazeera University.png";
import Jobkey from "../assets/images/ourpartners/local/Jobkey University.jpg";
import Juba from "../assets/images/ourpartners/local/Juba University of Science and Technology.jpg";
import Kowneyn from "../assets/images/ourpartners/local/Kowneyn University.png";
import Modern from "../assets/images/ourpartners/local/Modern University for Science and Technology.jpg";
import Mogadishu_university from "../assets/images/ourpartners/local/Mogadishu-university.png";
import Pan from "../assets/images/ourpartners/local/Pan Africa International University.jpg";
import Plasma from "../assets/images/ourpartners/local/Plasma.png";
import puntlandState from "../assets/images/ourpartners/local/puntlandState.png";
import Salaam from "../assets/images/ourpartners/local/Salaam University.png";
import Simad from "../assets/images/ourpartners/local/Simad University.jpg";
import SIU from "../assets/images/ourpartners/local/SIU.png";
import somaliNationalUniversity from "../assets/images/ourpartners/local/somaliNationalUniversity.jpg";
import Somaville from "../assets/images/ourpartners/local/Somaville University.jpg";
import UNISO from "../assets/images/ourpartners/local/UNISO.jpg";
import ZAMZAM from "../assets/images/ourpartners/local/ZAMZAM University.png";
import jobba from "../assets/images/ourpartners/local/jobba-logo.png";
import ReadSea from"../assets/images/ourpartners/local/Readsea University Logo.png";
import Kismayo from "../assets/images/ourpartners/local/kismayo university Logo.png";
import qudus from "../assets/images/ourpartners/local/qudus_university.jpeg";
import Hiiraan from "../assets/images/ourpartners/local/Hiiraan University logo.jpeg";
import DaaruSalaam from "../assets/images/ourpartners/local/Daarusalaam University Logo.png";
// import EastAfrica from "../assets/images/ourpartners/local/eastAfricaUniversity.png";
// import Galkayo from "../assets/images/ourpartners/local/Galkayo University.png";
// import Baidoa from "../assets/images/ourpartners/local/biodabo.png";
// import Sombridge from "../assets/images/ourpartners/local/sombridge university logo.png";
// import Ainul from "../assets/images/ourpartners/local/Ainul Hayaat International University logo.png";
// import LowerShabeelle from "../assets/images/ourpartners/local/Lower Shabeele University logo.png";
// import Sahal from "../assets/images/ourpartners/local/sahal university logo.png";
// import UniversityofSouthern from "../assets/images/ourpartners/local/University of southern somalia Logo.png";

//accrditations
import wwhts from "../assets/images/ourpartners/accreditations/1.jpg";
import CUFCE from "../assets/images/ourpartners/accreditations/2.jpg";
import ACBSP from "../assets/images/ourpartners/accreditations/3.jpg";
import USHEAC from "../assets/images/ourpartners/accreditations/4.jpg";
import AICE from "../assets/images/ourpartners/accreditations/6.png";


export const up = [
  { id: 1, name: "Hamline University", logo: HM, link: "https://www.hamline.edu/" },
  {
    id: 2,
    name: "Makerere University",
    logo: MU,
    link: "https://www.mak.ac.ug/",
  },
  {
    id: 3,
    name: "Ahfad university for women",
    logo: a_u_w_cover,
    link: "https://www.auw-sd.com/",
  },
  { id: 4, name: "Prime next-generation precision medicine", logo: PRiME_Wordmark, link: "https://www.prime.utoronto.ca/" },
  { id: 5, name: "University of Minnesota", logo: uom, link: "" },
  {
    id: 6,
    name: "University of gezira",
    logo: uoj,
    link: "https://www.gezirauniversity.net/",
  },
  { id: 7, name: "karolinska institutet", logo: ki, link: "https://ki.se/" },
  {
    id: 8,
    name: "International University of Africa",
    logo: usim,
    link: "https://www.usim.edu.my/",
  },
  {
    id: 9,
    name: "Università degli studi di palermo",
    logo: palermo,
    link: "https://www.unipa.it/",
  },
  { id: 10, name: "", logo: IUA, link: "" },
  {
    id: 11,
    name: "Cairo University",
    logo: cust,
    link: "https://cu.edu.eg/Home",
  },
  {
    id: 12,
    name: "Benha University",
    logo: Benha,
    link: "https://bu.edu.eg/en/",
  },
  {
    id: 13,
    name: "Ain Shams University",
    logo: ain,
    link: "https://www.asu.edu.eg/ar",
  },
  {
    id: 14,
    name: "Masinde Muliro University of Science and Technology",
    logo: mmust,
    link: "https://mmust.ac.ke/",
  },
  {
    id: 15,
    name: "Zonguldak Bülent Ecevit University",
    logo: bulent,
    link: "https://en.beun.edu.tr/",
  },
  {
    id: 16,
    name: "Çankırı Karatekin University",
    logo: cankiri,
    link: "https://www.karatekin.edu.tr/",
  },
  {
    id: 17,
    name: "kenyatta Nniversity",
    logo: kenyata,
    link: "https://www.ku.ac.ke/",
  },
  {
    id: 18,
    name: "Kazan Federal University",
    logo: kazan,
    link: "https://eng.kpfu.ru/",
  },
  {
    id: 19,
    name: "Alexandria University",
    logo: au_egypt,
    link: "https://www.alexu.edu.eg/index.php/en/",
  },
  { id: 20, name: "Middle East University", logo: meu, link: "https://www.meu.edu.jo/" },
  {
    id: 21,
    name: "New Ismailia National University",
    logo: ninu,
    link: "http://sis.ninu.edu.eg/",
  },
  {
    id: 22,
    name: "University of Health Sciences(UoHS)",
    logo: saglik,
    link: "https://uohs.edu.so/",
  },
  {
    id: 23,
    name: "Umeå University",
    logo: umea,
    link: "https://www.umu.se/en/",
  },
  {
    id: 24,
    name: "Sudan University of Science and Technology",
    logo: sust,
    link: "https://www.sustech.edu/",
  },
  {
    id: 25,
    name: "Uppsala University",
    logo: uppsala,
    link: "https://www.uu.se/en",
  },
  {
    id: 26,
    name: "Umm al-Qura University",
    logo: uqu,
    link: "https://uqu.edu.sa/en",
  },
  {
    id: 27,
    name: "COMSATS University Islamabad (CUI)",
    logo: comsats,
    link: "https://www.comsats.edu.pk/",
  },
  {
    id: 28,
    name: "International Islamic University (IIUI)",
    logo: isuislamabad,
    link: "https://www.iiu.edu.pk/",
  },
  {
    id: 29,
    name: "Bahria University",
    logo: bahria,
    link: "https://bahria.edu.pk/",
  },
  {
    id: 30,
    name: "University of Djibouti",
    logo: djabuti,
    link: "https://www.univ.edu.dj/",
  },
  {
    id: 31,
    name: "Jigjiga University",
    logo: JigJiga,
    link: "https://jju.edu.et/",
  },
  {
    id: 32,
    name: "University of Gothenburg",
    logo: genthernburg,
    link: "https://www.gu.se/en",
  },
  { id: 33, name: "University of Havana", logo: havana, link: "" },
  {
    id: 34,
    name: "University of Khartoum",
    logo: Khartoum,
    link: "https://www.uofk.edu/",
  },
  {
    id: 35,
    name: "University of Nairobi",
    logo: nairobi,
    link: "https://www.uonbi.ac.ke/",
  },
  {
    id: 36,
    name: "Pir Mehr Ali Shah Arid Agriculture University",
    logo: arid,
    link: "https://www.uaar.edu.pk/index.php",
  },
  {
    id: 37,
    name: "Aga Khan University",
    logo: agakhan,
    link: "https://www.aku.edu/Pages/home.aspx",
  },
  {
    id: 39,
    name: "Ondokuz Mayıs University",
    logo: OMU_logo,
    link: "https://www.omu.edu.tr/en",
  },
  {
    id: 38,
    name: "Istanbul Medeniyet University",
    logo: istanbul,
    link: "https://www.medeniyet.edu.tr/en",
  },
];

export const op = [
  { id: 1, name: "", logo: comstech, link: "" },
  { id: 2, name: "", logo: usaid, link: "" },
  { id: 3, name: "", logo: mgfoundation, link: "" },
  { id: 4, name: "", logo: hormuudSalamFoundation, link: "" },
  { id: 5, name: "", logo: shag, link: "" },
  { id: 5, name: "", logo: who, link: "" },
  { id: 6, name: "", logo: iom, link: "" },
  { id: 7, name: "", logo: tika, link: "" },
  { id: 8, name: "", logo: turkish_embassy, link: "" },
  { id: 9, name: "", logo: unicef, link: "" },
];

// export const lp = [
//   { id: 1, name: "Hormuud University", logo: hormuud, link: "https://hu.edu.so/" },
//   { id: 2, name: "Somali National University (SNU)", logo: somaliNationalUniversity , link: "https://snu.edu.so/"},
//   { id: 3, name: "Mogadishu University", logo: Mogadishu_university, link: "" },
//   { id: 4, name: "Puntland State University", logo: UNISO , link: ""},
//   { id: 6, name: "University", logo: SIU, link: "" },
//   { id: 7, name: "University", logo: ZAMZAM, link: "" },
//   { id: 5, name: "University", logo: Simad , link: ""},
//   { id: 8, name: "University", logo: Somaville, link: "" },
//   { id: 9, name: "University", logo: Salaam, link: "" },
//   { id: 10, name: "University", logo: puntlandState, link: "" },
//   { id: 11, name: "University", logo: Plasma, link: "" },
//   { id: 12, name: "University", logo: Pan, link: "" },
//   { id: 13, name: "University", logo: Modern, link: "" },
//   { id: 14, name: "University", logo: Kowneyn, link: "" },
//   { id: 15, name: "University", logo: Juba, link: "" },
//   { id: 16, name: "University", logo: Jobkey, link: "" },
//   { id: 17, name: "University", logo: Jazeera, link: "" },
//   { id: 18, name: "University", logo: Jamhuriya, link: "" },
//   { id: 19, name: "University", logo: Islamic, link: "" },
//   { id: 20, name: "University", logo: IMAM , link: ""},
//   { id: 21, name: "University", logo: Imamshafi, link: "" },
//   { id: 22, name: "University", logo: Horseed, link: "" },
//   { id: 23, name: "University", logo: Hope , link: ""},
//   { id: 24, name: "University", logo: Himilo, link: "" },
//   { id: 25, name: "University", logo: Abrar, link: "" },
//   { id: 26, name: "University", logo: Aden, link: "" },
//   { id: 27, name: "University", logo: Alarabiya, link: "" },
//   { id: 28, name: "University", logo: Alhayat, link: "" },
//   { id: 29, name: "University", logo: Alimra , link: ""},
//   { id: 30, name: "University", logo: Almaas, link: "" },
//   { id: 31, name: "University", logo: Capital, link: "" },
//   { id: 32, name: "University", logo: Central, link: "" },
//   { id: 33, name: "University", logo: City, link: "" },
//   { id: 34, name: "University", logo: Daha, link: "" },
//   { id: 35, name: "University", logo: Darul, link: "" },
//   { id: 36, name: "University", logo: Frontier, link: "" },
//   { id: 37, name: "University", logo: Gedo , link: ""},
//   { id: 38, name: "University", logo: Golden , link: ""},
// ];

export const lp1 = [
  {
    id: 10,
    name: "Hormuud University",
    logo: hormuud,
    country: "Somalia",
    desc: `Hormuud University is a private, Non-profit institution, established by elite members of Hormuud Telecommunication Company in 2010, majoring in the field of engineering and technology in Somalia. Hormuud University has a member of a number of associations, local, regional, and international.`,
    link: "https://hu.edu.so/",
},
{ 
  id: 2000,
   name: "Somali National University (SNU)", 
   logo: somaliNationalUniversity , 
   link: "https://snu.edu.so/"
  },
{
id: 2,
name: "Mogadishu University",
logo: Mogadishu_university,
country: "Somalia",
desc: `Mogadishu University The idea to establish an educational institution started in June 1997, after which time it took five years to develop and open the university. It opened in September 1997 as a non-governmental, non-profit institution for higher learning, governed by a Board of Trustees and a University Council.`,
link:"https://mu.edu.so/",
},
{
id: 3,
name: "Himilo University",
logo: Himilo,
country: "Somalia",
desc: `Himilo University is a national , non profit , cost recovering higher education established in Mogadishu, the capital city of Somalia. it was founded in 2010 by unique somali intelectuals though to transfer new world civilized education to the country and developed Somali education specially higher education and also has power of creativity with support and encouragement of somali intellectuals inside and outside the country.`,
link:"https://himilouniversity.edu.so/",
},
{
id: 4,
name: "Horseed International University",
logo: Horseed,
country: "Somalia",
desc: `Horseed International University (HIU) is Non Profit, Private University in Somalia. It was founded in 2010 by some of the Somali Scholars .HIU is an institution of higher education and It provides high quality with practical instruction at the under graduate and Postgraduate partnership with Foreigner Universities.`,
link:"https://hiu.edu.so/",
},
{
id: 5,
name: "Arabia University",
logo: Alarabiya,
country: "Somalia",
desc: `The Arabia University in Somalia is: a private educational institution established in the academic year 2017/2018 AD, corresponding to 1438/1439 AD. The university fully accredits the study of the Arabic language and Islamic studies, as the university becomes one of the best universities contributing to the construction of Islamic sciences education in Somalia.`,
link:"https://alarabia.edu.so/,"
},
{ 
  id: 1000, 
  name: "University", 
  logo: puntlandState, 
  link: "https://psu.edu.so/" },
{
id: 6,
name: "SIMAD University",
logo: Simad,
country: "Somalia",
desc: `Founded in 1999, SIMAD University is a renowned private institution located in Mogadishu, Somalia. Over the years, it has built a strong reputation for offering exceptional higher education in the region. The university boasts a diverse array of undergraduate.`,
link:"https://simad.edu.so/",
},
{
id:7,
name:"Hiiraan university",
logo:Hiiraan,
country:"somalia",
desc:`HU was initiative of Hiranian Diaspora in North America and it was established in December 2003 with support of Hiiraan University Foundation team. However, after long fund raising campaign throughout the North America, Europe, East Africa and in Hiran region, Somalia, finally HU started its opened her office in 2005 in Beledweyne`,
link:"https://www.hiiraanuniversity.edu.so/",

},
{
  id: 1,
  name: "Plasma University (PU) ",
  logo: Plasma,
  country: "Somalia",
  desc: `Plasma University (PU)  was opened in 2005 as a private non-profit higher education institution in Somalia. The university offers academic programs linking the problems of theoretical education with real practical problems.`,
  link:"https://plasma.edu.so/",
},

{
id: 11,
name: "Abrar University",
logo: Abrar,
country: "Somalia",
desc: `Abrar University is a higher educational institution located in Somalia, established on June 26, 2014, by the Imam Shafi’i Foundation. It is dedicated to offering innovative undergraduate, postgraduate, and continuing professional education.`,
link:"https://abrar.edu.so/",
},
{
id: 12,
name: "IMAM University (IU)",
logo: IMAM,
country: "Somalia",
desc: `IMAM University (IU) is a nonprofit educational institution based in Somalia. It was founded in 2012 by a group of Somali scholars in order to fill the gaps left by the lack of a central government over the decades.`,
link:"https://imamuniversity.edu.so/",
},
{
id: 14,
name: "Capital University",
logo: Capital,
country: "Somalia",
desc: `Capital University of Somalia is non-state, non-profit educational institution,established  the Capital University on 14th May, 2013. supported by the Capital Company ltd” aimed to foster and develop knowledge through engagement of youth in education and research activities`,
link:"https://capitaluniversity.edu.so/",
},
{
id:15,
name:"Daaru Salaam University (DSU)",
logo:DaaruSalaam,
country:"somalia",
desc:`Daaru Salaam University (DSU) was established in January 2013 by a consortium of Somali Scholars, with its headquarters located in Mogadishu, Somalia. The primary objective of the university is to provide high-quality education and engage in cutting-edge research on a global scale. DSU combines state-of-the-art infrastructure with an innovative approach to teaching and research, while also upholding a rich tradition of service and excellence.`,
link:"https://dsu.edu.so/",

},
{
id:16,
name:"Red Sea University",
logo:ReadSea,
country:"somalia",
desc:`Red Sea University (RSU) is a non-governmental and non-profit university, with the main campus in Bosaso and a branch campus in Galkio, both in the Puntland state of Somalia.Red Sea University was established in 2008 and currently offers twenty-one (21) undergraduate programs that are under seven (7) faculties and a wide range of postgraduate programs (Diploma, Master, and PhD).`,
link:"https://rsu.edu.so/",

},
{
id: 17,
name: "Central University for Science and Technology (CUST) ",
logo: Central,
country: "Somalia",
desc: ` Central University for Science and Technology (CUST) private Somali high educational institution based in south central regions of Somalia with its main office in Beledweyn. CUST was founded on 10th of July 2014 by an assemblage of Somali citizens with the aim of providing high quality educational service in different faculties as well as bridging the community needs for high education in Somalia.`,
link:"https://cust.edu.so/",
},
{
id: 18,
name: "Aden Adde International University (AAIU)",
logo: Aden,
country: "Somalia",
desc: `Aden Adde International University (AAIU) is a non-profit institution, registered with the Ministry of Higher Education of Somalia. The conception of AAIU took place in late 2015 with a group of Somali intellectuals lead by Prof Dr. Osman Aden, a prominent physician and geneticist who has spent his entire life in the service of his people.`,
link:"https://aaiu.edu.so/",
},
{
id: 19,
name: "QUDUS University",
logo: qudus,
country: "Somalia",
desc: `Qudus University Was Established In The 2013 February By A Group Of Intellectuals Well Versed In The Field Of Education So As To Promote The Young Generation In Tangible Knowledge And Boost Up Their Morals In Order To Engage The Learning And Progressing Talent Academic High Class Education For Their Future Aspects, So As The Self Reliance And Confidence Hence Attractive Climate, Good Atmosphere Of Line And An Environment Of Self Confidence`,
link:"https://qudusuniversity.edu.so/",
},
{
id: 20,
name: "Islamic University",
logo: Islamic,
country: "Somalia",
desc: `Islamic University  is a non-profit institution  was founded in Mogadishu on the 5th Rajab 1422 Hegira, corresponding to 22nd /9/2001 AD by the hands of the constellation dignified Somali intellectuals, under the vacuum of the Somalia central government and the collapse of all educational institutions.`,
link:"https://islamicuniversity.so/en/",
},
{
id: 21,
name: "Somali International University (SIU)",
logo: SIU,
country: "Somalia",
desc: `Somali International University (SIU) is a private non-profit community owned university located in Mogadishu, Somalia,The university has six faculties. Each faculty has several departments. SIU offers over 30 degree programs at undergraduate and postgraduate level. The postgraduate programs are in collaboration with Kenyatta University.`,
link:"https://siu.edu.so/",
},
{
id:22,
name:"Kismayo University",
logo:Kismayo,
country:"somalia",
desc:`Kismayo University was established in August 2005. The University is situated in the Southern City of Kismayo, Jubbaland in the Republic of Somalia, and 500 Km South of Mogadishu along the Kismayo-Mogadishu Road.`,
link:"https://ku.edu.so/",

},
{
id: 23,
name: "somaville university ",
logo: Somaville,
country: "Somalia",
desc: `Somaville University is a non-profit, non-governmental educational institution, it was established on 24th January 2013, legally operate under a REG No: WWHTS/XAG/0109/2015, with its Head Quarters at Mogadishu with a mission to contribute to society through the pursuit of education, learning, and research at the highest international levels of excellence`,
link:"https://www.somaville.edu.so/",
},
{
id: 24,
name: "Jazeera University",
logo: Jazeera,
country: "Somalia",
desc: `Jazeera University was established on February, 2010 in Mogadishu, Somalia by an elect group of Somali scholars. It is a higher education centre which is geared to create a knowledge based society..`,
link:"https://jazeerauniversity.edu.so/",
},
{
id: 26,
name: "Juba University of Science and Technology (JUSTECH)",
logo: Juba,
country: "Somalia",
desc: `Founded in 2012, the Jaamacadda Juba ee Cilmiga iyo Tiknolojiyada  is a private higher  education institution located in the urban setting of the metropolis of Mogadishu population range of 1,000,000-5,000,000 inhabitants Banaadir.`,
link:"https://justech.edu.so/",
},
{
id: 28,
name: "Zamzam University of Science and Technology (ZUST)",
logo: ZAMZAM,
country: "Somalia",
desc: `Zamzam University of Science and Technology (ZUST) is a Somali higher education institution founded in 2013 for the purpose of excelling human capacity needed in the nation. The university has main campus in Mogadishu, Somalia and also has campuses in the cities of Baidoa and Jowhar.`,
link:"https://zust.edu.so/",

},
{
id: 29,
name: "Hope University (HU)",
logo: Hope,
country: "Somalia",
desc: `Hope University (HU) is a national not-for-profit, cost recovering higher education establishment in Hargeisa, the capital of Somaliland. It was founded in 2008 by Najib Sheikh Abdulkarim. The institution aims to provide affordable higher learning opportunities in the fields of health, technology and management sciences.`,
link:"https://hope.edu.so/",
},
{
id: 30,
name: "Jubba University of Somalia (JUS)",
logo:jobba,
country: "Somalia",
desc: `.`,
link:"https://jussom.edu.so/",
},
{
id: 31,
name: "Modern university  ",
logo: Modern,
country: "Somalia",
desc: `The university is a private university with non-profit oriented that was founded different scholars who felt in-part with the environment that the education systems operating by the time is not enough on the need from the community. It was established in January 2012 with full objective of creating an institution that provides higher education level with fully potentiality focusing on the science and technology application methods,`,
link:"https://must.edu.so/",
},
{
id: 32,
name: "Jamhuriya University of Science and Technology (JUST",
logo: Jamhuriya,
country: "Somalia",
desc: `Jamhuriya University of Science and Technology (JUST) is private institution and was officially established in Mogadishu, Somalia, in 2011 by a group of Somali scholars and Intellectuals to fill the existing vacuum (in the quality) in the higher education system in Somalia by providing higher education of international standards.`,
link:"https://www.just.edu.so/",
},
{
id: 34,
name: "University of Somalia",
logo: UNISO,
country: "Somalia",
desc: `The institution was founded in 2005 by a group of independent scholars.[1] It is headquartered in downtown Mogadishu, and also has a branch in El-sha Biyaha, As of 2014, the university provides higher instruction to a student body of 6,000 pupils.`,
link:"https://www.uniso.edu.so/",
},
{
id: 36,
name: " Daha International University",
logo: Daha,
country: "Somalia",
desc: ` Daha International University of  Somalia is a non-profit academic institution and one of the most important institutions of higher education operating in Somalia, was founded in 1434 H corresponding to 2012 by a constellation of Somali intellectuals in light of the collapse of some educational institutions.`,
link:"https://diu.edu.so/",
},
{
id: 39,
name: "Jobkey University (JU)",
logo: Jobkey,
country: "Somalia",
desc: `Jobkey University (JU), founded in 2011, is a university located in Somalia with its main campus in Mogadishu, Somalia's capital city. The idea of launching the university was advanced by a group of intellectuals and other diaspora people. After more than three years of preparation, the university became operational at the end of 2011. The university has two campuses in Mogadishu, Banaadir: one is in Jowhar and the other one is in Beledweyne.`,
link:"https://jobkey.edu.so/",
},

{
id: 40,
name: "Imam Shafi’i University",
logo: Imamshafi,
country: "Somalia",
desc: `Imam Shafi’i University is a higher education institution in Mogadishu, Somalia. Was founded by the Mo’allim Noor Foundation in 2013. Although the university is new, the Foundation has been the holder of primary and secondary education in Somalia since 1942.`,
link:"https://ishu.edu.so/",
},
{
id: 42,
name: "salaam university ",
logo: Salaam,
country: "Somalia",
desc: `Founded in 2009, the Jaamacadda Salaam (Salaam University) is a non-profit private higher  education institution located in the metropolis of Mogadishu (population range of 1,000,000-5,000,000 inhabitants), Banaadir.`,
link:"https://salaam.edu.so/",
},
{
id: 44,
name: "Frontier University (FU)",
logo: Frontier,
country: "Somalia",
desc: ` Frontier University (FU) was established in late 2011. It started as Institute or college (Frontier College), a leading center for English Language and Development studies in Somalia, and has grown to be one of the most prominent private universities in Mogadishu. FU offers wide range of industry-relevant programs at undergraduate and postgraduate levels in collaboration with world leading universities.`,
link:"https://frontieruniversity.so/",
},

{
id: 45,
name: "Kownayn University",
logo: Kowneyn,
country: "Somalia",
desc: `Kownayn University is Private Liberal arts Institution founded in 2013. Designed primarily for the public to unlock the potential of today’s generation by providing high quality education blending it with critical-thinking flavor to global populations in general and Africans-more specifically to the Somali population scattered throughout East Africa.`,
link:"www.kownaynuniversity.edu.so",
},

{
id: 46,
name: "Darul Hikmah University (DHU) ",
logo: Darul,
country: "Somalia",
desc: ` Darul Hikmah University (DHU)   was founded  in the year 2012.is a non-political and non-profit educational organization. The credit for the idea of establishing this university goes to Somali Scholars felt the need for a university under private initiative, and discussed the concept of establishing this university with a group of intellectuals, researchers and educationists, at home and abroad.`,
link:"https://dhu.edu.so/",
},
{
id: 47,
name: "City University (CU) ",
logo: City,
country: "Somalia",
desc: `City University (CU) is a Not-For-Profit foundation university ﻿﻿Established in 2012, located in Mogadishu, Somalia that is pioneering quality instruction, research, and service to society..`,
link:"https://www.cu.edu.so/",

},
{
id: 48,
name: "Golden University",
logo: Golden,
country: "Somalia",
desc: `Golden University is an institution established for the Somali community, offering quality education across different levels of higher education. The university was founded at the beginning of 2014 and currently offers six different faculties.`,
link:"http://www.goldenuniversity.net/",
},
{
id: 50,
name: "Al-Imra International ",
logo: Alimra,
country: "Somalia",
desc: `Al-Imra International University is a Somali private university located in Mogadishu,AIU was set up on 31 July 2014. A special event           marking the opening was held in Mogadishu. AIU is registered in the Ministry of Culture and Higher Education. AIU held its    firstconvocationceremony on October 17, 2018`,
link:"www.aiu.so",
},
{
id: 51,
name: "Al Hayat Medical University (AMU)",
logo: Alhayat,
country: "Somalia",
desc: `Al Hayat Medical University (AMU) is a Unique College of Medical and Health Science education. It was  established 2016 in Mogadishu, Somalia by a team of Medical professionals with a high-quality Health science and Medical Education curriculum tailored to meet the learning needs and aspirations of the Somali students.`,
link:"https://alhayat.edu.so/",
},

{
id: 52,
name: "Pan African International University(PU) ",
logo: Pan,
country: "Somalia",
desc: `Pan African International University (PAIU), located in Mogadishu, Somalia, is a prominent institution of higher learning that was established in 2009. The university offers a range of programs through regular, online, and distance learning formats. PAIU's mission is to create an educational environment that fosters academic excellence, personal responsibility, and a commitment to service.`,
link:"https://paiu.edu.so",
},
{
id: 54,
name: "University of Gedo",
logo: Gedo,
country: "Somalia",
desc: `University of Gedo جامعة جدو is a public university in the city of Bardera situated in the Gedo region of Somalia. University of Gedo first campus was opened on August 29, 2008 in Bardera,The Somali Development Foundation (SDF), headed by Professor Warsame Ali, was the force behind its establishment.`,
link:"http://www.uofgedo.com/",
},
{
id: 56,
name: "Almaas University",
logo: Almaas,
country: "Somalia",
desc: `Almaas University, located in Mogadishu, Somalia, is a specialist, unique and non-state owned university that deals and pioneers with the education and research on natural resources, their sciences, their engineering applications, their management and governance,`,
link:"https://almaas.edu.so/",
},
];

export const acc = [
  {
    id: 1,
    name: "wazarada waxbarashada iyo hidaha tacliinta sare",
    logo: wwhts,
    link: "Somalia",
  },
  { id: 2, name: "CUFCE", logo: CUFCE, link: "USA" },
  { id: 3, name: "USHEAC", logo: USHEAC, link: "USA" },
  { id: 4, name: "AICE", logo: AICE, link: "USA" },
  { id: 5, name: "ACBSP", logo: ACBSP, link: "USA" },
];

export const all = _(up)
  .concat(op, lp1)
  .groupBy("logo")
  .map(_.spread(_.merge))
  .value();
