import { http } from "./httpServices";
import api from "../config.json";

// const url = api.local + "/employ";
const url = api.global + "/employ";

function employUrl(id) {
  return `${url}/${id}`;
}

export function getAllEmploy() {
  return http.get(url);
}
export function getAllStaffs() {
  return http.get(`${url}/staffs/all`);
}

export function deleteEmploy(id) {
  return http.delete(employUrl(id));
}
export function findStaffJobs(id) {
  return http.get(employUrl(id));
}

export function findStaffDepts(department) {
  const id = department.replaceAll(" ", "-")
  return http.get(employUrl('depts/'+id));
}

export async function getStaff(StaffId) {
  return http.get(employUrl(StaffId));
}

export function saveEmploy(staff) {
 
  return http.post(url, staff);
}

export function updateStaff(Staff) {
    return http.put(employUrl(Staff._id), Staff);

}
