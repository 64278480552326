import { GrAnnounce } from "react-icons/gr";
import cover1 from "../assets/images/errusmas/1.jpg"

const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/erasmus/carousel';
const cover =  backet + '/1.jpg'
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
export const announcements = [
//     {
//         id:1,
//         cover: cover,
//         title: "2023-2024 KA171 ACADEMIC YEAR STUDENT MOBILITY APPLICATIONS", 
//         date: new Date("2021, 10, 28").toLocaleDateString("en-US",options),
//         icon: <GrAnnounce />,
//         link: "student-2024",
//         desc: `<div className='flex md:justify-center md:items-center xl:justify-center xl:items-center w-full md:my-10 xl:my-10 my-3'>
//         <div className='flex flex-col justify-center w-[70%] text-black'>
//         <p className='text-2xl font-semibold'>Student Exchange Mobility to Çankırı Karatekin University</p>
//         <p className='my-5 text-xl'>Benadir University announces the opening of the application for student exchange with
//         Cankin Karatekin University within the scope of Erasmus+ mobility exchange program
//         for the 2nd semester of 2023-2024 academic year.</p>
//         <div className='flex flex-row sm:flex-col xs:flex-col '>
//             <p className='text-xl font-semibold'>University website:</p>
//             <div className='flex flex-col text-xl mx-5 xs:my-3 text-blue-500'>
//                 <a href="https://www.karatekin.edu.tr" target="_blank" rel="noopener noreferrer" >https://www.karatekin.edu.tr (Turkish)</a>
//                 <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/en">https://international.karatekin.edu.tr/en (English)</a>
//                 <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/ar">https://international.karatekin.edu.tr/ar (Arabic)</a>
//             </div>
//         </div>
       
//         <div className='flex flex-row xs:flex-col sm:flex-col  my-3'>
//             <p className='text-xl font-semibold'>Course Catalogue:</p>
//             <div className='flex flex-col text-xl mx-5 text-blue-500'>
//                 <a href='https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15' target="_blank" rel="noopener noreferrer">https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15</a>
//             </div>
//         </div>
//         <div className='flex flex-row xs:flex-col sm:flex-col my-3'>
//             <p className='text-xl font-semibold'>Grant:</p>
//             <div className='flex flex-col text-xl mx-5'>
//                 <p>800 euro for per month + travel cost according to distance band</p>
//             </div>
//         </div>
//         <div className='flex flex-row xs:flex-col sm:flex-col my-3'>
//             <p className='text-xl font-semibold'>Mobility dates:</p>
//             <div className='flex flex-col text-xl mx-5'>
//                 <p>26.02.2024 - 14.06.2024 (Spring term) (109 days in total) </p>
//             </div>
//         </div>

//         <div className='flex flex-col  my-3'>
//             <p className='text-xl font-semibold'>Available Departments</p>
//             <p className='text-xl font-semibold mx-8 my-3'>For Undergradute Level</p>
//             <div className='flex flex-col text-xl mx-16'>
//                 <p>Mathematics BSc Programme</p>
//                 <p>Economics BSc Programme</p>
//             </div>
//             <p className='text-xl font-semibold mx-8  my-3'>For Postgradute Level</p>
//             <div className='flex flex-col text-xl mx-16'>
//                 <p >Political Science and International Relations MA Programme (Master Degree) </p>
                
//                 <p>Economics Msc Programme (Master Degree)</p>
//                 <p>Chemistry MSc Programme (Master Degree)</p>
//                 <p> Biology MSc Programme (Master Degree)</p>
//                 <p>Mathematics MSc Programme (Master Degree)</p>
//                 <p>Art and Design MA Programme (Master Degree)</p>
//                 <p>Business MA Programme (Master Degree)</p>
//             </div>
//         </div>
        
//         <div className='flex flex-col my-3'>
//             <p className='text-xl font-semibold'>Evaluation Criteria + Minimum conditions</p>
//             <ol className='flex flex-col text-xl mx-16 list-decimal'>
//                 <li>Undergraduate students who are considering to apply for Erasmus program should have at least 2,20 CGPA and graduate students should have at least 2,50 CGPA.</li>
//                 <li>English proficiency</li>
//             </ol>
//             <p className='text-xl mx-16 my-8'>
//             For evaluation we will take %50 of CGPA and %50 English proficiency score.
//             </p>
//         </div>
//         <div className='flex flex-col my-3'>
//             <p className='text-xl font-semibold'>How to apply?</p>
//             <p className='text-xl mx-16 my-3'>
//             CAKU ONLINE SYSTEM: <a href='http://onkayit.karatekin.edu.tr/erasmus_student' target="_blank" rel="noopener noreferrer" className='text-blue-500'>http://onkayit.karatekin.edu.tr/erasmus_student </a> 
//             </p>
//         </div>
//         <div className='flex flex-col my-3'>
//             <p className='text-xl font-semibold'>Who can apply?</p>
//             <p className='text-xl mx-16 my-3'> Benadir University students studying in the above mentioned departments </p>
//         </div>
//         <div className='flex flex-row  my-3'>
//             <p className='text-xl font-semibold'>Quotas:</p>
//             <div className='flex flex-col text-xl mx-5'>
//                 <p>7 learning mobility</p>
//             </div>
//         </div>
//         <div className='flex flex-col my-3'>
//             <p className='text-xl mx-16 my-3'> Online system of CAKU will be used for the applications. </p>
//         </div>
//         </div>
//     </div>`
//     },
//     {
//         id:2, 
//         cover: cover,
//         title: "2023-2024 KA171 STAFF MOBILITY APPLICATIONS", 
//         date: new Date("2021, 10, 28").toLocaleDateString("en-US",options),
//         icon: <GrAnnounce />,
//         link: "staff-2024",
//        desc: `        <div className='flex justify-center items-center w-full my-10'>


//        <div className='flex flex-col justify-center w-[70%] text-black'>
//            <p className='text-2xl font-semibold'>Staff Exchange Mobility to Çankırı Karatekin University</p>
//            <p className='my-5 text-xl'>Benadir University announces the opening of the application for academic exchange for staff with Cankin Karatekin University within the scope of Erasmus+ mobility exchange program for the 2023-2024 academic year starting from 28 October to 12 November 2023.</p>
//            <div className='flex flex-row sm:flex-col xs:flex-col '>
//                <p className='text-xl font-semibold'>University website:</p>
//                <div className='flex flex-col text-xl md:mx-5 xl:mx-5 xs:text-base  text-blue-500'>
//                    <a href="https://www.karatekin.edu.tr" target="_blank" rel="noopener noreferrer" >https://www.karatekin.edu.tr (Turkish)</a>
//                    <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/en">https://international.karatekin.edu.tr/en (English)</a>
//                    <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/ar">https://international.karatekin.edu.tr/ar (Arabic)</a>
//                </div>
//            </div>
          
//            <div className='flex flex-row  my-3 sm:flex-col xs:flex-col '>
//                <p className='text-xl font-semibold '>Course Catalogue:</p>
//                <div className='flex flex-col text-xl xs:text-base md:mx-5 xl:mx-5 text-blue-500'>
//                    <a href='https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15' target="_blank" rel="noopener noreferrer">https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15</a>
//                </div>
//            </div>
//            <div className='flex flex-row  my-3'>
//                <p className='text-xl font-semibold'>Grant:</p>
//                <div className='flex flex-col text-xl mx-5'>
//                    <p>6 days - 140 Euro for per day + travel cost according to distance band</p>
//                </div>
//            </div>
//            <div className='flex flex-row  my-3'>
//                <p className='text-xl font-semibold'>Mobility dates:</p>
//                <div className='flex flex-col text-xl mx-5'>
//                    <p>26.02.2024 - 14.06.2024 (Spring term) (109 days in total) </p>
//                </div>
//            </div>

//            <div className='flex flex-col  my-3'>
//                <p className='text-xl font-semibold'>Majors</p>
//                <p className='text-xl font-semibold mx-8 my-3'>Available Departments for Teaching</p>
//                <div className='flex flex-col text-xl mx-16'>
//                    <p>Political Science and International Relations MA Programme</p>
//                    <p>Economics Msc Programme</p>
//                    <p>Chemistry MSc Programme</p>
//                    <p>Biology MSc Programme</p>
//                    <p>Mathematics MSc Programme</p>
//                    <p>Art and Design MA Programme</p>
//                    <p>Business MA Programme</p>
//                </div>
//                <p className='text-xl my-5'>Training mobilities will be accepted to any departments & units.</p>
//            </div>
//            <div className='flex flex-col my-3'>
//                <p className='text-xl font-semibold'>How to apply?</p>
//                <p className='text-xl mx-16 my-3 xs:text-base'>
//                CAKU ONLINE SYSTEM: <a href='http://onkayit.karatekin.edu.tr/erasmus_personal' target="_blank" rel="noopener noreferrer" className='text-blue-500'>http://onkayit.karatekin.edu.tr/erasmus_personal </a> 
//                </p>
//            </div>
//            <div className='flex flex-col my-3'>
//                <p className='text-xl font-semibold'>Who can apply?</p>
//                <p className='text-xl mx-16 my-3'> Benadir University staff members </p>
//            </div>
//            <div className='flex flex-row  my-3'>
//                <p className='text-xl font-semibold'>Quotas:</p>
//                <div className='flex flex-col text-xl mx-5'>
//                    <p>1 teaching, 1 training</p>
//                </div>
//            </div>
//            <div className='flex flex-col'>
//                <p className='text-xl  my-3'>Disabled staff have equal access to participate in Erasmus+ mobility programs, such as staff training or teaching assignments abroad.</p>
//            </div>
//            <div className='flex flex-col'>
//                <p className='text-xl  my-3'>Please do not forget that the staff will get 140 EUR per day together with the travel grant as 820 EUR for Somalia.</p>
//            </div>
//        </div>
//  </div>`
//     },
//     {
//         id:3, 
//         cover: cover1,
//         title: "2024-2025 KA171 ACADEMIC YEAR STUDENT MOBILITY APPLICATIONS", 
//         date: new Date("2024, 05, 01").toLocaleDateString("en-US",options),
//         icon: <GrAnnounce />,
//         link: "student-2025",
//         desc: `<div className='flex md:justify-center md:items-center xl:justify-center xl:items-center w-full md:my-10 xl:my-10 my-3'>
//         <div className='flex flex-col justify-center md:w-[70%] xl:w-[70%] sm:mx-3 xs:mx-5 w-full text-black'>
//         <p className='text-2xl sm:text-xl xs:text-xl font-semibold'>Student Exchange Mobility to Çankırı Karatekin University (2024 - 2025)</p>
//         <p className='my-5 text-xl sm:text-base xs:text-base'>Benadir University announces the opening of the application for student exchange with
//         Cankin Karatekin University within the scope of Erasmus+ mobility exchange program
//         for <strong> the autumn term in 2024-2025</strong> academic year starting from <strong>23 September 2024 to 10 January 2025</strong></p>
//         <div className='flex flex-row xs:flex-col sm:flex-col'>
//             <p className='text-xl font-semibold sm:text-base xs:text-base'>University website:</p>
//             <div className='flex flex-col text-xl sm:text-base xs:text-base mx-5 text-blue-500'>
//                 <a href="https://www.karatekin.edu.tr" target="_blank" rel="noopener noreferrer" >https://www.karatekin.edu.tr (Turkish)</a>
//                 <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/en">https://international.karatekin.edu.tr/en (English)</a>
//                 <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/ar">https://international.karatekin.edu.tr/ar (Arabic)</a>
//             </div>
//         </div>
       
//         <div className='flex flex-row xs:flex-col sm:flex-col my-3'>
//             <p className='text-xl font-semibold sm:text-base xs:text-base'>Course Catalogue:</p>
//             <div className='flex flex-col text-xl sm:text-base xs:text-base md:mx-5 xl:mx-5 text-blue-500'>
//                 <a href='https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15' target="_blank" rel="noopener noreferrer">https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15 </a>
//             </div>
//         </div>
//         <div className='flex flex-row xs:flex-col sm:flex-col  my-3'>
//             <p className='text-xl font-semibold sm:text-base xs:text-base'>Grant:</p>
//             <div className='flex flex-col text-xl sm:text-base xs:text-base mx-5'>
//                 <p>800 euro for per month + travel cost according to distance band</p>
//             </div>
//         </div>
//         <div className='flex flex-row xs:flex-col sm:flex-col my-3'>
//             <p className='text-xl font-semibold sm:text-base xs:text-base'>Mobility dates:</p>
//             <div className='flex flex-col text-xl mx-5 sm:text-base xs:text-base'>
//                 <p>23.09.2024 – 10.01.2025 (Autumn term) (108 days in total) </p>
//             </div>
//         </div>

//         <div className='flex flex-col  my-3'>
//             <p className='text-xl font-semibold sm:text-base xs:text-base'>Available Departments</p>
//             <p className='text-xl font-semibold md:mx-8 xl:mx-8 my-3 sm:text-base xs:text-base'>For Undergradute Level</p>
//             <div className='flex flex-col text-xl sm:text-base xs:text-base md:mx-16 xl:mx-16'>
//                 <p>Mathematics BSc Programme</p>
//                 <p>Economics BSc Programme</p>
//             </div>
//             <p className='text-xl font-semibold md:mx-8 xl:mx-8  my-3'>For Postgradute Level</p>
//             <div className='flex flex-col text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16'>
//                 <p >Political Science and International Relations MA Programme (Master Degree).</p>
                
//                 <p>Economics Msc Programme (Master Degree).</p>
//                 <p>Chemistry MSc Programme (Master Degree).</p>
//                 <p> Biology MSc Programme (Master Degree).</p>
//                 <p>Mathematics MSc Programme (Master Degree).</p>
//                 <p>Art and Design MA Programme (Master Degree).</p>
//                 <p>Business MA Programme (Master Degree).</p>
//             </div>
//         </div>
        
//         <div className='flex flex-col my-3'>
//             <p className='text-xl font-semibold sm:text-base xs:text-base '>Evaluation Criteria + Minimum conditions</p>
//             <ol className='flex flex-col text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16 list-decimal'>
//                 <li>Undergraduate students who are considering to apply for Erasmus program should have at least 2,20 CGPA and graduate students should have at least 2,50 CGPA.</li>
//                 <li>English proficiency</li>
//             </ol>
//             <p className='text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16 my-8'>
//             For evaluation we will take %50 of CGPA and %50 English proficiency score.
//             </p>
//         </div>
//         <div className='flex flex-col my-3'>
//             <p className='text-xl sm:text-base xs:text-base font-semibold'>How to apply?</p>
//             <p className='text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16 my-3'>
//             To apply the program click : <a href='https://forms.gle/t669e64hY8esqZJL6' target="_blank" rel="noopener noreferrer" className='text-blue-500'>https://forms.gle/t669e64hY8esqZJL6 </a> 
//             </p>
//             <p className="font-semibold">N.B:</p>
//             <div className="space-y-2 mx-3">
//             <p>
//             The selection process for this program will be conducted by Benadir University committee.</p>
//             <p>Application Deadline is : 30th of May 2024</p>
//             <p> Online system of CAKU will no longer be used for the applications. </p>
//             </div>
//         </div>
//         <div className='flex flex-col my-3'>
//             <p className='text-xl font-semibold sm:text-base xs:text-base'>Who can apply?</p>
//             <p className='text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16 my-3'> Benadir University students studying in the above mentioned departments </p>
//         </div>

//         <div className='flex flex-row  my-3'>
//             <p className='text-xl font-semibold'>Quotas:</p>
//             <div className='flex flex-col text-xl mx-5'>
//                 <p>10 learning mobility</p>
//             </div>
//         </div>
        
       
//         </div>
//     </div>`
//     },
//     {
//         id:4, 
//         cover: cover1,
//         title: "2024-2025 KA171 STAFF MOBILITY APPLICATIONS", 
//         date: new Date("2024, 05, 01").toLocaleDateString("en-US",options),
//         icon: <GrAnnounce />,
//         link: "staff-2025",
//        desc: `<div className='flex md:justify-center md:items-center xl:justify-center xl:items-center w-full md:my-10 xl:my-10 my-3'>
//        <div className='flex flex-col justify-center md:w-[70%] xl:w-[70%] sm:mx-5 xs:mx-5 text-black'>
//            <p className='text-2xl font-semibold xs:text-xl sm:text-xl'>Staff Exchange Mobility to Çankırı Karatekin University (2024 - 2025)</p>
//            <p className='my-5 text-xl'>Benadir University announces the opening of the application for academic exchange for staff with Cankin Karatekin University within the scope of Erasmus+ mobility exchange program for <strong> the autumn semester in 2024-2025</strong> academic year starting from <strong>23 September 2024 to 10 January 2025</strong>.</p>
//            <div className='flex flex-row sm:flex-col xs:flex-col '>
//                <p className='text-xl font-semibold'>University website:</p>
//                <div className='flex flex-col text-xl md:mx-5 xl:mx-5 xs:text-base  text-blue-500'>
//                    <a href="https://www.karatekin.edu.tr" target="_blank" rel="noopener noreferrer" >https://www.karatekin.edu.tr (Turkish)</a>
//                    <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/en">https://international.karatekin.edu.tr/en (English)</a>
//                    <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/ar">https://international.karatekin.edu.tr/ar (Arabic)</a>
//                </div>
//            </div>
          
//            <div className='flex flex-row  my-3 sm:flex-col xs:flex-col xs:hidden '>
//                <p className='text-xl font-semibold '>Course Catalogue:</p>
//                <div className='flex flex-col text-xl xs:text-base md:mx-5 xl:mx-5 text-blue-500'>
//                    <a href='https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15' target="_blank" rel="noopener noreferrer">https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15</a>
//                </div>
//            </div>
//            <div className='flex flex-row  my-3'>
//                <p className='text-xl font-semibold'>Grant:</p>
//                <div className='flex flex-col text-xl mx-5'>
//                    <p>5 working days and 1 travel day grant  - 140 Euro for per day +  travel cost according to distance band</p>
//                </div>
//            </div>
//            <div className='flex flex-row  my-3 hidden'>
//                <p className='text-xl font-semibold'>Mobility dates:</p>
//                <div className='flex flex-col text-xl mx-5'>
//                    <p>26.02.2024 - 14.06.2024 (Spring term) (109 days in total) </p>
//                </div>
//            </div>

//            <div className='flex flex-col  my-3'>
//                <p className='text-xl font-semibold'>Majors</p>
//                <p className='text-xl font-semibold mx-8 my-3'>Available Departments for Teaching</p>
//                <div className='flex flex-col text-xl mx-16'>
//                    <p>Political Science and International Relations MA Programme</p>
//                    <p>Economics Msc Programme</p>
//                    <p>Chemistry MSc Programme</p>
//                    <p>Biology MSc Programme</p>
//                    <p>Mathematics MSc Programme</p>
//                    <p>Art and Design MA Programme</p>
//                    <p>Business MA Programme</p>
//                </div>
//                <p className='text-xl my-5'>Training mobilities will be accepted to any departments & units.</p>
//            </div>
//            <div className='flex flex-row  my-3'>
//            <p className='text-xl font-semibold'>The Dates of Staff Week : </p>
//            <div className='flex flex-col text-xl mx-5'>
//                <p>21.10.2024 - 25.10.2024.<strong>(Five working days 21 - 25 of October 2024)</strong> </p>
//            </div>
//             </div>
//             <div className='flex flex-col  my-3'>
//            <p className='text-xl font-semibold'>REMINDER FOR INCOMING STAFF</p>
//            <div className='flex flex-col text-xl my-3 mx-5 leading-8'>
//                <p>Incoming staff are responsible for all living expenses including transportation to and from CAKU, room and board, HEALTH INSURANCE and miscellaneous expenses while at CAKU. Although CAKU has a health center where incoming staff can see a doctor and have simple blood tests done, staff must have an emergency/hospitalisation insurance valid in Turkey for furher health complications.<br/><br/>
//                Incoming staff agree to abide by the rules and regulations of Cankiri Karatekin University.<br/><br/>
//                The Erasmus grant is going to be <strong> paid on Friday 100% if the activities are completed</strong>.
//                </p>
//            </div>
//             </div>

//            <div className='flex flex-col my-3'>
//                <p className='text-xl font-semibold'>How to apply?</p>
//                <p className='text-xl mx-16 my-3 xs:text-base'>
//                To apply the program, click the link. <a href='https://forms.gle/XT5odCu7qXdYGvQ36' target="_blank" rel="noopener noreferrer" className='text-blue-500'>https://forms.gle/XT5odCu7qXdYGvQ36</a>
//                <br/>
//                <span className="text-sm">For more info about the program, contact the registrar's Office.</span>
//                </p>
//                <p className="font-semibold">N.B:</p>
//             <div className="space-y-2 mx-3">
//             <p>
//             The selection process for this program will be conducted by Benadir University committee.</p>
//             <p>Application Deadline is : 3<sup>th</sup> of July 2024, 11:59 PM</p>
//             <p> Online system of CAKU will no longer be used for the applications. </p>
//            </div>
//            <div className='flex flex-col my-3'>
//                <p className='text-xl font-semibold'>Who can apply?</p>
//                <p className='text-xl mx-16 my-3'> Benadir University staff members </p>
//            </div>
//            <div className='flex flex-row  my-3'>
//                <p className='text-xl font-semibold'>Quotas:</p>
//                <div className='flex flex-col text-xl mx-5'>
//                    <p>1 teaching, 1 training</p>
//                </div>
//            </div>
//            <div className='flex flex-col'>
//                <p className='text-xl  my-3'>Disabled staff have equal access to participate in Erasmus+ mobility programs, such as staff training or teaching assignments abroad.</p>
//            </div>
           
//        </div>
//  </div>`
//     },
    {
        id:5, 
        cover: cover1,
        title: "2024-2025 KA171 ACADEMIC YEAR STUDENT MOBILITY APPLICATIONS - Spring", 
        date: new Date("2024, 11, 12").toLocaleDateString("en-US",options),
        icon: <GrAnnounce />,
        link: "student-2025-spring",
        desc: `<div className='flex md:justify-center md:items-center xl:justify-center xl:items-center w-full md:my-10 xl:my-10 my-3'>
        <div className='flex flex-col justify-center md:w-[70%] xl:w-[70%] sm:mx-3 xs:mx-5 w-full text-black'>
        <p className='text-2xl sm:text-xl xs:text-xl font-semibold'>Student Exchange Mobility to Çankırı Karatekin University (2024 - 2025)</p>
        <p className='my-5 text-xl sm:text-base xs:text-base'>Benadir University announces the opening of the application for student exchange with
        Cankin Karatekin University within the scope of Erasmus+ mobility exchange program
        for <strong> the spring term in 2024-2025</strong> academic year starting from <strong>24 February 2025 to 13 June 2025</strong></p>
        <div className='flex flex-row xs:flex-col sm:flex-col'>
            <p className='text-xl font-semibold sm:text-base xs:text-base'>University website:</p>
            <div className='flex flex-col text-xl sm:text-base xs:text-base mx-5 text-blue-500'>
                <a href="https://www.karatekin.edu.tr" target="_blank" rel="noopener noreferrer" >https://www.karatekin.edu.tr (Turkish)</a>
                <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/en">https://international.karatekin.edu.tr/en (English)</a>
                <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/ar">https://international.karatekin.edu.tr/ar (Arabic)</a>
            </div>
        </div>
       
        <div className='flex flex-row xs:flex-col sm:flex-col my-3'>
            <p className='text-xl font-semibold sm:text-base xs:text-base'>Course Catalogue:</p>
            <div className='flex flex-col text-xl sm:text-base xs:text-base md:mx-5 xl:mx-5 text-blue-500'>
                <a href='https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15' target="_blank" rel="noopener noreferrer">https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15</a>
            </div>
        </div>
        <div className='flex flex-row xs:flex-col sm:flex-col  my-3'>
            <p className='text-xl font-semibold sm:text-base xs:text-base'>Grant:</p>
            <div className='flex flex-col text-xl sm:text-base xs:text-base mx-5'>
                <p>800 euro for per month + travel cost according to distance band</p>
            </div>
        </div>
        <div className='flex flex-row xs:flex-col sm:flex-col my-3'>
            <p className='text-xl font-semibold sm:text-base xs:text-base'>Mobility dates:</p>
            <div className='flex flex-col text-xl mx-5 sm:text-base xs:text-base'>
                <p>24.02.2025 – 13.06.2025 (spring  term) (110 days in total)</p>
            </div>
        </div>

        <div className='flex flex-col  my-3'>
            <p className='text-xl font-semibold sm:text-base xs:text-base'>Available Departments</p>
            <p className='text-xl font-semibold md:mx-8 xl:mx-8 my-3 sm:text-base xs:text-base'>For Undergradute Level</p>
            <div className='flex flex-col text-xl sm:text-base xs:text-base md:mx-16 xl:mx-16'>
                <p>Mathematics BSc Programme</p>
                <p>Economics BSc Programme</p>
            </div>
            <p className='text-xl font-semibold md:mx-8 xl:mx-8  my-3'>For Postgradute Level</p>
            <div className='flex flex-col text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16'>
                <p >Political Science and International Relations MA Programme (Master Degree).</p>
                <p>Economics Msc Programme (Master Degree).</p>
                <p> Biology MSc Programme (Master Degree).</p>
                <p>Mathematics MSc Programme (Master Degree).</p>
                <p>Art and Design MA Programme (Master Degree).</p>
                <p>Business MA Programme (Master Degree).</p>
            </div>
        </div>
        
        <div className='flex flex-col my-3'>
            <p className='text-xl font-semibold sm:text-base xs:text-base '>Evaluation Criteria + Minimum conditions</p>
            <ol className='flex flex-col text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16 list-decimal'>
                <li>Undergraduate students who are considering to apply for Erasmus program should have at least 2,20 CGPA and graduate students should have at least 2,50 CGPA.</li>
                <li>English proficiency</li>
            </ol>
            <p className='text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16 my-8'>
            For evaluation we will take %50 of CGPA and %50 English proficiency score.
            </p>
        </div>
        <div className='flex flex-col my-3'>
            <p className='text-xl sm:text-base xs:text-base font-semibold'>How to apply?</p>
            <p className='text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16 my-3'>
            To apply the program click : <a href='https://forms.gle/UJMLREzPashfTKCt9' target="_blank" rel="noopener noreferrer" className='text-blue-500'>https://forms.gle/UJMLREzPashfTKCt9 </a> 
            </p>
            <p className="font-semibold">N.B:</p>
            <div className="space-y-2 mx-3">
            <p>
            The selection process for this program will be conducted by Benadir University committee.</p>
            <p>Application Deadline is : 6th of December 2024</p>
            <p> Online system of CAKU will no longer be used for the applications. </p>
            </div>
        </div>
        <div className='flex flex-col my-3'>
            <p className='text-xl font-semibold sm:text-base xs:text-base'>Who can apply?</p>
            <p className='text-xl sm:text-base xs:text-sm md:mx-16 xl:mx-16 my-3'> Benadir University students studying in the above mentioned departments </p>
        </div>

        <div className='flex flex-row  my-3'>
            <p className='text-xl font-semibold'>Quotas:</p>
            <div className='flex flex-col text-xl mx-5'>
                <p>10 learning mobility</p>
            </div>
        </div>
        
       
        </div>
    </div>`
    },
    {
        id:6, 
        cover: cover1,
        title: "2024-2025 KA171 STAFF MOBILITY APPLICATIONS - spring", 
        date: new Date("2024, 11, 12").toLocaleDateString("en-US",options),
        icon: <GrAnnounce />,
        link: "staff-2025-spring",
       desc: `<div className='flex md:justify-center md:items-center xl:justify-center xl:items-center w-full md:my-10 xl:my-10 my-3'>
       <div className='flex flex-col justify-center md:w-[70%] xl:w-[70%] sm:mx-5 xs:mx-5 text-black'>
           <p className='text-2xl font-semibold xs:text-xl sm:text-xl'>Staff Exchange Mobility to Çankırı Karatekin University (2024 - 2025)</p>
           <p className='my-5 text-xl'>Benadir University announces the opening of the application for academic exchange for staff with Cankin Karatekin University within the scope of Erasmus+ mobility exchange program for <strong> the spring term semester in 2024-2025</strong> academic year starting from <strong>24 February 2025 to 13 June 2025</strong>.</p>
           <div className='flex flex-row sm:flex-col xs:flex-col '>
               <p className='text-xl font-semibold'>University website:</p>
               <div className='flex flex-col text-xl md:mx-5 xl:mx-5 xs:text-base  text-blue-500'>
                   <a href="https://www.karatekin.edu.tr" target="_blank" rel="noopener noreferrer" >https://www.karatekin.edu.tr (Turkish)</a>
                   <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/en">https://international.karatekin.edu.tr/en (English)</a>
                   <a target="_blank" rel="noopener noreferrer" href="https://international.karatekin.edu.tr/ar">https://international.karatekin.edu.tr/ar (Arabic)</a>
               </div>
           </div>
          
           <div className='flex flex-row  my-3 sm:flex-col xs:flex-col xs:hidden '>
               <p className='text-xl font-semibold '>Course Catalogue:</p>
               <div className='flex flex-col text-xl xs:text-base md:mx-5 xl:mx-5 text-blue-500'>
                   <a href='https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15' target="_blank" rel="noopener noreferrer">https://bbs.karatekin.edu.tr/AkademikBirimler.aspx?birim=15</a>
               </div>
           </div>
           <div className='flex flex-row  my-3'>
               <p className='text-xl font-semibold'>Grant:</p>
               <div className='flex flex-col text-xl mx-5'>
                   <p>5 working days and 1 travel day grant  - 140 Euro for per day +  travel cost according to distance band</p>
               </div>
           </div>
           <div className='flex flex-row  my-3 hidden'>
               <p className='text-xl font-semibold'>Mobility dates:</p>
               <div className='flex flex-col text-xl mx-5'>
                   <p>26.02.2024 - 14.06.2024 (Spring term) (109 days in total) </p>
               </div>
           </div>

           <div className='flex flex-col  my-3'>
               <p className='text-xl font-semibold'>Majors</p>
               <p className='text-xl font-semibold mx-8 my-3'>Available Departments for Teaching</p>
               <div className='flex flex-col text-xl mx-16'>
                   <p>Political Science and International Relations MA Programme</p>
                   <p>Economics Msc Programme</p>
                   <p>Chemistry MSc Programme</p>
                   <p>Biology MSc Programme</p>
                   <p>Mathematics MSc Programme</p>
                   <p>Art and Design MA Programme</p>
                   <p>Business MA Programme</p>
               </div>
               <p className='text-xl my-5'>Training mobilities will be accepted to any departments & units.</p>
           </div>
           <div className='flex flex-row  my-3'>
           <p className='text-xl font-semibold'>The Dates of Staff Week : </p>
           <div className='flex flex-col text-xl mx-5'>
               <p>21.10.2024 - 25.10.2024.<strong>(Five working days 21 - 25 of October 2024)</strong> </p>
           </div>
            </div>
            <div className='flex flex-col  my-3'>
           <p className='text-xl font-semibold'>REMINDER FOR INCOMING STAFF</p>
           <div className='flex flex-col text-xl my-3 mx-5 leading-8'>
               <p>Incoming staff are responsible for all living expenses including transportation to and from CAKU, room and board, HEALTH INSURANCE and miscellaneous expenses while at CAKU. Although CAKU has a health center where incoming staff can see a doctor and have simple blood tests done, staff must have an emergency/hospitalisation insurance valid in Turkey for furher health complications.<br/><br/>
               Incoming staff agree to abide by the rules and regulations of Cankiri Karatekin University.<br/><br/>
               The Erasmus grant is going to be <strong> paid on Friday 100% if the activities are completed</strong>.
               </p>
           </div>
            </div>

           <div className='flex flex-col my-3'>
               <p className='text-xl font-semibold'>How to apply?</p>
               <p className='text-xl mx-16 my-3 xs:text-base'>
               To apply the program, click the link. <a href='https://forms.gle/XT5odCu7qXdYGvQ36' target="_blank" rel="noopener noreferrer" className='text-blue-500'>https://forms.gle/XT5odCu7qXdYGvQ36</a>
               <br/>
               <span className="text-sm">For more info about the program, contact the registrar's Office.</span>
               </p>
               <p className="font-semibold">N.B:</p>
            <div className="space-y-2 mx-3">
            <p>
            The selection process for this program will be conducted by Benadir University committee.</p>
            <p>Application Deadline is : 3<sup>th</sup> of July 2024, 11:59 PM</p>
            <p> Online system of CAKU will no longer be used for the applications. </p>
           </div>
           <div className='flex flex-col my-3'>
               <p className='text-xl font-semibold'>Who can apply?</p>
               <p className='text-xl mx-16 my-3'> Benadir University staff members </p>
           </div>
           <div className='flex flex-row  my-3'>
               <p className='text-xl font-semibold'>Quotas:</p>
               <div className='flex flex-col text-xl mx-5'>
                   <p>1 teaching, 1 training</p>
               </div>
           </div>
           <div className='flex flex-col'>
               <p className='text-xl  my-3'>Disabled staff have equal access to participate in Erasmus+ mobility programs, such as staff training or teaching assignments abroad.</p>
           </div>
           
       </div>
 </div>`
    },
]