import React, { useState } from 'react'
import _ from "lodash";
import avatar from "../../../assets/images/profle/leadership/avatar.jpeg"
import {Rectorate, admin, deans} from "../../../data/leaders"
import head from "../../../assets/images/profle/leadership/1.jpg"
import { Link } from 'react-router-dom';
const Leadership = () => {
  
  return (
    <div className='flex flex-col text-black w-full  place-items-center'>
      <div className='w-[80%] justify-center items-center'>
        <div >
          <p className='uppercase text-5xl sm:text-2xl xs:text-2xl text-black font-bold mx-10 font-montserrat'>University Leadership</p>
        </div>
        <div className='mt-5 h-[2px] bg-black w-full'></div>
        <div className='md:my-10 xl:my-10'>
          <div className='my-10'>
          <p className='text-2xl sm:text-base xs:text-base font-bold font-montserrat'>
          Rectorate - Senior Leadership
          </p>
          <div className='grid grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 gap-3 my-3 '>
            {Rectorate.map( ld =>(
              <div key={ld.id} className=''>
              <img src={ld.photo} alt="photoo" className='w-[500px]'/>
              <div className='space-y-1 my-2'>
                <p className='text-sm'>{ld.name}</p>
                <p className='font-bold'>{ld.tiltle}</p>
                {
                  ld.bio && ld.bio === "biography" ? (
                <Link to={`${ld.bio}`} className='text-blue-500 '>Read bio
                </Link>
                  )
                  :
                  (
                    <Link to={`${ld.bio}`} state={{ bio: ld }} className='text-blue-500 '>Read bio
                </Link>
                  )
                }
              </div>
              </div>   ))
            }
          </div>
          </div>
      {/* deans  */}

      <div className='my-10'>
          <p className='text-2xl sm:text-base xs:text-base font-bold font-montserrat'>
          Deans - Faculty Leadership
          </p>
          <div className='grid grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 gap-3 my-3 '>
            {deans.map( d =>(
              <div key={d.id} className=''>
              <img src={d.photo} alt="photoo" className='w-[500px]'/>
              <div className='space-y-1 my-2'>
                <p>{d.name}</p>
                <p>{d.tiltle}</p>
                {
                  d.bio &&  (
                <Link to={`${d.bio}`} className='text-blue-500 '>Read bio
                </Link>
                  )
                }
              </div>
              </div>   ))
            }
          </div>
          </div>
          {/* adminstration */}
          <div className='my-10'>
          <p className='text-2xl sm:text-base xs:text-base font-bold font-montserrat'>
          Directors - Adminstration Leadership
          </p>
          <div className='grid grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 gap-3 my-3 '>
            {admin.map( a =>(
              <div key={a.id} className=''>
              <img src={a.photo} alt="photoo" className='w-[500px]'/>
              <div className='space-y-1 my-2'>
                <p>{a.name}</p>
                <p>{a.tiltle}</p>
                {
                  a.bio && (
                <Link to={`${a.bio}`} className='text-blue-500 '>Read bio
                </Link>
                  )
                }
              </div>
              </div>   ))
            }
          </div>
          </div>
        </div>
        </div>
</div>
)
}

export default Leadership