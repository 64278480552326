import React, {useState} from "react";

import { FaAngleDown,FaAngleUp } from 'react-icons/fa';




const Eligibility = () => {

  const desc = [
    "Students having a Secondary School Certificate",
    "Students having a foundation year certificate",
  ]

  const overView = "book. It has survived not only five centuries, but also the leap into electronic, typesetting, remaining essentially unchanged. It was popularised in the 1960s with the, release of Letraset sheets containing Lorem Ipsum passages, and more recently with,desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
  const lists =  [ 
    { id: 1, program: "Undregraduate programs", info: desc, overview: overView },
    { id: 2, program: "Postgraduate programs" ,  info: desc , overview: overView}
]
    
  const [collapse, setCollapse] = useState(0);
  const [collapse1, setCollapse1] = useState(true);
  const handleOpen = (value) =>{ 
    setCollapse(value);
    setCollapse1(!collapse1)
  }

  // collapse === open && collapse1 === true? "cursor-pointer my-3 bg-[#0863AA] text-white": "cursor-pointer my-3"
  return (
         <div  className="md:mx-20 md:px-20 xl:mx-20 xl:px-20 mx-3 px-3 flex flex-col justify-evenly text-[#0863AA]  mt-20">
          <p className="text-4xl text-center font-bold my-10 py-5">ADMISSION ELIGIBILITY</p>
        {
            lists.map( (l,open) => (

          <div key={l.id}  className={"cursor-pointer my-3"}>
            <div onClick={() => handleOpen(open)} className={collapse === open && collapse1 === true? "flex flex-row justify-between py-3 font-semibold text-2xl  border-2 solid  px-10 rounded-lg bg-[#0863AA] text-white": "flex flex-row justify-between py-3 font-semibold text-2xl  border-2 solid  px-10 rounded-lg bg-white"}>
            <p className="hover:underline">
                {l.program}
            </p>
            <p className="text-[40px]">
                {collapse === open && collapse1 === true? <FaAngleUp /> :  <FaAngleDown />}
                
            </p>
            </div>

           
            {collapse === open && collapse1 === true ?  
            (
              <div className="border-2 solid border-[#0863AA] rounded-lg mb-6 my-3">

           
            {l.info.map(
                (i,r) => (
                      <div key={r} className="flex  flex-row px-10 place-items-center py-1 ">
                    <p  className=" text-left text-base font-sans font-normal text-black   py-2 px-2">
                      {i}
                    </p>
                    
                  </div>
                )
            )} 
             </div>
              ): ""}
          </div>
            ))
        }
       
         
         
        
       
      </div>

  );
};

export default Eligibility;