import React, {useState, useEffect} from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import {Link, useNavigate} from "react-router-dom"
import {login, getCurrentUser } from "../../../services/authServices"


const logo_mobile = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Benadir University Logo.png'


const ForgotPassword = () => {


  const navigate = useNavigate();
  

    const validationSchema = yup.object({
        email: yup
          .string('Enter your email')
          .email('Enter a valid email')
          .required('Email is required'),
      });

      useEffect( () => {
        if(getCurrentUser()){
          return navigate('/dashboard')
        }
      },[navigate])

    return  (
    <div className='bg-blue-500 h-screen w-screen flex justify-center items-center  text-[12px]'>
    <div className='xl:w-1/4 md:w-1/4 w-full'>
            <div className="card overflow-hidden sm:rounded-md md:rounded-md rounded-none">
                  <div className="px-6 py-8">
                      <a href="/" className="flex justify-center mb-8">
                        <img className="h-10" src={logo_mobile} alt=""/>
                      </a>

                      <p class="text-center lg:w-3/4 mx-auto mb-6">Enter your email address and we'll send you an email with instructions to reset your password.</p>


                        <Formik
                        initialValues={{ email: '', password: ''}}
                        validationSchema={validationSchema}
                        onSubmit={ async(values, actions) => {
                        actions.setErrors({username:'', email: ''})
                        // await login(values)
                        actions.setSubmitting(true)
                        navigate('/recovermessage')
                        }}
                        >
                        {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                        }) => 
                        (
                        <form onSubmit={handleSubmit} className=''>
                        <div className='mb-4'>
                          <label className="mb-2" for="LoggingEmailAddress">Email Address</label>
                          <input
                          placeholder="Email address"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className='form-input'
                          />
                          {errors.email && touched.email && errors.email}
                        </div>
                   
                        
                        <div className='flex justify-center mb-3'>
                        <button type="submit" disabled={isSubmitting} className='btn w-full text-white bg-blue-500  hover:bg-[#465B53]'>Reset password</button>
                        </div>

                        </form>
                        )}
                        </Formik>

                  </div>
            </div>
            <p class="text-white text-center mt-8">Already have an account ?<Link to="/login" class="font-semibold mx-1">Sign In</Link></p>

  </div>
  </div>
)};

export default ForgotPassword;