import React, { useState, useEffect } from "react";
import api from "../../config.json"
// import { posts } from "../../data/posts";
import { Link } from "react-router-dom";
// import { news } from "../../data/news";
import { getAllNews} from "../../services/newsServices"
import _ from "lodash"


const News = () => {
  // const sortedNews = news.sort((a, b) => b.id - a.id);
  const [sortedNews, setSortedNews] = useState([]);
  
  const url = api.url
  useEffect( () => {

    async function fetchData() {
        const response = await getAllNews();
        const news = response.data
        const sortedNews = _.orderBy(news, 'createdAt', 'desc');
        setSortedNews(sortedNews);
      }
      fetchData();
  },[sortedNews])

  return (
    <div className="">
      <div className="relative w-full h-[100px] mx-h-[100px]"></div>

      {/* menu of this area */}
      <div className="bg-[#EDF4F4] px-4 md:px-10 py-10 ">
        <div className="col-span-10 w-full">
          <input
            type="text"
            placeholder="Search Here"
            className="w-full py-3 px-3 outline-none rounded-md"
          />
        </div>
      </div>

      {/* no name */}
      <div className="grid md:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 mx-4 md:mx-10 xl:mx-10 my-10 gap-4">
        {sortedNews.map((n) => (
          <div
            key={n._id}
            className="bg-white rounded-lg shadow-lg hover:border-2 hover:border-[#000] cursor-pointer"
          >
            <Link to={`/news/${n.slug}`} state={{ news: n }}>
              <img
                src={url + n.titleImageUrl}
                className="rounded-t-lg object-cover md:w-[500px] md:h-[300px] xl:w-[500px] xl:h-[300px]"
                alt="not found"
              />
              <div className="px-6 mt-8 mb-10">
                <p className="font-bold text-[16px] md:text-[1.5rem] line-clamp-1">
                  {n.title}
                </p>
                <p className="text-star  font-normal tracking-wider line-clamp-2">
                  {n.newsContent}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
