import React from 'react'
import {useCollapse} from 'react-collapsed';
import { FaAngleDown,FaAngleUp } from 'react-icons/fa';

function SectionCollapse(props) {
   
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div   className={"cursor-pointer my-3"} {...getToggleProps()} >
    <div className={isExpanded === false ? "flex flex-row justify-between py-3 font-semibold md:text-xl xl:text-xl text-base xs:text-base  border-2 solid  md:px-10 xl:px-10 sm:px-5 xs:px-5  bg-white ": "flex flex-row justify-between py-3 font-semibold md:text-xl xl:text-xl text-base xs:text-base  border-2 solid  md:px-10 xl:px-10 sm:px-5 xs:px-5 bg-white"}>
        <div className='flex flex-row'>
            <p className="xs:text-[12px]">
                Publications done in
            </p>
            <p className="hover:underline xs:text-base mx-2 text-primary">
                {props.title}
            </p>
        </div>
    <p className="md:text-[25px] xl:text-[30px] xs:text-[15px] sm:text-[20px]">
        {isExpanded ? <FaAngleUp /> :  <FaAngleDown />}
    </p>
    </div>
  
    
      <div className="border-2 solid border-[#d9d9de]  bg-white" {...getCollapseProps()}>
              <div  className="flex  flex-col md:px-5 xl:px-5   py-1 ">
            
            {props.children}
            
          </div>
     </div>
      
  </div>
 
  )
}

export default SectionCollapse