import React,{useState} from "react";
import { NavLink } from "react-router-dom";
import { IoMdMenu } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';

const BioNav = ({id}) => {
  const [togle, setTogle] = useState(false);

   const menus = [
    { id: 1, menu: "Biography" , link: "biography"},
    { id: 2, menu: "Messages", link: 'messages'},
    { id: 3, menu: "Innaguration" , link: "inauguration"},
  ]
  
  const showToggle =()=>{
    setTogle(!togle);
    // setSubTogle(!subTogle);
  }

  return (
    <>
    <div className="flex flex-col xs:flex-col sm:flex-col  justify-start items-start ml-10 w-fit my-6 space-x-2 sm:hidden xs:hidden " >
        {
          menus.map( m => (
              <NavLink
                key={m.id} to={`/about/leadership/${m.link}`}
                className={({ isActive }) =>
                isActive ? " font-sans w-fit font-semibold text-base sm:w-full xs:w-full  px-3  text-[#0863AA] border-b-2 border-b-[#0863AA] py-2" : "font-serif w-fit sm:w-full xs:w-full font-semibold text-base  text-[#0863AA] py-2 px-3 hover:border-b-2 hover:border-b-[#0863AA] hover:text-[#0863AA]"}>
                    {m.menu}
              </NavLink>
          ))
        }
      </div>
       <div className="flex flex-col  justify-center w-full my-5 xl:hidden md:hidden p-2  " >
        <div className='flex items-end w-full mb-2 cursor-pointer flex-col md:flex-row xl:flex-row justify-start '  onClick={showToggle}>
          {togle ? <IoMdClose className='h-8 w-8'/> : <IoMdMenu className='h-8 w-8'/>}
          
        </div>
        <div className={`flex flex-col border-2 border-[#0863AA] ${togle ? "" : "hidden"}`}>
       {
         menus.map( m => (
             <NavLink
               key={m.id} to={`/about/leadership/${m.link}`}
               className={({ isActive }) =>
               isActive ? " font-sans w-full font-semibold text-base sm:w-full xs:w-full   bg-[#0863AA] text-white    py-3" : "font-serif w-fit sm:w-full xs:w-full font-semibold text-base  text-[#0863AA] border-[1px]    py-3   hover:bg-[#0863AA] hover:text-white border-[#dddede] "}>
                   {m.menu}
             </NavLink>
         ))
       }
         </div>
     </div>
     </>
  );
};

export default BioNav;