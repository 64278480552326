import React from 'react';
import {FaAngleDoubleLeft, FaAngleDoubleRight} from 'react-icons/fa'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <div  className='flex flex-row justify-end px-10 xs:hidden'>
      <ul className="inline-flex items-center  border border-gray-300 rounded-r-lg rounded-l-lg">
        <li>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-2 ml-0 leading-tight text-black bg-white   hover:bg-gray-100 hover:text-gray-700"
          >
            <FaAngleDoubleLeft/>
          </button>
        </li>
        {getPageNumbers().map(number => (
          <li key={number}>
            <button
              onClick={() => onPageChange(number)}
              className={`px-3 py-2 leading-tight ${number === currentPage ? 'text-white bg-[#0863AA]' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-[#0863AA] hover:text-white`}
            >
              {number}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-3 py-2 leading-tight text-black-500 bg-white  hover:bg-gray-100 hover:text-gray-700 font-bold"
          >
           <FaAngleDoubleRight/>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
