import React from 'react'
import {useCollapse} from 'react-collapsed';
import { FaAngleDown,FaAngleUp } from 'react-icons/fa';

function SectionCollapse(props) {
   
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div   className={"cursor-pointer my-3"} {...getToggleProps()} >
    <div className={isExpanded === false ? "flex flex-row justify-between py-3 font-semibold md:text-2xl xl:text-2xl text-lg xs:text-base  border-2 solid  md:px-10 xl:px-10 px-1 rounded-lg bg-white ": "flex flex-row justify-between py-3 font-semibold md:text-2xl xl:text-2xl text-lg xs:text-base  border-2 solid  md:px-10 xl:px-10 px-1 rounded-lg bg-white"}>
        <div className='flex flex-row'>
            <img src={props.icon} alt="icon" className='w-[30px] h-[30px] xs:w-[20px] xs:h-[20px] mx-3' />
            <p className="hover:underline">
                {props.title}
            </p>
        </div>
    <p className="md:text-[40px] xl:text-[40px] xs:text-[20px] sm:text-[30px]">
        {isExpanded ? <FaAngleUp /> :  <FaAngleDown />}
    </p>
    </div>
  
    
      <div className="border-2 solid border-[#d9d9de] rounded-lg  my-1 bg-white" {...getCollapseProps()}>
              <div  className="flex  flex-row md:px-10 xl:px-10 px-4 place-items-center py-1 ">
            <p  className=" md:text-justify text-base font-sans font-normal text-black whitespace-pre-wrap   py-2 md:px-2">
            {props.children}
            </p>
          </div>
     </div>
      
  </div>
 
  )
}

export default SectionCollapse