import React from 'react'
import {Link, useNavigate} from "react-router-dom"

const logo_mobile = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Benadir University Logo.png'
function Revorymessage() {
    const email = "email@domain.com"
  return (
    <div className='bg-blue-500 h-screen w-screen flex flex-col space-y-2 justify-center items-center  text-sm font-base text-white'>
         <a href="/" className="flex justify-center mb-8">
                        <img className="h-20" src={logo_mobile} alt=""/>
                      </a>
        <p>
            we succesfully sent instructions to this <a href="#" className='font-semibold'>{email}</a>.
        </p>
            <p>
                Please follow those instructions to reset your password
            </p>
            <p class="text-white text-center mt-8">Already have an account ?<Link to="/resetpassword" class="font-semibold mx-1">reset</Link></p>
    </div>
  )
}

export default Revorymessage