import React, {useState, useEffect} from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import {Link, useNavigate} from "react-router-dom"
import {login, getCurrentUser } from "../../../services/authServices"


const logo_mobile = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Benadir University Logo.png'


const ResetPassword = () => {
    const email = "reset@domain.com"


  const navigate = useNavigate();
  

    const validationSchema = yup.object({
        password: yup
          .string('Enter your password')
          .min(5, 'Password should be of minimum 5 characters length')
          .required('Password is required'),
        confirmPassword: yup
          .string('Confrim password')
          .oneOf([yup.ref('password'), null], "Passwords don't match")
      });

      useEffect( () => {
        // if(getCurrentUser()){
        //   return navigate('/dashboard')
        // }
      },[navigate])

    return  (
    <div className='bg-blue-500 h-screen w-screen flex justify-center items-center  text-[12px]'>
    <div className='xl:w-1/4 md:w-1/4 w-full'>
            <div className="card overflow-hidden sm:rounded-md md:rounded-md rounded-none">
                  <div className="px-6 py-8">
                    <div className='w-full mb-10 flex justify-center items-center'>
                     <p className='leading-6 font-base  font-semibold'>
                        Rest Password
                     </p>
                     </div>

                        <Formik
                        initialValues={{ password: '', confirmPassword: ''}}
                        validationSchema={validationSchema}
                        onSubmit={ async(values, actions) => {
                        actions.setErrors({username:'', email: ''})
                        // await login(values)
                        actions.setSubmitting(true)
                        navigate('/login')
                        }}
                        >
                        {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                        }) => 
                        (
                        <form onSubmit={handleSubmit} className=''>
                        <div className='mb-4'>
                           <div className="flex items-center justify-between mb-2">
                            <label for="loggingPassword">Password</label>
                           </div>
                          <input
                          placeholder="Password"
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className='form-input'
                          />
                          {errors.password && touched.password && errors.password}
                        </div>
                        <div className='mb-4'>
                           <div className="flex items-center justify-between mb-2">
                            <label for="loggingPassword">Confirm password</label>
                           </div>
                          <input
                          placeholder="Confirm password"
                          type="password"
                          name="confirmPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          className='form-input'
                          />
                          {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                        </div>
                       
                        <div className='flex justify-center mb-3'>
                        <button type="submit" disabled={isSubmitting} className='btn w-full text-white bg-blue-500  hover:bg-[#465B53]'>Reset</button>
                        </div>

                        </form>
                        )}
                        </Formik>

                  </div>
            </div>
  </div>
  </div>
)};

export default ResetPassword;