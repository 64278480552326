import React,{useState, useEffect} from 'react'
import { useLocation, useParams } from "react-router-dom";
import { MdWatchLater } from "react-icons/md";
import parse from "html-react-parser";
import api from "../../../config.json"
import _ from "lodash"
import {getAllActivity} from "../../../services/activityServices"

var options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

function ActivityDetail() {
  const [myActivity, setMyActivity] = useState([]);
  let activity;


  const { state } = useLocation();
  const prm = useParams();
 
  
  useEffect(() => {
    async function fetchData() {
      const response = await getAllActivity();
      const currentActivity = response.data;
      setMyActivity(currentActivity);
    }
    fetchData();
  }, [myActivity]);

  if (state) {
    activity = state.activity;
  } else if (prm) {
    activity = _.find(myActivity, ["_id", prm._id]);
  }
  const url = api.url;
 

  return (
    <div className='xl:container md:mx-10 mx-auto md:py-4 xl:py-3 md:px-5 xl:px-5 text-black border-2 rounded-lg my-2 xs:p-3'>
      {
        activity && (
          <div>
                    <div className="flex items-center ">
              <MdWatchLater className="xs:hidden mr-3" />
              <p className="sm:text-[14px] xs:text-[14px]">
                Published on{" "}
                {new Date(activity.createdAt).toLocaleDateString(
                  "en-US",
                  options
                )}{" "}
                | Updated on{" "}
                {new Date(activity.updatedAt).toLocaleDateString(
                  "en-US",
                  options
                )}{" "}
              </p>
        </div>
        
        <div className="my-2">
              <p className="font-semibold md:text-[30px] xl:text-[30px] tex-[22px] mb-6">
                {activity.title}
              </p>
              <div>
                <p className="my-4 whitespace-pre-line">
                  {parse(activity.activityContent)}
                </p>
              </div>
              <div className="grid xl:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 gap-3 place-items-center w-full my-3">
                {
                    activity.imageList.map( img => (
                        <img key={img._id} src={url + img.imageUrls} alt="activity" />
                    ))
                }
              </div>
        </div>
          </div>

        )
      }

        
    </div>
  )
}

export default ActivityDetail