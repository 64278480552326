import React, {useState} from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {Link} from 'react-router-dom';
function NewSchool() {
    const [selectedImage, setSelectedImage] = useState(null);
    
  return (
    <div>
        <div className='px-5 py-6 mx-5 my-3'>
            
            <div className='flex justify-between py-2'>
            <p className='my-2'>Post a school</p>
            <Link to="/schools" className="font-medium bg-blue-700 text-white dark:text-blue-500 hover:bg-blue-900 px-3 py-1 rounded-lg">Save</Link>
            </div>
            <input type="text" placeholder='News title' className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" name="search"/>
            <textarea className="my-2 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder='News Description' name="content" rows="4" cols="50"/>
            <textarea className="my-2 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder='News Content' name="content" rows="8" cols="50"/>

            <input type="file" 
            accept="image/png, image/jpeg, image/jpg"
            name="myImage" 
            className="block w-full text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-violet-700
                hover:file:bg-violet-100"
                onChange={(event) => setSelectedImage(event.target.files[0])}/>

        {selectedImage && (
            <div>
                <div className='flex justify-end mr-5 w-[350px]'>

            <AiOutlineCloseCircle className='font-bold text-red-700' onClick={()=>setSelectedImage(null)}/>
                </div>
            <img alt="not fount" width={"350px"} src={URL.createObjectURL(selectedImage)} />
            </div>
            )}
        </div>
    </div>
  )
}

export default NewSchool