import React from 'react'
import AdmissionNav from './admissionNav';


const Admission = ({children}) => {
 
  const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

  const campus =  backet + '/admission/ardayshidan.PNG'
  return (
      <div className=''>
        <div className='relative w-full'>
            <img src={campus} className='w-full relative object-cover xl:h-[650px] md:h-[558px] sm:h-[400px] xs:h-[400px]' alt="admission"/>
            <div className='absolute top-[80%] w-full h-[15%] bg-white opacity-50 flex justify-center items-center'> </div>
            <p className='  xs:text-4xl sm:text-5xl xl:text-7xl md:text-6xl  font-bold text-[#0843aafe] text-center uppercase absolute w-full top-[83%]'>
                Admissions
              </p>
        </div>
        <AdmissionNav />
              {children}
        </div>
  )
}
export default Admission