import React, {useState, useEffect} from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import {Link, useNavigate} from "react-router-dom"
import {login, getCurrentUser } from "../../../services/authServices"


const logo_mobile = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Benadir University Logo.png'


const Login = () => {


  const navigate = useNavigate();
  

    const validationSchema = yup.object({
        email: yup
          .string('Enter your email')
          .email('Enter a valid email')
          .required('Email is required'),
        password: yup
          .string('Enter your password')
          .min(5, 'Password should be of minimum 5 characters length')
          .required('Password is required')
      });

      useEffect( () => {
        if(getCurrentUser()){
          return navigate('/dashboard')
        }
      },[navigate])

    return  (
    <div className='bg-blue-500 h-screen w-screen flex justify-center items-center  text-[12px]'>
    <div className='xl:w-1/4 md:w-1/4 w-full'>
            <div className="card overflow-hidden sm:rounded-md md:rounded-md rounded-none">
                  <div className="px-6 py-8">
                      <a href="/" className="flex justify-center mb-8">
                        <img className="h-10" src={logo_mobile} alt=""/>
                      </a>

                        <Formik
                        initialValues={{ email: '', password: ''}}
                        validationSchema={validationSchema}
                        onSubmit={ async(values, actions) => {
                        actions.setErrors({username:'', email: ''})
                        await login(values)
                        actions.setSubmitting(true)
                        navigate('/dashboard')
                        }}
                        >
                        {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                        }) => 
                        (
                        <form onSubmit={handleSubmit} className=''>
                        <div className='mb-4'>
                          <label className="mb-2" htmlFor="LoggingEmailAddress">Email Address</label>
                          <input
                          placeholder="Email address"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className='form-input'
                          />
                          {errors.email && touched.email && errors.email}
                        </div>
                        <div className='mb-4'>
                          <div className="flex items-center justify-between mb-2">
                            <label htmlFor="loggingPassword">Password</label>
                            <Link to="/recoverpassword" className="text-[12px] font-semibold text-blue-500">Forget Password ?</Link>
                          </div>
                          <input
                          placeholder="Password"
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className='form-input'
                          />
                          {errors.password && touched.password && errors.password}
                        </div>
                        <div className="flex items-center mb-4">
                          <input type="checkbox" className="form-checkbox rounded" id="checkbox-signin"/>
                          <label className="mx-2" htmlFor="checkbox-signin">Remember me</label>
                        </div>
                        <div className='flex justify-center mb-3'>
                        <button type="submit" disabled={isSubmitting} className='btn w-full text-white bg-blue-500 uppercase hover:bg-[#465B53]'>Sign In</button>
                        </div>

                        </form>
                        )}
                        </Formik>

                  </div>
            </div>
  </div>
  </div>
)};

export default Login;