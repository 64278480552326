import React, { useState } from 'react'
import { BsArrowLeftCircleFill } from 'react-icons/bs';

import jsonData from '../../data/graduatedStudents.json'
import { useNavigate } from 'react-router-dom';

const logo = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/icon.png';

const panner = "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/panner.PNG";

const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';


const cover =  backet + '/others/cover.jpeg'

const CertificateVerification = () => {
  const [searchTerm,setSearchTerm] = useState('');
  const [stid,setStid] = useState('');
  const navigate = useNavigate();

  function handleChange(e) {
    setStid(e.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSearchTerm(stid)
  };

  const JSONDATA = jsonData.Students
  // console.log(JSONDATA)
  return (
      <div className='bg-[#F2F5F8] flex flex-col'>
         <div className='relative w-full h-[300px] mx-h-[300px]'>
          <img src={cover} className='w-full object-cover h-[300px] mx-h-[300px]' alt="cover"/>
        </div>
        <div className='flex flex-row sm:flex-col xs:flex-col'> 

        
        <div className='bg-gray-100 shadow-xl md:w-[25%] xl:w-[25%] max:w-[450px]  px-10  flex flex-col items-start space-y-5 py-20'>

            <h1 className='capitalize mt-5 text-[#0863AA] text-[20px] font-bold '>Enter student ID </h1>
            <p className='text-base text-[#0863AA] '>
            Please enter the student ID written on the transcript.
            </p>
        
            <form className='w-full flex justify-center flex-col items-center' >
              <div className='w-full '>
              <input className='border-2 border-gray-300 py-3 px-5 text-center text-white w-full focus:outline-none rounded-full bg-[#0863AA]'  
                id="stid"
                name="stid"
                value={stid}
                onChange={handleChange}
                placeholder='Enter certificate code'/>
              </div>
              <button className='bg-[#0863AA] text-white my-5 px-3 py-2' onClick={handleSubmit}>Verifiy</button>
            </form>
   
        </div>

        <div>
        
        </div>
        <div className='md:w-[75%] xl:w-[75%] text-[#0863AA] text-[20px] tracking-wide mt-10'> 

        {JSONDATA.filter((val)=>{
                  if(searchTerm === ""){
                    return 0
                  }
                  else if(val.SID !== searchTerm.toUpperCase()){
                    (<div>Unfortunately, this student id does not exist!</div>)
                    return 0;
                  }
                  else if(val.SID === searchTerm.toUpperCase()){
                    // val.SID.includes(searchTerm.toUpperCase())
                    return val;
                  }
                }).map((val,i)=>{
                  
                  return (
                    
                <div key={i} className='flex flex-col mt-5 px-5 overflow-hidden '> 
                {val ==="" ? 
                "not found" 
                : 
                <>
                  <div className='flex flex-col items-center px-3 space-y-4 sm:text-base xs:text-base '>
                      <p >
                      This is to certify that
                      </p>
                        <p className='font-bold'> {val["SNAME"]}</p>
                        <p className='md:w-[40%] xl:w-[40%] text-center'>
                        Having passed all the precertified examinations and
                          Satisfied all other university requirements.
                        </p>
                        <div className='flex flex-row justify-center'>
                        <p>in the year </p>
                        <p className='font-bold mx-2'>{val["Year"]}</p> 
                        </div>
                     <p>Has been awarded the degree of</p>
                     <p className='font-bold mx-2'>{val["Degree"]}</p> 
                  </div>
                  
                  </>
                  }
              </div>
              
              )
            })}
        
      </div>
      </div>
      </div>
   
  )
}

export default CertificateVerification