export const cslabs = {
  title: "LABORATORY FACILITIES",
  desc: "The faculty has well-equipped laboratory with state-of-the-art computer hardware and software.",
  lst: [
    "The software facility at the faculty includes popular operating system platforms, programming language compilers, development tools, DBMS packages, etc. Both free and proprietary software packages are used.",
    "The faculty has a switched Fast Ethernet network connecting all nodes of the Lab through structured cabling.",
  ],
};

export const sllabs = {
  title: "FACULTY ADMISSION REQUIREMENTS",
  desc: " The College adopts standards and admission requirements of the university, and besides that the College requires the applicants: ",
  lst: [
    "The average grade rate of the certificate should not be less than 65%. ",
    " The average grade rate of the subjects: Islamic education, Arabic    language, and English should not be less than 75%.",
  ],
};

export const agrlabs = {
  title: "TEACHING AND TRAINING FACILITIES",
  desc: " The College adopts standards and admission requirements of the university, and besides that the College requires the applicants: ",
  lst: [
    "3 Open Farms",
    "Greenhouse",
    "Well-equipped laboratory and",
    "Well-organized classes",
  ],
};

export const vetlabs = {
  title: "FACULTY PROGRAMMES",
  desc: "In principle, the faculty runs three programs to make students eligible for a bachelor's degree",
  lst: ["Veterinary Clinic", "Veterinary Laboratory", "Farm animal"],
};

export const hstlabs = {
  title: "FACULTY PROGRAMMES",
  desc: "In principle, the faculty runs two programs to make students eligible for a bachelor's degree and a professional diploma.",
  lst: ["Programme (1)", "Programme (2)"],
};

export const geolabs = {
  title: "FACULTY LABS",
  desc: "In principle, the faculty runs two programs to make students eligible for a bachelor's degree and a professional diploma.",
  lst: [
    "Geological specimens (Rocks, Minerals, Fossils) were collected different areas in Somalia and worldwide.",
    "High performance liquid chromatography ",
    "polarized microscopes",
    "Different Geological Maps",
    "Geological Instruments for Field Geology",
  ],
};
