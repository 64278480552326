export const rules = [
    {
        id: 1, 
        title: "Introduction", 
        desc: "University Registration Department is one of the backbones of the University, supporting the University's closeness to university Strategy planning and serving academies including postgraduate and undergraduate, students, and graduates. Registration role included implementation of the rules for entering or leaving and controls the Drops of the students registered at the University. Annual Calendar Preparation, Keeping Records of graduates, Monitoring Lecturer Allowances, Academic / Official Transcripts, University Management and Development System (BUMS), and Monitoring of the following Offices:", 
        parts: [] 
    },
    {
        id: 2, 
        title: "Enrolment of Students", 
        desc: "Rule of the admission at the end of every academic year, the council for academic and scientific affairs determines the number of students who may be admitted to the each faculty year, according to the university’s available resources, correspondence should be addressed to the general registrar. Students will be in close contact with the registrar throughout their course of study in the university, Names of students accepted in every faculty will be announced, and the student’s choice and the marks he/she obtained in the subjects recommended for each faculty.", 
        parts: [] 
    },
    {
        id: 3, 
        title: "Admission Requirements", 
        desc: "", 
        parts: [
            { id: 1, 
                title: "Undergraduate Students" , 
                desc1: "Students submitting to the degree of Bachelor in Benadir University their enrolment application must meet the following entry requirements:", 
                list1:[
                    "Application Letter",
                    "Secondary Leaving Certificate",
                    "Birth certificate or a notarized copy of it.",
                    "Four passport photo size.",
                    "Pay 25$ Admission Fee Entrance Exam"
                ],
                desc2: "After all admission requirements the student should get:", 
                list2:[
                    "Getting Admission Entrance Card and timetable of exam.",
                    "If the student pass admission exam to came with reasonable responsible of his family to fill Admission & Registration Fee to gather."
                ]   
            },
            { id: 2, 
                title: "Postgraduate Students" , 
                desc1: "All candidates can apply for specialization by submitting the following documents:", 
                list1:[
                    "Application Letter.",
                    "Four passport photo size.",
                    "Copy and Original of the University and Transcript.",
                    "Applicant CV.",
                    "Attaining above Average marks in admission exam and Interview.",
                    "Pay 50$ Admission Fee.",
                    "All applicants submit Experience Letter."
                ]
            },

        ] 
    },
    {
        id: 4, 
        title: "Restoration of Suspended Registration", 
        desc: "A student whose registration is suspended may request for restoration of registration provided he/she has cleared all the issues that called for suspension and pays all dues.",
        parts: [] 
    },
    {
        id: 5, 
        title: "Double Degree Admission", 
        desc: "Concurrent admission in more than one degree program is not allowed.",
        parts: [] 
    },
    {
        id: 6, 
        title: "Study Stoppage", 
        desc: "A student may stoppage his/her semester for a maximum of 4 semesters.",
        parts: [] 
    },
    {
        id: 7, 
        title: "Re-Admission", 
        desc: "A former student who has been on disciplinary warning shall not be re-admitted. A former student, whose admission was cancelled due to any reason from the university, He is not allowed re-admission.",
        parts: [] 
    },

    

]

