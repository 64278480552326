import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PageTitle from "../../general/pageTitle";
import { updateUser } from "../../../services/portalServices";

function UpdateUserPortal(props) {
  const navigate = useNavigate();

  const { state } = useLocation();
  const u = state.user;

  const validationSchema = yup.object().shape({
    studentName: yup
      .string("Enter student Name")
      .required("Enter student Name"),
    studentID: yup.string("Enter student ID").required("Enter student ID"),
    Password: yup.string("Enter Password").required("Enter Password"),
    confirmPassword: yup
      .string("Confrim password")
      .oneOf([yup.ref("Password"), null], "Passwords don't match"),
  });

  return (
    <>
      <PageTitle title="Update user" subTitle="Portal" />

      <div className=" w-full overflow-auto  ">
        <div className="flex flex-col justify-center p-4 ">
          <Formik
            enableReinitialize={true}
            initialValues={{
              studentName: u.studentName,
              studentID: u.studentID,
              Password: u.Password,
              confirmPassword: u.Password,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              const resp = await updateUser(values);
              navigate("/portal");
            }}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col bg-white border shadow-sm rounded-xl"
              >
                <div className="flex justify-between items-center py-2.5 px-4 border-b">
                  <h3 className="font-bold text-gray-800">Update new user</h3>
                  <Link
                    to="/portal"
                    className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                  >
                    <IoMdClose />
                  </Link>
                </div>
                <div className="p-4 overflow-y-auto">
                  <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Student ID
                      </label>
                      <input
                        className="form-input"
                        name="studentID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.studentID}
                      />
                      {errors.studentID &&
                        touched.studentID &&
                        errors.studentID}
                    </div>
                    <div>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Student Name
                      </label>
                      <input
                        className="form-input"
                        name="studentName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.studentName}
                      />
                      {errors.studentName &&
                        touched.studentName &&
                        errors.studentName}
                    </div>
                    <div>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-input"
                        name="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Password}
                      />
                      {errors.Password && touched.Password && errors.Password}
                    </div>
                    <div>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-input"
                        name="confirmPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      {errors.confirmPassword &&
                        touched.confirmPassword &&
                        errors.confirmPassword}
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t my-4">
                    {/* <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn bg-secondary text-white"
                >
                  Cancel
                </button> */}
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn bg-primary text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default UpdateUserPortal;
