import React,{useEffect, useState} from 'react'
import api from "../../config.json";
import { AiOutlineWifi } from 'react-icons/ai';
import { MdWatchLater } from 'react-icons/md';
import { Link } from 'react-router-dom'
import _ from 'lodash';
// import {news} from "../../data/news"
import {getNewsHeaders, getAllNews} from "../../services/newsServices"


const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';
const Slide5 = backet + '/others/Slide5.jpg';
const it = backet + '/others/it.jpg';
const cool = backet + '/others/cool.jpg';
const future = backet + '/others/future.jpg';
const campus = backet + '/others/campus.png';
const img1 = backet + "/news/1.jpg"
const img2 = backet + "/news/2.jpg"

const url = api.url


const News = () => {

      const [newsData, setNewsData] = useState([]);
      const [latestNews, setlatestNews] = useState([]);

var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

      useEffect( () => {
    
        async function fetchData() {
            const response = await getAllNews();
            // const userNames = response.data.map(res => res.username)
            const news = response.data
            // const sortedNews = news.sort( (a,b) => b.newsId - a.newsId)
            // const sortedNews = _.sortBy(news, [function (o) { return o.newsId; }])
            const sortedNews = _.orderBy(news, 'createdAt', 'desc');

           
            setNewsData(_.slice(sortedNews, 1,5));
            setlatestNews(_.head(sortedNews));
          }
          fetchData();
      },[newsData])



    
      
      

      // const sortedNews = news.sort( (a,b) => b.id - a.id)

      // const newsData = _.slice(sortedNews, 1,5)
      // const latestNews = _.head(sortedNews)
      // console.log(latestNews)
   


      
  return (
    <div>
         {/* news */}
      <div className='flex flex-col px-4 md:px-10 xl:px-10 mt-10 bg-[#e9f0e0]'>

            {/* top */}
            <div className='flex flex-row justify-between py-5'>
            <div className='flex items-center gap-x-6'>
            <p className='text-[23px] md:text-[30px] xl:text-[30px] font-semibold font'>News</p>
            <p className='hidden md:flex xl:flex md:text-[18px] xl:text-[20px]'>Latest news from Benadir University</p>
            </div>

            <AiOutlineWifi className=''/>
            </div>
            <div className='bg-white h-[0.5px]'></div>
            <div className='bg-black h-[0.5px] w-[80px]'></div>

            {/* second */}
            <div className='grid  md:grid-cols-3 xl:grid-cols-3 mb-5  md:gap-6 xl:gap-6 justify-center items-center mt-3'>
            {/* card one */}
            <div className='bg-white rounded-lg shadow-lg cursor-pointer hover:border-[#000] hover:border-2'>
            <Link to={`/news/${latestNews.slug}`} state={{ news: latestNews }}>
                  <img loading="lazy" src={url + latestNews.titleImageUrl} className='rounded-t-lg object-cover  md:flex xl:flex md:h-[200px] md:w-[500px] h-[230px] w-full' alt="news"/>

            <div className='px-6 mt-8 mb-10'>
            <p className='font-bold text-[14px] md:text-[1rem] xl:text-[1.1rem] line-clamp-2'>{latestNews.title}</p>
            <div className='my-2 flex gap-2 text-slate-500 items-center'>
            <MdWatchLater/>
            <p>{new Date(latestNews.createdAt).toLocaleDateString("en-US", options)}</p>
            </div>
            <p className='text-start text-[13px] line-clamp-3'>{latestNews.newsContent}</p>
            </div>
            </Link>
            </div>

            {/* card two */}
            <div className='col-span-1 md:col-span-2 xl:col-span-2 mt-5 md:mt-0 xl:mt-0'>

            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 md:gap-6 xl:gap-6 gap-y-3 '> 

            {/* post one */}
            {
                  newsData.map( n => (
                        <div key={n._id} className='relative cursor-pointer border-[1px]  rounded-lg md:h-[200px] md:mx-h-[200px] xl:h-[200px] xl:mx-h-[200px] hover:border-[#000]'>
                              <Link to={`/news/${n.slug}`} state={{ news: n }}>
                                    <img src={url + n.titleImageUrl} loading="lazy" className='md:rounded-t-lg xl:rounded-t-lg w-full object-cover h-full hidden md:flex xl:flex' alt="not found"/>
                                    <div className='bg-white  md:absolute xl:absolute md:bottom-0 xl:bottom-0 w-full opacity-80 sm:rounded-lg xs:rounded-lg h-[75px]'>
                                          <p className='px-2 py-1 text-[14px] md:text-[1rem] xl:text-[1.1rem] font-semibold line-clamp-3'>{n.title}</p>
                                    </div>
                              </Link>
                        </div>
                  ))
            }
            </div>
            </div>
            </div>
            <div className='flex justify-center md:justify-end xl:justify-end m-4'>
            <Link to="/news" className='bg-white border-2 font-semibold border-[#0863aa] text-[#0863aa] py-2 px-6 rounded hover:bg-[#0863aa] hover:text-white hover:border-0'>Sea More</Link>
            </div>
            </div>
            </div>
  )
}

export default News