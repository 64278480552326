import React from "react";
import {  Link, NavLink } from "react-router-dom";


const AdmissionNav = props => {
   const menus = [
    { id: 1, menu: "Admission", link: "admisionnotice" },
    { id: 2, menu: "Contact", link: "admissioncontacts" },
    { id: 3, menu: "Required Documents" , link: "checklists"},
    { id: 4, menu: "Admission Test Result" , link: "result"},
  ]
  
  return (
         <div className="flex flex-row xs:flex-col sm:flex-col  justify-center  w-full  bg-[#e7e9ea]" >
        {
          menus.map( m => (
              <NavLink
                key={m.id} to={`/admision/${m.link}`}
                className={({ isActive }) =>
                isActive ? " font-sans w-fit font-semibold text-xl sm:w-full xs:w-full  px-16  text-[#5ab848] hover:bg-[#ffffff] bg-[#ffffff] hover:text-[#0863AA] py-8" : " font-serif w-fit sm:w-full xs:w-full font-semibold text-xl  text-[#0863AA]   py-8 px-16  hover:bg-white hover:text-[#0863AA] "}>
                    {m.menu}
              </NavLink>
          ))
        }
      </div>
  );
};
export default AdmissionNav;