import React, { useState } from 'react'
import _ from "lodash";
import Bidey from "../../../assets/images/profle/leadership/1.jpg"
import BioNav from './bioNav';

const Bio = ({children}) => {
    // const 
  return (
    <div className='flex flex-col my-2 md:mx-3 xl:mx-3'>
    <p className='text-black text-3xl sm:text-2xl xs:text-xl md:my-3 xl:my-3'>
    The 5<sup>th</sup> Rector of Benadir University
    </p>
    <div className='flex flex-row sm:flex-col xs:flex-col'>
    <img src={Bidey} alt="rector" className='h-[250px] w-[250px] rounded-md' />
    <div className='md:mx-10 xl:mx-10 mx-3 text-black font-montserrat space-y-4'>
      <p className='font-semibold text-xl sm:text-sm xs:text-sm my-3'>Prof. Dr. Mohamed Mohamud Hassan</p>
            <p className='text-base'>
            Rector of Benadir University, One of the founders of Benadir University
            </p>
            <p className='text-base'>
            Senior Adviser on Higher Education to the Minister of Education, Culture, and Higher Education of the Federal Government of Somalia, 
            </p>
            <p className='text-base'>
            Chairman of the Association of Somali Universities, and 
            </p>
            <p className='text-base'>
            Vice-Chairman of SomaliREN
            </p>
    </div>
    </div>
    <div className=' text-black space-y-3 md:my-3 xl:my-3 sm:my-10 xs:my-10 font-montserrat'>
      <p>
        Professor Dr. Mohamed Mohamud Hassan, also known as <strong>Dr. Biday</strong>, is a 
        physician who has dedicated himself to serving his community. As a 
        cardiologist, he has gone above and beyond to provide medical care to those 
        who cannot afford it, especially the underserved and underprivileged. Prof. Dr. 
        Mohamed's contributions have made a significant impact on the lives of many 
        people in his community.
      </p>
      <p>
        Professor Dr. Mohamed is a dedicated public servant who has taken on 
        several roles to help his community. He is the current Rector of Benadir 
        University and one of its founders. Furthermore, Prof. Dr. Mohamed is a Senior 
        Adviser on Higher Education to the Minister of Education, Culture, and Higher 
        Education of the Federal Government of Somalia. Additionally, he serves as the 
        Chairman of the Association of Somali Universities and as the Deputy 
        Chairman of SomaliREN, both of which are important organizations in the 
        education sector.
      </p>
      <p>
      Prof. Dr. Mohamed is the founder and owner of Biday Specialist 
      Hospital, which offers medical services to the Somali community. He has a 
      strong commitment to serving his community and Somali students, and this 
      dedication is evidenced by the fact that he was seriously injured while doing so. 
      Despite this setback, the professor remains determined to continue supporting 
      his community in any way he can
      </p>
    </div>
    </div>
)
}

export default Bio