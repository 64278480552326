import React, {useState, useEffect} from 'react'
import EventCard from "./eventCard"
import {Link, useNavigate} from 'react-router-dom'
import { news } from "../../../data/news"
import {getAllNews} from "../../../services/newsServices"


const Events = () => {
  const [eventList, setEventList] = useState([]);

  useEffect( () => {

    async function fetchData() {
        const response = await getAllNews();
        // const userNames = response.data.map(res => res.username)
        setEventList(response.data);
      }
      fetchData();
  },[eventList])



  return (
    <div className='py-5 px-4 bg-slate-50 shadow-lg mx-2 rounded-md'>
        <div className='bg-slate-200 space-x-4  py-3 px-3 mx-2  flex justify-between w-full'>
                <input
                    type="text"
                    placeholder="Search Here"
                    className="relative w-full py-2 px-3 outline-none rounded-md"
                />

            <Link to="/event-entery" className="w-fit font-medium bg-blue-700 text-white dark:text-blue-500 hover:bg-blue-900 px-3 py-1 rounded-lg shadow-lg flex justify-center items-center whitespace-nowrap ">Post an event</Link>
        </div>
        <div className="relative w-full h-fit ">
           <EventCard  events={eventList}/>
        </div>
    </div>
  )
}

export default Events