export const fees = [
  {
    id: 1,
    program: "Faculty of Medicine and Surgery",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 160" },
    ],
  },
  {
    id: 2,
    program: "Faculty of Education",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 25" },
    ],
  },
  {
    id: 3,
    program: "Faculty of Computer Scicence & IT",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 55" },
    ],
  },
  {
    id: 4,
    program: "Faculty of Engineering & Technology",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 105" },
    ],
  },
  {
    id: 5,
    program: "Faculty of Sharea and Law",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 45" },
    ],
  },
  {
    id: 6,
    program: "Faculty of Agriculture",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 55" },
    ],
  },
  {
    id: 7,
    program: "Faculty of Veterinary",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 55" },
    ],
  },
  {
    id: 8,
    program: "Faculty of Health Science",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 55" },
    ],
  },
  {
    id: 9,
    program: "Faculty of Economic & Management",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 55" },
    ],
  },
  {
    id: 10,
    program: "Faculty of Geology",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 55" },
    ],
  },
  {
    id: 11,
    program: "Faculty of Dentistry",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 110" },
    ],
  },
  {
    id: 12,
    program: "Faculty of Social Science",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 55" },
    ],
  },
  {
    id: 13,
    program: "Faculty of Marine Science",
    fees: [
      { id: 1, title: "Registeration Fee (yearly)", desc: "$ 50" },
      { id: 2, title: "ID card charges (yearly)", desc: "$ 10" },
      { id: 3, title: "Tuition Fee (monthly)", desc: "$ 55" },
    ],
  },
];
