import React, { useState } from 'react'
import {profile} from "../../../data/profile"
import {programs} from "../../../data/programs"
import Popular from "./popular"
import { pubs } from "../../../data/pubs";
import _ from "lodash";
import bg from '../../../assets/images/profle/2.jpg'

const Welcome = () => {
    const pub = _.last(pubs);
    const sortedPubs = _.orderBy(pub.item, "id", "desc");
    const research = _.slice(sortedPubs, 0,3);
    

  return (
    <>
    <div className=''>
        <img src={bg} alt="cover" className='w-full object-cover bg-center h-[500px]' />
    </div>    
<div className='flex md:flex-row xl:flex-row sm:flex-col xs:flex-col justify-center pt-10   w-[90]'>
    <div className='flex flex-col justify-center items-start mx-5 md:w-[50%] xl:w-[50%] '>
        <div className='w-fit flex flex-col '>

        <p className='text-5xl sm:text-xl xs:text-xl text-[#0863aa] font-bold'>Welcome to Benadir University</p>
        <p className='text-[10px]  place-self-end my-2 italic font-thin font-inriaserif'>Cultivating human tallensts</p>
        </div>
<div className='  my-10'>
    {
        profile.map( p => (
            <div key={p.id}>
                <div className='flex flex-row space-x-2 items-center '>
                {p.icon}
                <div className='bg-[#0863aa] w-fit px-3 shadow-lg'>
                    
            <p className='md:text-xl xl:text-xl sm:text-base xs:text-base font-semibold my-2 text-white'>{p.title}</p>
                </div>
                </div>
                <div className=' mt-3 mb-5'>
                <p className='text-xl text-justify mx-5 my-2 mb-3'>{p.desc}</p>
                </div>
                <div>
                   {p.img.length >0 &&
                   <div className='grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-3 '>
                   {p.img.map( m => (
                        <div key={m.id} className='p-3'>
                        <img src={m.img} alt="some" className='' />
                        </div>
                    )
                    )}
                    </div>
                   
                   }
                </div>
            </div>
        ))
    }
</div>
</div>
<div className='justify-center md:w-[25%] xl:w-[25%] px-5 md:border-l-2 xl:border-l-2 sm:border-t-2 xs:border-t-2 sm:mb-10 xs:mb-10'>
    <div className='sm:my-10 xs:my-10'>

    <p className='text-xl mb-5 font-bold'>Benadir Univerist's most popular programs</p>
    <hr />
    {
        <Popular list={programs[0].lists}/>   
    }
    </div>
    <div className='mt-10'>
    <p className='text-xl mb-5 font-bold'>Benadir Univerist's most popular research finds</p>
    <hr />
    {
        research.map( r => (
                <div key={r.id} className='flex flex-row '>
                    <a href={r.doi} target="_blank" rel="noopener noreferrer" className='my-1'>
                        <p className="mt-1 text-primary hover:underline">{r.title}</p>
                    </a>
                </div>
        ))
    }
    </div>
</div>
</div>
</>
)
}

export default Welcome