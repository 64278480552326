import React, { useState, useEffect } from "react";
// import { csStaff } from "../../../data/staff_data";
import { AiOutlineClose, AiOutlineWarning } from "react-icons/ai";
import PageTitle from "../../general/pageTitle";
import { LuRefreshCcw } from "react-icons/lu";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { getAllStaff } from "../../../services/staffServices";
import { findStaffJobs } from "../../../services/employStaff";
import _ from "lodash"


function StaffList() {
  const [staffData, setStaffData] = useState([]);
  const [togle, setTogle] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [currentStaff, setCurrentStaff] = useState();
  const [staffDetps, setStaffDetps] = useState([]);
  const [cofirmError, setCofirmError] = useState("");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");

  const fields = [{name: "Staff ID", value:"staffId"}, 
                    {name: "Name", value:"fullName"}, 
                    {name: "Email", value:"email"}, 
                    {name: "Mobile No", value:"mobileNumber"}]

  const handleDelete = async(s) => {
    const depts = await findStaffJobs(s.staffId);
    setStaffDetps(depts.data)
    setTogle(!togle);
    setCurrentStaff(s);
  };
  const handleDelateStaff = async (s) => {
    if (s) {
      if (confirm === s.staffId) {
        setCofirmError("");
        setConfirm("");
        setTogle(!togle);
        await handleReferesh()
      } else {
        setCofirmError("pleaes confirm deletion!");
      }
    }
  };

  const showModel = () => {
    setConfirm("");
    setCofirmError("");
    setTogle(!togle);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await getAllStaff();
      setStaffData(response.data);
    }
    fetchData();
  }, []);

  const handleSelect = (e) => {
    setFilter(e.currentTarget.value)
}
const handleSearch = () => {
  if(search){
    const result = _.find(staffData,[filter, search.trim()])
    if(result)  setStaffData([result])
  }
}



const handleReferesh = async() => {
    const response = await getAllStaff();
    setStaffData(response.data);
}
  return (
    <>
      <div>
        <PageTitle title="Staff List" subTitle="Staff" />
        <div className="grid lg:grid-cols-1 gap-6 mt-8 mx-3">
          <div className="card bg-white overflow-hidden">
            <div className="card-header flex justify-between">
              <div className="flex justify-center items-center  px-4 ">
                <div className="flex flex-row items-center">
                  <select
                    name="filter"
                    value={filter}
                    onChange={(e) => handleSelect(e)}
                    className="form-select md:w-[150px] font-semibold"
                  >
                    <option value="--select filter--">--select filter--</option>
                    {fields.map((f, i) => (
                      <option key={i} value={f.value}>
                        {f.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="form-input md:w-[400px] mx-3 text-[15px]"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleSearch}
                  type="button"
                  className="btn bg-primary text-white mx-2"
                >
                  Find
                </button>
              </div>
              <div>
                <Link to="/create-staff" className="btn bg-primary text-white">
                  New Staff
                </Link>
                {/* @@include("bu-staff-fomrs.html") */}
              </div>
            </div>
            <div className="p-4">
              <div className="overflow-x-auto">
                <div className="min-w-full inline-block align-middle">
                  <div className="border rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm text-gray-500"
                          >
                            Staff ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm text-gray-500"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm text-gray-500"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm text-gray-500"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm text-gray-500"
                          >
                            Mobile No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm text-gray-500  text-center"
                          ><div className="flex flex-row justify-between ">
                             Action
                             <p className="p-2 cursor-pointer  bg-slate-200 rounded-md hover:bg-slate-400" onClick={handleReferesh}>
                            <LuRefreshCcw />
                             </p>
                          </div>
                           
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {staffData.length > 0 &&
                          staffData.map((s) => (
                            <tr key={s.email}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                {s.staffId}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                {s.fullName}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                {s.title}{" "}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                {s.email}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                {s.mobileNumber}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium flex flex-row  justify-center space-x-2">
                                <Link to={`/edit-staff`} state={{ staff: s }}>
                                  <FaEdit
                                    size={20}
                                    className="cursor-pointer m-1 hover:text-primary"
                                  />
                                </Link>
                                <Link onClick={() => handleDelete(s)}>
                                  <MdDelete
                                    size={20}
                                    className="cursor-pointer m-1 hover:text-primary"
                                  />
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-30 ease-in duration-300 ${
          togle ? "" : "hidden"
        }`}
      >
        {/* <div className='bg-[#EDF4F4] xs:w-full  w-[300px] max:w-[500px]  overflow-auto pt-4 fixed '> */}
        <div className="flex flex-col justify-center ">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl">
            <div className="flex justify-between items-center py-2.5 px-4 border-b">
              <h3 className="font-bold text-gray-800">Delete Staff</h3>
              <button
                data-fc-dismiss
                type="button"
                className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                onClick={showModel}
              >
                <AiOutlineClose className="text-xl" />
              </button>
            </div>
            <div className="p-4 overflow-y-auto flex flex-row">
              <div className="mx-10">
                <AiOutlineWarning className="text-7xl text-danger" />
              </div>
              <div className="mx-7">
                {
                  currentStaff && 
                  (staffDetps.length >0 ? (
                    <div>
                      <p>You can not delete a staff works on any department. 
                        <br/>
                        To delete a staff, please fire him from the following departments :</p>
                      {staffDetps.map( (d,i) => (
                        <p key={i} className="mx-3 font-semibold">{d.department}</p>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <p className="mt-1 text-gray-800">
                        Do you want to delete this staff:
                          <br /><span className="font-semibold">{currentStaff.staffId} </span></p>
                          <div className="w-full  my-3">
                            <input
                              required
                              placeholder="write the staff-ID"
                              name="titleImageCaption"
                              onChange={(e) => setConfirm(e.target.value)}
                              value={confirm}
                              className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                            />
                            <div className="mx-4 my-2">{cofirmError}</div>
                          </div>
                    </div>  
                  ))
                }
              </div>
            </div>
            <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t">
              <button
                data-fc-dismiss
                type="button"
                className="btn bg-secondary text-white"
                onClick={showModel}
              >
                No
              </button>
              <button
                data-fc-dismiss
                type="button"
                disabled={staffDetps.length >0}
                className="btn bg-warning text-white"
                onClick={() => handleDelateStaff(currentStaff)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffList;
