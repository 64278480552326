import Histopathology from "./Histopathology.pdf";
import AnnualConf from "../assets/images/events/2nd-annual-confrence.png";
import thesis from "../assets/images/events/THESIS-02.jpg";
// import iihe1 from "../assets/images/events/1.jpg";
import iihe2 from "../assets/images/events/2.jpg";
import iihe3 from "../assets/images/events/3.png";
import iihe4 from "../assets/images/events/4.jpg";
import iihe5 from "../assets/images/events/5.png";
import iihe6 from "../assets/images/events/6.png";

import trading from "../assets/images/events/trading-system.jpg";
import remotesen from "../assets/images/events/remote-sensing.jpg";

import tmm from "../assets/images/events/TMM JB-01.jpg";
import tmmHeader from "../assets/images/events/tmm-header.jpg";


const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";

const e1 = backet + "/events/Graduation.jpg";
const e2 = backet + "/events/SETC.jpg";
const e4 = backet + "/events/3dec2009.jpg";
const e5 = backet + "/events/ACSOS2023.jpg";

export const events = [
  {
    id: 11,
    slug: "Tartanka-Maxkamada-Matalaada",
    detailDay: "27th - 31st of july 2024",
    day: "27 - 31",
    month: "July",
    img: tmm,
    img_header: tmmHeader,
    eventType: "Challenges",
    venue: "BU Dr.Adow Campus",
    desc: `<div><p>Maamulka Kuliyadda Sharciga ee Jaamacdda Benadir waxa uu ogeysiinayaa Kuliyadaha Sharciga ee Jaamacadaha Soomaaliyeed  in uu qabanaayo Tartan Maxkamad Matalaad ah.</p>
<span className="text-xl font-semibold">Ujeedooyinka Tartanka Maxkamada Matalaada ah waxaa kamid ah:</span>
<ol className="list-decimal mx-8">
<li>Ku baahinta bulshada fahamka & ku dhaqanka  sharciga si loo gaaro cadaalad waarta .</li>
<li> Kor uqaadista aqoonta ardayda barata sharciga iyadoo ay ubedelaayaan Theoretical kii ay kuqaateen galaasyada mid Practical ah.</li>
<li> Kor uqaadista is dhaxgalka & kala faaiideysiga khibradaha oogista dacwadaha ardayda barata shrciga .</li>
<li> Si loo hubiyo hufnaanta Tartanka Maxkamadda Matalaad ah waxa qiimeyndoona sedax (3) garyaqaan oo ka madax banana jaamacadaha.</li>
</ol>
<span className="text-xl font-semibold">Shuruudaha Tartanka Maxkamada Matalaada ah:</span>
<ol className="list-decimal mx-8">
<li> In Maamulka Kuliyadda Sharciga uu soo qoro codsi ka qeybgelka Tartanka iyo liiska & ID-yada 10 arday oo uga qeyb galeysa tartanka <strong>ugu danbeen 15<sup>th</sup> of july, 2024</strong>.</li> 
<li>Tartanka waxaa loo qorsheeyay inuu dhaco 27<sup>th</sup> of July, wuxuuna socon doonaa <strong> shan maalmood lagabilaabo 27<sup>th</sup> ilaa iyo 31<sup>st</sup> of july, 2024.</strong></li>
<li> In ardayda ka qeyb geleysa Tartanka ay naqdaan ardayda dhigata hadda Kuliyadda Shrciga.</li>
<li> Waxaa looga qeyb geli karaa Tartanka Maxkamadda Matalaada ah mid kamid ah Dacwadaha kala ah :</li>
<ol className="list-disc mx-8">
<li> Dacwadaha  Ciqaabta. </li>
<li> Dacwadaha Maamulka. </li>
<li> Dacwadaha  Madaniga.</li>
<li> Dacwadaha Qoyska.</li>
<li> Dacwadaha Shaqada.</li>
</ol>
</ol>
</div>
    <img src="${tmm}" className='w-full    bg-cover bg-center duration-500' alt="cover"/>
    <p>Wixii faahfaahin ah Kala soo xiriir : </p>  
    <p>E-mail : dean-sharialaw@bu.edu.so</p>
    <p>Telka : +2526-1-5839898 ama +2526-159820261</p>`,
    Time: "10:00AM",
    title: "Tartanka Maxkamada Matalaada.",
    EventDate: "2024, 07,31",
  },
  {
    id: 10,
    slug: "Remote-Sensing-Technology-is-best-Tool-learning-Geoscience-student",
    detailDay: "20th of May 2024",
    day: "20",
    month: "May",
    img: remotesen,
    img_header: remotesen,
    eventType: "Seminar",
    venue: "BU Dr.Adow Campus",
    desc: `<p>This event will occur on monday 20<sup>th</sup> of may 2024.</p>
    <img src="${remotesen}" className='w-full    bg-cover bg-center duration-500' alt="cover"/>`,
    Time: "10:00AM",
    title: "Remote Sensing Technology is best Tool learning Geoscience student.",
    EventDate: "2024, 05,20",
  },
  {
    id: 9,
    slug: "Multilateral-Trading-System",
    detailDay: "20th of May 2024",
    day: "20",
    month: "May",
    img: trading,
    img_header: trading,
    eventType: "Seminar",
    venue: "BU Dr.Adow Campus",
    desc: `<p>This event will occur on monday 20<sup>th</sup> of may 2024.</p>
    <img src="${trading}" className='w-full    bg-cover bg-center duration-500' alt="cover"/>`,
    Time: "01:00PM",
    title: "Multilateral Trading System",
    EventDate: "2024, 05,20",
  },
  {
    id: 8,
    slug: "conference-of-Internationalization-in-Higher-Education",
    detailDay: "25th of Oct - 27th of Oct, 2024",
    day: "25 - 27",
    month: "Oct",
    img: iihe2,
    img_header: iihe6,
    eventType: "Conference",
    venue: "OMU, Samsun - Turkey",
    desc: `Benadir University is excited to be the only higher Education Institution in Somalia being member of the conference of Internationalization in Higher Education. 
    Stakeholders with different cultures and geographies will share valuable academic and institutional experiences.
    <div className="flex flex-col justify-center items-center w-full my-3">
    <img src="${iihe2}" alt="current month" className="w-[600px]"/>
    </div>
    <p className="text-xl text-black font-semibold">Call For Paper IHEC 2024</p>
  
    Registration: <a href="https://ihec.omu.edu.tr/register.php">ihec.omu.edu.tr/register.php</a>
    
    Important Dates:
  - 15 July :Deadline of abstract submission.
- 20 August: Deadline of abstract submission (normal registration).
- 10 Sep: Announcement of Abstract
- 25 Oct : Hybrid Conference
- 10 Nov : Deadline for Full-text Submission

To ensure the inclusion of papers in the Web of Science (WoS) and their subsequent publication, an additional fee is required for papers accepted after the editorial processes. Please note that this fee is separate from the conference registration fee.

<img src="${iihe4}" alt="current month" className="w-[600px]"/>

For further details, including information about our esteemed keynote speakers, please see the picture bellow.

<img src="${iihe3}" alt="current month" className="w-[600px]"/>

You can find all the details about the conference in the image bellow.

<img src="${iihe5}" alt="current month" className="w-[600px]"/>
    `,
    Time: "09:30am",
    title: "Internationalization in Higher Education",
    EventDate: "2024, 10,27",
  },
  {
    id: 7,
    slug: "How-to-write-an-effective-thesis-book",
    detailDay: "22nd of Jan - 24th of Jan, 2024",
    day: "22 - 24",
    month: "Feb",
    img: thesis,
    img_header: thesis,
    eventType: "Seminar",
    venue: "BU Dr.Adow Campus",
    desc: ``,
    Time: "03:30PM",
    title: "How to write an effective thesis book",
    EventDate: "2024, 01,25",
  },
  {
    id: 6,
    slug: "2nd-ANNUAL-RESEARCH-&-INNOVATION,-DISSEMINATION-CONFERENCE",
    detailDay: "1st of Feb - 3th of Feb, 2024",
    day: "01 - 03",
    month: "Feb",
    img: AnnualConf,
    img_header: AnnualConf,
    eventType: "CONFERENCE",
    venue: "BU Dr.Adow Campus",
    desc: ``,
    Time: "09:00AM",
    title: "2nd ANNUAL RESEARCH & INNOVATION, DISSEMINATION CONFERENCE",
    EventDate: "2024, 06,01",
  },
  {
    id: 5,
    slug: "histopathology",
    detailDay: "31<sup>st</sup> of Jan  -  4<sup>th</sup> of Feb, 2024",
    day: "31 - 04",
    month: "Feb",
    img: backet + "/events/Histopathology.jpg",
    img_header: backet + "/events/eventhistopathology.jpg",
    eventType: "Training",
    venue: "Dr.Adaw Campus",
    desc: `\n<strong>Introduction</strong>\n
    Histopathology is a branch of pathology that involves the microscopic examination of tissues to study the structural changes caused by diseases. In this field, trained pathologists analyze thin sections of tissue samples, typically obtained through biopsy or surgical procedures, using a microscope. The goal is to identify abnormalities, such as tumors, inflammation, or infections, and to understand how these changes relate to the patient’s clinical condition. Histopathology plays a crucial role in the diagnosis and management of various medical conditions, guiding treatment decisions, and providing essential information for prognosis.\n
    In Africa, histopathology assumes particular significance due to the high prevalence of infectious diseases, tropical illnesses, and other emerging health challenges. The accurate diagnosis of conditions, ranging from infectious diseases to cancers, is essential for effective healthcare management. However, the availability of trained histopathologists in African regions is rather limited. There is a pressing need for investment in training programs for histopathologists to build local expertise.

By fostering the development of histopathology expertise in Africa, the healthcare system can better address the unique health challenges prevalent in the area. This includes the early detection of infectious diseases and tumors, understanding the epidemiology of specific conditions, and tailoring treatment strategies to the local context. In essence, investing in histopathology training is a crucial step toward enhancing healthcare capabilities in Africa, with potential benefits for both individual patients and the broader public health landscape.\n
You can download program schedule or time table <u><a href=${Histopathology} download="Histopathology-training-schedule"> Here</a></u>\n
<strong>Topics/Areas to be covered:</strong>

  Day-1:   Breast Pathology, including Utility of Predictive markers.

  Day-2:   Bone and Soft Tissue Pathology.

  Day-3:   Hematopoietic and Lymphoid Tissue Pathology.

  Day-4:   Gastrointestinal Tract & Liver Pathology.

  Day-5:   Miscellaneous Pathology (Male and Female Genital Pathology, and Thyroid Pathology).
  
  <u><a href="https://drive.google.com/drive/folders/1sxhnobKfg57d2gdnrKK8odtq3IUF39re?usp=drive_link" target="_blank">Click here to download the ppts and slides</a></u>
  
  <u><a href="https://www.youtube.com/@benadiruniv" target="_blank">Click here to watch the videos</a></u>

  <u><a href="https://drive.google.com/drive/folders/1zexfDMxgNWkdOY0RRlg9xc2ZYSUBbFWd?usp=drive_link" target="_blank">Click here to download other resources.</a></u>

In case you need to find these resources online <u><a href="https://link.springer.com/book/10.1007/978-981-15-1220-9" target="_blank">Click here</a></u>
  

<strong>Target Audience:</strong>

Pathologists, postgraduate pathology students, researchers, surgeons, gynecologists, orthopedic specialists, and other relevant fields.

<strong>Tentative List of Leading Experts/Resource Persons: </strong>

  1. Prof. Dr. Shahid Pervez, MBBS, DCP (London), PhD (Histopath, London), FCPS (Histopathology, PK), FRCPath (Histo, UK) – Team Leader (General Histopathology, Neoplastic Haematopathology, Predictive Histopathology, Cancer Registry), COMSTECH Distinguished Scholar, Professor & Consultant Histopathology, Departments of Pathology & Laboratory Medicine, Faculty of Health Sciences, Medical College, The Aga Khan University, Medical Centre, Karachi, Pakistan\n
  2. Dr. Nasiruddin, MBBS, FCPS (Histo, PK), FRCPath (Histo, UK) (General Histopathology, Bone and Soft Tissue, Head & Neck), The Aga Khan University, Karachi, Pakistan.\n
  3. Dr. Zeeshan Uddin, Assistant Professor and Consultant Histopathologist, The Aga Khan University, Karachi, Pakistan.

<strong>Registration Process:</strong>

1. For physical participation register at the following link: <u><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSeMSY0xYzx9ohaZChH3jsDQZIOrJ8rukJoHDbTnabX8aux7lw/viewform">Click Here</a></u>
(Physical Participation is open for participants from Somalia and Africa. The participants will need to arrange their own travel and local hospitality)

2. For virtual participation register at the following link: <u><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSdblQT4mn46-jmHyjhrNWFEJyS0ixreNc31WGmsWy6XfLUk_Q/viewform">Click here</a></u>

3. Read instructions in the application form carefully and submit a properly filled form.

4. Selected candidates will be notified through email; hence, applicants are required to check their emails regularly.

<strong>Technical Focal Person:</strong>

Prof. Dr. Shahid Pervez

M.B., B.S., D.C.P. (London), Ph.D. (Histopath. London),

FCPS (Histopath. Pakistan), FRCPath (UK)

COMSTECH Distinguished Scholar

Professor & Consultant Histopathology, Departments of Pathology & Laboratory Medicine,

Faculty of Health Sciences, Medical College, The Aga Khan University,

Medical Centre, Karachi, Pakistan.

<strong>Training Coordinators:</strong>

<strong>1. Muhammad Haseeb Ahmad</strong>
Program Officer
COMSTECH, Islamabad.
Email: Haseeb.ahmad@comstech.org

<strong>2. Prof. Dr. Mohamed Mohamud Ali “Fuje”</strong>
MBBS, MSc Clinical Tropical Medicine (London), DTM&H, DCP (London UK).
Dean, Benadir University Postgraduate school,
Mogadishu Somalia.
Email: mmfuje@gmail.com


In case you forgot to download the training schedule or time table, you can still download <u><a href=${Histopathology} download="Histopathology-training-schedual"> Here</a></u>
\n
To join the program click on the zoom link bellow:
<u><a href="https://zoom.us/j/96478691137?pwd=ekRScjduS0gvZUEyK0RtNC94eGN3dz09" target="_blank"> https://zoom.us/j/96478691137?pwd=ekRScjduS0gvZUEyK0RtNC94eGN3dz09</a></u>

If you interested in to get a certificate of attendant. <u><a href="https://forms.gle/LJKAinLe6DRkfQdh8" target="_blank">click here</a></u>
`,
    Time: "09:00AM",
    title:
      "OIC-COMSTECH Training Course in Histopathology: A Hybrid Learning Experience",
    EventDate: "2024, 02,30",
  },
  {
    id: 4,
    slug: "Somali-Engineering-and-Technology-Conference",
    detailDay: "31st of Jan - 4th of Feb, 2024",
    day: "27 - 28",
    month: "Dec",
    img: e2,
    img_header: e2,
    eventType: "Conference",
    venue: "Dr.Adaw Campus",
    desc: ``,
    Time: "08:00AM",
    title: "Somali Engineering and Technology Conference",
    EventDate: "2024, 12,27",
  },
  {
    id: 3,
    slug: "ACSOS2023",
    detailDay: "31st of Jan - 4th of Feb, 2024",
    day: "5 - 7",
    month: "Dec",
    img: e5,
    img_header: e5,
    eventType: "Conference",
    venue: "SAHAL TERMINAL",
    desc: ``,
    Time: "08:00AM",
    title: "ACSOS2023",
    EventDate: "2023, 12,05",
  },
  {
    id: 2,
    slug: "KULAN-DUCO",
    detailDay: "31st of Jan - 4th of Feb, 2024",
    day: "3",
    month: "Dec",
    img: e4,
    img_header: e4,
    eventType: "Mourning",
    venue: "BU Campus",
    desc: ``,
    Time: "09:00AM",
    title: "Kulan Duco",
    EventDate: "2023, 12,03",
  },
  {
    id: 1,
    slug: "Graduation-ceremony",
    detailDay: "31st of Jan - 4th of Feb, 2024",
    day: "16",
    month: "Nov",
    img: e1,
    img_header: e1,
    eventType: "Graduation ceremony",
    venue: "SAHAL TERMINAL",
    desc: `Dear esteemed students and parents,
    We are delighted to share the exciting news of the forthcoming graduation ceremony at Benadir University, where we will honour and celebrate the remarkable achievements of our talented graduating class of 2023. This momentous occasion marks a significant milestone in the lives of our students and the entire Benadir University community.
    This grand event promises to be an unforgettable celebration of knowledge, resilience, and growth. It will take place on Thursday, November 16, 2023, at Sahal Terminal, Mogadishu, Somalia. Our 17th graduation ceremony will serve as a testament to the dedication, perseverance, and hard work exhibited by our graduates throughout their academic journey.
    The graduation ceremony will mark the culmination of years of learning, discovery, and personal growth. It will be a time for reflection, gratitude, and an optimistic outlook towards the future. Faculty members, distinguished guests, parents, and friends will attend the event to celebrate and express their heartfelt congratulations to our graduates.
    As the university, we invite our graduating class of 2023 to attend the ceremony with their  friends and family. 
    In addition, we encourage our students to stay tuned for all the key information about the event (comprehensive schedule, special performances, keynote speakers, dress code, security measures, invitation cards, and event access) through our university official website and social media platforms.
    Finally, congratulations to the Class of 2023! We eagerly anticipate celebrating this milestone moment with you, your loved ones, and the entire Benadir University community.
    With great pride and excitement, 

    Rector’s office,

    Benadir University`,
    Time: "07:00AM",
    title: "Class of 2023 graduation",
    EventDate: "2023, 11,16",
  },
];
