import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaTh } from "react-icons/fa";
import { PiStudentThin } from "react-icons/pi";
import { HiOutlineUsers } from "react-icons/hi";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { CiHome } from "react-icons/ci";
import { IoIosPeople } from "react-icons/io";
import { RxActivityLog } from "react-icons/rx";

const Side = () => {
  const [itemCollapse, setItemCollapse] = useState(null);
  const [collapse, setCollapse] = useState(true);
  const handleOpen = (value) => {
    if (itemCollapse === value) {
      setCollapse(!collapse);
    } else {
      setCollapse(true);
      setItemCollapse(value);
    }
  };
  const location = useLocation();
  const items = [
    {
      id: 1,
      path: "/dashboard",
      icon: <CiHome />,
      title: "Dashboard",
      children: [],
      badge: "01",
    },
    {
      id: 2,
      path: "/staff-list",
      icon: <IoIosPeople />,
      title: "Staff",
      children: [
        {
          id: 2.1,
          path: "/staff-list",
          title: "Staff List",
          icon: <FaTh />,
          badge: "01",
        },
        {
          id: 2.2,
          path: "/create-staff",
          title: "Create staff",
          icon: <IoIosPeople />,
          badge: "01",
        },
        {
          id: 2.3,
          path: "/create-depts",
          title: "Department",
          icon: <IoIosPeople />,
          badge: "01",
        },
        {
          id: 2.4,
          path: "/employ-staff",
          title: "Assign Job",
          icon: <IoIosPeople />,
          badge: "01",
        },
        {
          id: 2.5,
          path: "/update-publication",
          title: "Publications",
          icon: <IoIosPeople />,
          badge: "01",
        },
      ],
      badge: "01",
    },
    {
      id: 4,
      path: "/myevents",
      icon: <HiOutlineUsers />,
      title: "Events",
      children: [],
      badge: "01",
    },
    {
      id: 6,
      path: "/mynews",
      icon: <FaTh />,
      title: "News",
      children: [],
      badge: "01",
    },
    {
      id: 7,
      path: "/myactivity",
      icon: <RxActivityLog />,
      title: "Activities",
      children: [],
      badge: "01",
    },
    {
      id: 8,
      path: "/listUsers",
      title: "Users",
      children: [
        {
          id: 1,
          path: "/listUsers",
          title: "Users List",
          icon: <IoIosPeople />,
          badge: "01",
        },
        {
          id: 2,
          path: "/register",
          title: "New user",
          icon: <IoIosPeople />,
          badge: "01",
        },
        
      ],
    },
    {
      id: 9,
      path: "/portal",
      icon: <PiStudentThin />,
      title: "Student Portal",
      children: [],
      badge: "",
    },
  ];

  return (
    <div className="md:w-[260px] md:min-w-[260px] xl:w-[260px] xl:min-w-[260px] top-0 h-full transition-all duration-300 border-r bg-slate-100 w-full">
      <ul className="md:px-5 xl:px-7 text-slate-500">
        <li className="font-semibold opacity-75 cursor-default tracking-widest whitespace-nowrap text-gray-500 pt-5 text-[12px] my-1 mx-2">
          Menu
        </li>
        {items.map((i, index) => (
          <li key={index}>
            <Link
              to={i.path}
              className={`flex justify-between items-center font-normal cursor-pointer text-[15px] hover:bg-blue-500/10 rounded-md p-2 sm:px-8 xs:px-10 my-1 ${
                location.pathname === i.path && "bg-blue-500/10 text-blue-500 font-semibold"
              }`}
              onClick={() => handleOpen(index)}
            >
              <div className="flex space-x-2">
                <span className="text-[20px]">{i.icon}</span>
                <span>
                  <p className="text-[12px] tracking-widest font-sem">{i.title}</p>
                </span>
              </div>
              <div>
                {i.children.length > 0 && (
                  <span>
                    {itemCollapse === index && collapse ? (
                      <MdKeyboardArrowDown />
                    ) : (
                      <MdKeyboardArrowRight />
                    )}
                  </span>
                )}
              </div>
            </Link>

            {i.children.length > 0 && collapse && itemCollapse === index && (
              <ul className="md:px-5 xl:px-7 sm:px-16 xs:px-16 text-slate-500">
                {i.children.map((c, index) => (
                  <li key={index}>
                    <Link
                      to={c.path}
                      className={`flex items-center space-x-2 font-normal cursor-pointer text-[15px] hover:bg-blue-500/10 rounded-md p-2 my-1 ${
                        location.pathname === c.path && "bg-blue-500/10 text-blue-500 font-semibold"
                      }`}
                    >
                      <span className="text-[20px]">{c.icon}</span>
                      <span>
                        <p className="text-[12px] tracking-widest font-sem">{c.title}</p>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Side;
