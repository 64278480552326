import React, { useState, useEffect } from "react";
import { csStaff } from "../../../data/staff_data";
import { AiOutlineClose, AiOutlineWarning } from "react-icons/ai";
import PageTitle from "../../general/pageTitle";
import { GrFormView } from "react-icons/gr";
import { LuRefreshCcw } from "react-icons/lu";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { getAllUsers, deleteUser,getUser } from "../../../services/portalServices";
import _ from "lodash";

function UsersPortalList() {
  const [userData, setUserData] = useState([]);
  const [togle, setTogle] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [currentStaff, setCurrentStaff] = useState();
  const [cofirmError, setCofirmError] = useState("");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fields = [
    { name: "Student-ID", value: "studentID" },
    { name: "Student Name", value: "studentName" },
  ];

  const handleDelete = async (s) => {
    setTogle(!togle);
    setCurrentStaff(s);
  };
  const handleDelateStaff = async (s) => {
    if (s) {
      if (confirm === s.studentID) {
        setCofirmError("");
        setConfirm("");
        const resp = await deleteUser(s.studentID);
        setTogle(!togle);
        await handleReferesh();
      } else {
        setCofirmError("Please confirm deletion!");
      }
    }
  };

  const showModel = () => {
    setConfirm("");
    setCofirmError("");
    setTogle(!togle);
  };

  async function fetchData() {
    setIsLoading(true);
    const response = await getAllUsers();
    setUserData(response.data);
    setIsLoading(false);

  }
  useEffect(() => {
    fetchData();
  }, []);

  const handleSelect = (e) => {
    setFilter(e.currentTarget.value);
  };
  const handleSearch = async() => {
    if (search) {
      // const result1 = _.find(userData, [filter, search.trim()]);
      const result = await getUser(search.trim());

      // console.log(result)
      if(result.data){
        setUserData([result.data]);
      }
      else{
        setUserData([{}])
      }
    }
  };

  const handleReferesh = async () => {
    const response = await getAllUsers();
    setUserData(response.data);
  };



  return (
    <>
      <div>
        <PageTitle title="Staff List" subTitle="Staff" />
        <div className="grid lg:grid-cols-1 gap-6 mt-8 mx-3">
          <div className="card bg-white overflow-hidden">
            <div className="card-header flex justify-between">
              <div className="flex justify-center items-center  px-4 ">
                <div className="flex flex-row items-center">
                  <select
                    name="filter"
                    value={filter}
                    onChange={(e) => handleSelect(e)}
                    className="form-select md:w-[150px] font-semibold"
                  >
                    {fields.map((f, i) => (
                      <option key={i} value={f.value}>
                        {f.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="form-input md:w-[400px] mx-3 text-[15px]"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleSearch}
                  type="button"
                  className="btn bg-primary text-white mx-2"
                >
                  Find
                </button>
              </div>
              <div>
                <Link
                  to="/create-user-portal"
                  className="btn bg-primary text-white"
                >
                  New User
                </Link>
                {/* @@include("bu-staff-fomrs.html") */}
              </div>
            </div>
            <div className="p-4">
              <div className="overflow-x-auto">
                <div className="min-w-full inline-block align-middle">
                  {
                    isLoading  ? 
                    (<div className="w-full  flex justify-center items-center py-20">
                    <div class="animate-spin inline-block w-20 h-20 border-[3px] border-current border-t-transparent text-primary rounded-full" role="status" aria-label="loading">
                    <span class="sr-only">Loading...</span>
                    </div>
                  </div>)
                  :
                  (<div className="border rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm text-gray-500"
                          >
                            Student ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm text-gray-500"
                          >
                            Student Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm text-gray-500"
                          >
                            Password
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 text-sm text-gray-500  text-center"
                          >
                            <div className="flex flex-row justify-between ">
                              Action
                              <p
                                className="p-2 cursor-pointer  bg-slate-200 rounded-md hover:bg-slate-400"
                                onClick={handleReferesh}
                              >
                                <LuRefreshCcw />
                              </p>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {userData && userData.length >0 && userData.map((u) => (
                            <tr key={u.id}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                {u.studentID}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                {u.studentName}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                {u.Password}{" "}
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium flex flex-row  justify-center space-x-2">
                                <Link
                                  to={`/edit-user-portal`}
                                  state={{ user: u }}
                                >
                                  <FaEdit
                                    size={20}
                                    className="cursor-pointer m-1 hover:text-primary"
                                  />
                                </Link>
                                <Link onClick={() => handleDelete(u)}>
                                  <MdDelete
                                    size={20}
                                    className="cursor-pointer m-1 hover:text-primary"
                                  />
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-30 ease-in duration-300 ${
          togle ? "" : "hidden"
        }`}
      >
        {/* <div className='bg-[#EDF4F4] xs:w-full  w-[300px] max:w-[500px]  overflow-auto pt-4 fixed '> */}
        <div className="flex flex-col justify-center ">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl">
            <div className="flex justify-between items-center py-2.5 px-4 border-b">
              <h3 className="font-bold text-gray-800">Delete Staff</h3>
              <button
                data-fc-dismiss
                type="button"
                className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                onClick={showModel}
              >
                <AiOutlineClose className="text-xl" />
              </button>
            </div>
            <div className="p-4 overflow-y-auto flex flex-row">
              <div className="mx-10">
                <AiOutlineWarning className="text-7xl text-danger" />
              </div>
              <div className="mx-7">
                {currentStaff && (
                  <div>
                    <p className="mt-1 text-gray-800">
                      Do you want to delete this user:
                      <br />
                      <span className="font-semibold">
                        {currentStaff.studentID}{" "}
                      </span>
                    </p>
                    <div className="w-full  my-3">
                      <input
                        required
                        placeholder="write the student-ID"
                        name="titleImageCaption"
                        onChange={(e) => setConfirm(e.target.value)}
                        value={confirm}
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      />
                      <div className="mx-4 my-2">{cofirmError}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t">
              <button
                data-fc-dismiss
                type="button"
                className="btn bg-secondary text-white"
                onClick={showModel}
              >
                No
              </button>
              <button
                data-fc-dismiss
                type="button"
                className="btn bg-warning text-white"
                onClick={() => handleDelateStaff(currentStaff)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsersPortalList;
