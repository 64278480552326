import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoTime, IoLocationSharp } from "react-icons/io5";
import { up,all } from "../../data/ourpartiners";
import _ from "lodash";
import { Link } from "react-router-dom";

function OurParnters() {

  return (
    <div className="w-full my-20">
      <div className=" flex flex-col my-2 mt-10  mx-4 md:mx-10 xl:mx-10 mb-10">
        <div className="flex flex-row justify-center">
          <p className="text-[30px] font-semibold  sm:text-[40px] uppercase font-inriaserif">
            Our Partners
          </p>
        </div>
        <div className="flex flex-row justify-center">
        <div className="bg-slate-200 h-[2px] md:w-[100px] xl:w-[100px] sm:w-full xs:w-full"></div>
        </div>

        <div className="flex flex-row justify-center items-center ">
         
        <Carousel
          className="w-full my-5  "
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={4000}
          centerMode={false}
          containerClass=""
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass="flex justify-center"
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          partialVisbile={false}
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 768,
              },
              items: 4,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 639,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 767,
                min: 640,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind
          rewindWithAnimation={true}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {all.map((e, i) => (
            <a
              href={e.link} key={e.id} target="_blank"
              className="flex flex-row w-fit  mx-3 px-5 my-5 "
            >
             <img src={e.logo} alt="" className="w-[150px] h-[150px]" />
            </a>
          ))}
        </Carousel>
      </div>
      </div>
    </div>
  );
}

export default OurParnters;
