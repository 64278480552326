import React, { useState } from 'react'
import _ from "lodash";
import shollarship from "../../../assets/images/profle/scholarship/1.jpg"
import ReactPlayer from "react-player"


const Scholarship = () => {

  const faculty = [
    {id: 1, name:"Faculty of Agriculture", tiution: "50%"},
    {id: 2, name:"Faculty of Marine science and nautical studies", tiution: "50%"},
    {id: 3, name:"Faculty of Geology and environmental science", tiution: "50%"},
    {id: 4, name:"Faculty of Veterinary", tiution: "50%"},
    {id: 5, name:"Faculty of Sharia Law", tiution: "50%"},
  ]
  return (
    <div className='flex flex-col md:mx-10 xl:mx-10 mx-5 text-black  place-items-center '>
      <div className='flex flex-col  md:w-[80%] xl:w-[80%]'>

      
        <p className='text-2xl sm:text-xl xs:text-xl text-black font-montserrat font-semibold'>Exploring Scholarship Opportunities at Benadir University</p>
        <div className='my-10'>
          <img src={shollarship} alt="schollarship" className='' />
        </div>
        <div className='space-y-3  my-3'>
          <p className='text-2xl  sm:text-xl xs:text-xl font-semibold font-montserrat'>Introduction</p>
          <p className='font-base text-base leading-7'>Welcome to Benadir University, where academic excellence meets opportunity. At Benadir University, we believe in nurturing talent, fostering innovation, and empowering aspiring scholars to reach their full potential. As part of our commitment to accessibility and inclusivity, we proudly offer a range of scholarship opportunities aimed at supporting students in their pursuit of higher education.</p>
        </div>
        <div className='space-y-3 my-3'>
          <p className='text-2xl sm:text-xl xs:text-xl font-semibold font-montserrat'>Why Benadir University?</p>
          <p className='font-base text-base leading-7'>Established with a vision to provide quality education in Somalia and beyond, Benadir University has emerged as a beacon of learning and academic excellence in the region. With a diverse community of students, dedicated faculty members, and state-of-the-art facilities, we provide a vibrant and supportive environment for intellectual growth and personal development.</p>
        </div>
        <div className='space-y-3 my-3'>
          <p className='text-2xl  sm:text-xl xs:text-xl font-semibold font-montserrat'>Our Scholarship Opportunities</p>
          <p className='font-base text-base leading-7'>At Benadir University, we understand that financial constraints should not hinder anyone's educational journey. That's why we offer various scholarship programs designed to recognize merit, alleviate financial burdens, and promote access to education for deserving students. Whether you're an incoming freshman, a transfer student, or pursuing postgraduate studies, there may be a scholarship opportunity tailored to your needs and aspirations.</p>
          <ol className='list-decimal md:mx-10 xl:mx-10 font-base text-base leading-7 space-y-3'>
            <li className='font-semibold'>Merit-Based Scholarships:</li>
            <p> We reward academic excellence by offering merit-based scholarships to students graduated from <strong>Hirabe high school</strong>, schools owned by the university. These scholarships recognize the achievements of top-performing student and provide him with the financial support needed to excel in his studies.</p>
            <li className='font-semibold'>Specialized Scholarships:</li>
            <p>In addition to general scholarships, we offer specialized scholarship programs for students pursuing studies in specific fields or disciplines.</p>
            <ul className='list-disc mx-5'>
              <li className='font-semibold my-3'>Faculty of Education Scholarships</li>
              <p className=''>
              This scholarship is designed for undergraduate students pursuing degrees in education or related fields. It aims to support individuals who are committed to making a difference in the field of education through teaching, research, or advocacy. Recipients of this scholarship demonstrate a strong passion for education and exhibit leadership potential in the classroom and beyond. <br/> <br/>
              The schollarship motivated and recommended by the Government of somalia to provide scholarships for faculty of education students. This pioneering program aims to cultivate a new generation of highly motivated and skilled educators who will play pivotal roles in shaping the future of education. Moreover, these scholarships are not merely financial support but also a testament to the commitment of the university to invest in the future of education.<br/><br/>
              Recipients of this opportunity will be required to pay <strong>a nominal fee of 100 USD</strong> and sign <strong>an agreement</strong> committing to remain within the field of education for a specified period(until the completion of their program). This agreement serves to ensure the continued dedication of scholarship recipients to the advancement of learning and teaching. By pledging to stay within the education sector, recipients demonstrate their commitment to making a lasting impact in their chosen field, fostering a culture of excellence and continuity within the educational community. <br/> <br/>
              The selection process for this opportunity relies on the <strong> outcomes of the admission examinations.</strong> These exams serve as a crucial assessment tool to evaluate applicants' academic aptitude, proficiency, and potential to excel in their chosen field of study. By carefully analyzing the results of these exams, the selection committee identifies candidates who demonstrate the necessary skills, knowledge, and readiness to benefit from the educational program offered. Through a transparent and merit-based approach, the selection process ensures that deserving individuals are chosen to receive this opportunity, fostering a diverse and talented student body committed to academic excellence.
              </p>
              </ul>
              <li className='font-semibold'>Partialy funded Scholarships:</li>
            <p>
            Benadir University is delighted to announce a partially funded scholarship opportunity tailored for students pursuing the fields mentioned in the table bellow. Under this initiative, recipients will only be responsible for <strong>paying 50% of the tuition fees</strong>, significantly easing the financial burden associated with higher education. This support aims to empower deserving individuals to pursue their academic aspirations with confidence and focus. By investing in these talented students, Benadir University is committed to fostering innovation, leadership, and positive change within their specified field. This scholarship reflects our dedication to providing accessible and inclusive education while enabling students to realize their full potential and make impactful contributions to their communities and beyond.
            </p>
            <div className='flex w-full justify-center items-center '>
            <table className="table-auto border-collapse border border-[#e8d5d6] my-10 ">
            <thead className='bg-[#0863aa] text-xl uppercase text-white'>
              <tr className='py-3'>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>Faculty</th>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>Scholarship</th>
              </tr>
            </thead>
            <tbody>
              {
                faculty.map( f => (
                <tr key={f.id} className='font-semibold'>
                  <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>{f.name}</td>
                  <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>{f.tiution}</td>
                </tr>
                ))
              }
            </tbody>
          </table>
          </div>
            <li className='font-semibold'>Statewide Scholarships: Benadir University's Commitment to Regional Education</li>
            <p>
            Benadir University is proud to announce a scholarship program extended to states, providing invaluable educational opportunities to deserving individuals across regions. This scholarship initiative aims to empower students from various states by offering financial assistance to pursue their academic aspirations at Benadir University. Through this program, the university seeks to promote inclusivity, diversity, and accessibility to higher education, fostering a brighter future for communities across different states. Recipients of this scholarship will benefit from a supportive learning environment, world-class faculty, and state-of-the-art facilities, enabling them to excel in their chosen fields and make meaningful contributions to their respective communities and beyond.
            </p>
          </ol>
        </div>
        <div className='space-y-3 my-5'>
          <p className='text-2xl font-semibold font-montserrat'>The Journey of a Scholarship Recipient</p>
          <div className='grid grid-cols-2 gap-5 sm:grid-cols-1 xs:grid-cols-1'>
          <p className='font-base text-base leading-7 text-justify'>
          Meet <strong>Ali Aden</strong>, a remarkable individual whose journey exemplifies the profound impact of scholarships offered by the Faculty of Education at Benadir University. Hailing from a humble background, Ali's dream of pursuing higher education in education seemed beyond reach until he was awarded a scholarship from the Faculty of Education. With this invaluable support, Ali embarked on his educational journey with determination and zeal. Throughout his studies, Ali demonstrated exceptional academic prowess and a deep commitment to the field of education. He actively engaged in teaching practicums, community outreach projects, and research endeavors, showcasing his passion for fostering positive change in the educational landscape. Upon graduation, Ali not only earned top honors but also emerged as a beacon of inspiration for aspiring educators. Today, he stands as a respected senior lecturer, mentor, and advocate for quality education, leaving an indelible mark on the lives of countless students. Ali's success story serves as a testament to the transformative power of scholarships in unlocking the potential of bright and deserving individuals like himself.
          </p>
          <ReactPlayer
                    url='https://www.youtube.com/watch?v=ClX_810xKX8'
                    width='100%'
                    height='100%'
                />
        </div>
        </div>
        

        </div>
</div>
)
}

export default Scholarship