import React from "react";
import { Link, useParams } from "react-router-dom";
import {foundation} from '../../../data/programs'




const ProgramDetails = () => {

  let msg = foundation.msg
  return (
         <div className="text-black text-justify w-full  mt-10  flex  justify-center items-center  " >
          <div className="flex flex-col md:w-[70%] xl:w-[70%] w-[90%]">

          <div className="flex flex-row sm:flex-col xs:flex-col  ">
              {msg && <div className="w-fit mx-3">
                      <img src={msg.img} alt="avatar" className='h-[250px] w-[250px]  rounded-2xl'/>
              </div>}
            <div className="w-full mx-5 flex flex-col justify-end items-start ">
            {msg && <p className="pt-5 text-2xl xs:text-xl font-semibold">
              {msg.name}</p>}
              {msg && <p className=" text-xl xs:text-base font-semibold">
                  {msg.title} 
              </p>}
              {msg && <p className=" text-xl xs:text-base font-semibold">
                  {msg.phone} 
              </p>}
              {msg && <p className=" text-xl xs:text-base font-semibold">
                  {msg.email} 
              </p>}
            </div>
          </div>
          <div className="text-justify text-black my-8 ">
            {msg && msg.body.map( (m,i )=> (
              <p key={i} className="py-5 text-xl xs:text-base sm:text-base whitespace-pre-line ">
                 {m}
              </p>
            ))
            }
            </div>
            </div>
      </div>

  );
};

export default ProgramDetails;