import React from 'react'



const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';
const cover =  backet + '/postgrad/froms.jpg'

const BUBrochure = backet + '/brochures/Benadir-University-Brochure.pdf'
const Marine_science = backet + '/brochures/Faculty-of-marine-brochure.pdf'
const Medicine = backet + '/brochures/Faculty-of-medicine-brochure.pdf'
const Dental = backet + '/brochures/Faculty-of-dental-brochures.pdf'
const Fema = backet + '/brochures/Faculty-of-economics-brochure.pdf'
const Education = backet + '/brochures/Faculty-of-education-brochure.pdf'
const Hs = backet + '/brochures/Faculty-of-healthScience-brochure.pdf'


const  Brochure = (props) => {
  return (
    <div className=''>
        <div className='relative w-full h-[400px] mx-h-[400px]'>
        <p className=' text-6xl xs:text-4xl  font-bold text-[#0843aafe] text-center uppercase absolute w-full top-[73%]'>
            Brochures
          </p>
        </div>
        <div className='w-full h-[1px] bg-[#0863aa]'></div>
        <div className='flex justify-center items-start md:h-[600px] xl:h-[600px]'>
        <table className="table-auto border-collapse border border-[#e8d5d6] my-10 ">
            <thead className='bg-[#0863aa] text-xl uppercase text-white'>
              <tr className='py-3'>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>Brochure</th>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>Last Revision Date</th>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>Download Link</th>
              </tr>
            </thead>
            <tbody>
              <tr className='font-semibold'>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>Benadir University Brochure</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>September 30, 2023</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>
                <a href={BUBrochure} download="Benadir-University-Brochure" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Download</span>
                </a>
                </td>
              </tr>
              <tr className='font-semibold'>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>Faculty of Medicine</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>January 14, 2024</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>
                <a href={Medicine} download="Faculty-of-medicine-brochure" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Download</span>
                </a>
                </td>
              </tr>
              <tr className='font-semibold'>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>Faculty of Marine Science</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>January 14, 2024</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>
                <a href={Marine_science} download="Faculty-of-marine-brochure" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Download</span>
                </a>
                </td>
              </tr>
              <tr className='font-semibold'>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>Faculty of Dental</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>January 14, 2024</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>
                <a href={Dental} download="Faculty-of-dental-brochure" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Download</span>
                </a>
                </td>
              </tr>
              <tr className='font-semibold'>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>Faculty of Economics</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>January 14, 2024</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>
                <a href={Fema} download="Faculty-of-economics-brochure" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Download</span>
                </a>
                </td>
              </tr>
              <tr className='font-semibold'>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>Faculty of Education</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>January 14, 2024</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>
                <a href={Education} download="Faculty-of-education-brochure" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Download</span>
                </a>
                </td>
              </tr>
              <tr className='font-semibold'>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>Faculty of Health Science</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>January 14, 2024</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>
                <a href={Hs} download="Faculty-of-healthScience-brochure" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Download</span>
                </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default Brochure;