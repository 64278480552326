import React, { useState } from 'react'
import impact from '../../../assets/images/profle/impacts/1.jpg'
import res1 from '../../../assets/images/profle/impacts/2.jpg'
import res2 from '../../../assets/images/profle/impacts/5.jpg'
import ce1 from '../../../assets/images/profle/impacts/3.jpg'
import ce2 from '../../../assets/images/profle/impacts/11.jpg'
import ci1 from '../../../assets/images/profle/impacts/6.jpg'
import ci2 from '../../../assets/images/profle/impacts/9.jpg'
import edu1 from '../../../assets/images/profle/impacts/4.jpg'
import edu2 from '../../../assets/images/profle/impacts/7.jpg'


import _ from "lodash";

const OurImpact = () => {

    const Impact = [
        {
            id: 1, 
            title: "Research and Innovation", 
            Desc: `The university likely engages in research activities aimed at addressing local health challenges and finding innovative solutions. Research conducted at Benadir University may lead to advancements in healthcare practices, disease prevention, and treatment strategies, benefiting both the local community and the broader medical field.

            Benadir University's research activities provide opportunities for capacity building and training for students, faculty, and healthcare professionals. Engaging in research helps develop critical thinking skills, research methodologies, and scientific inquiry among students and faculty members. Additionally, research collaborations and partnerships with local and international institutions facilitate knowledge exchange and skills transfer, further enhancing research capacity within the university and the broader healthcare community.
            
            Innovation is woven into the fabric of Benadir University, driving progress across various sectors. Whether it's developing new medical technologies to improve patient care, designing sustainable solutions to address environmental challenges, or harnessing digital innovations to enhance education, the university's researchers are at the forefront of innovation.
            
            Benadir University's commitment to innovation extends beyond the confines of the campus, with initiatives aimed at fostering entrepreneurship and supporting startups. The university provides aspiring entrepreneurs with the resources, mentorship, and networking opportunities needed to turn their ideas into reality, contributing to economic growth and job creation in the region.`, 
            img1: res1,
            img2: res2,
        },
        {
            id: 2, 
            title: "Educational Impact", 
            Desc: `Benadir University plays a crucial role in educating the next generation of healthcare professionals, including doctors, nurses, pharmacists, and other allied health workers. By providing quality education and training, the university contributes to improving healthcare services in Somalia, ultimately enhancing the well-being of the population.

            Benadir University can also offer programs and workshops designed to enhance digital literacy and technical skills within the community. These initiatives can range from basic computer literacy courses to more advanced training in areas such as coding, software development, and information technology. By equipping community members with relevant tech skills, the university empowers them to participate more effectively in the digital economy and leverage technology for personal and professional growth.

            Benadir University advocates for policies and initiatives aimed at promoting digital inclusion and expanding access to technology across the community, especially in underserved areas. 
            
            BU plays a crucial role in developing human capital by providing education and training to students pursuing careers in healthcare, technology, business, and other fields. By producing skilled professionals, the university enhances the workforce's productivity and employability, thereby contributing to economic growth and competitiveness.`, 
            img1: edu1,
            img2: edu2,
        },
        {
            id: 3, 
            title: "Community Engagement",
            Desc: `Benadir University's community engagement efforts likely extend beyond providing healthcare services to actively empowering communities to take charge of their health and well-being. Community outreach programs, health education initiatives, and awareness campaigns organized by the university aim to promote healthy behaviors, prevent diseases, and improve health literacy among the population. By empowering communities with knowledge and resources, the university contributes to building resilient and healthy communities.
            Benadir University empowers community members to actively participate in decision-making processes related to their health and well-being. Through community forums, advisory boards, and participatory research approaches, the university ensures that community voices are heard, priorities are addressed, and interventions are co-designed with input from those directly affected.
            BU collaborates with local organizations, government agencies, NGOs, and community leaders to address community health needs and implement health interventions effectively. These partnerships leverage resources, expertise, and networks to maximize the impact of community engagement initiatives and ensure they are culturally appropriate and sustainable`, 
            img1: ce1,
            img2: ce2,
        },
        {
            id: 4, 
            title: "Cultural Impact", 
            Desc: `As an educational institution, Benadir University plays a role in preserving and promoting Somali culture and identity. It provides a platform for the exchange of ideas, knowledge, and cultural practices among students, faculty, and staff, fostering a sense of belonging and pride within the community.

            Benadir University's cultural impacts extend beyond the classroom and healthcare settings, influencing various aspects of community life and contributing to the richness and diversity of Somali culture. Through its educational, healthcare, and community engagement initiatives, the university fosters cultural pride, promotes intercultural understanding, and celebrates the vibrant cultural heritage of Somalia.
            
            As Benadir University continues to grow and evolve, its cultural impact will undoubtedly continue to flourish. With a steadfast commitment to celebrating diversity, preserving heritage, and engaging with the community, the university is poised to remain a beacon of cultural enrichment and unity in Somalia and beyond. Through its dedication to education, cultural engagement, and community empowerment, Benadir University is not only shaping the minds of future leaders but also shaping the cultural landscape of the nation for generations to come.`, 
            img1: ci1,
            img2: ci2,
        },
    ]
    

  return (
    <div className='flex flex-row justify-center items-center md:px-10 xl:px-10 mx-5'>
       
<div className='flex md:flex-col xl:flex-col sm:flex-col xs:flex-col justify-center pt-3   '>
    <div className='flex flex-col justify-center items-start ml-20  '>
        <div className='w-fit flex flex-col '>

        <p className='text-5xl sm:text-2xl xs:text-2xl text-[#0863aa] font-bold'>Our Impact</p>
        <p className='text-[10px]  place-self-end my-2 italic font-thin font-inriaserif'>Cultivating human tallensts</p>
        </div>
    </div>
    <div className='my-5 grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-3 justify-around'>
        <div className='col-span-1 px-2 place-content-center '>
        <p className='text-black text-xl text-justify whitespace-pre-line'>
        The Benadir University (BU), guided by its ethos of continuous improvement, has strived to provide the best possible value for money to its Member Countries, while constantly improving the quality and breadth of its academic offerings, facilities, educational and pastoral support, community projects, and regionally-relevant research. It has come a long way since its humble beginnings in 2002.<br/>
        Benadir University's impacts are multidimensional, encompassing education, healthcare, research, community engagement, economic development, and social empowerment. As a leading institution of higher learning and healthcare provider in Somalia, the university plays a pivotal role in shaping the future of the country and improving the quality of life for its citizens.
        </p>
        </div>
        <div className='col-span-1'>
        <img src={impact} alt="impact" className='h-[300px]'/>
        </div>
    </div>
    {
        Impact.map( im => (
            <div key={im.id}>
            <p  className='text-black text-2xl font-semibold my-2'>{im.title}</p>
            <hr />
            <div  className='my-5 grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-3 justify-around'>
                <div className='col-span-1 px-2 place-content-center '>
                <p className='text-black text-xl text-justify whitespace-pre-line'>
                {im.Desc}
                </p>
                </div>
                <div className='col-span-1'>
                <img src={im.img1} alt="impact" className='h-[300px] my-3'/>
                <img src={im.img2} alt="impact" className='h-[300px] my-3'/>
                </div>
            </div>
            </div>
        ))
    }

</div>
</div>
)
}

export default OurImpact