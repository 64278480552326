import React from 'react'

import { IoTime,IoLocationSharp } from 'react-icons/io5';
import {events} from '../../data/events'
import _ from 'lodash'


function Events() {

  const SortedEvents = _.orderBy(events, ['id'], ['asc'])


  return (
    <div className=''>
    
    <div className=' flex flex-col my-2 mt-10  mx-4 md:mx-10 xl:mx-10 mb-5'>
    <div className='flex flex-row'>
        <p className='text-[30px] font-semibold font-sans sm:text-[40px] '>Events</p>
    </div>
    <div className='bg-black h-[2px] md:w-[200px] xl:w-[300px] sm:w-full xs:w-full'></div>
       
    <hr />
    <div className=' grid md:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 my-2 justify-center'>
    {
      SortedEvents.map( (e,i) => (
    <div key={i} className="flex flex-col  justify-between my-3 mx-3 hover:bg-slate-300 ">
      <p className='text-[#0863AA] font-bold text-2xl xs:text-xl'>
        {e.day}
      </p>
      <p className='bg-[#0863AA] text-white font-bold text-2xl xs:text-xl w-fit px-3 '>
        {e.month}
      </p>
      <p>
        <img src={e.img} alt="current month" className='w-full'/>
      </p>
      <p className='text-[#0863AA] font-bold text-2xl xs:text-xl'>
        {e.eventType}
      </p>
      <p className='line-clamp-1'>{e.title}</p>
      <div className='flex flex-row justify-between md:mt-8 xl:mt-8 sm:mt-6 xs:mt-2 text-[#0863AA] font-bold text-xl xs:text-base'>
          <div className='flex flow-row space-x-1'>
              <IoLocationSharp />
              <p className=' '>
                {e.venue}
              </p>

          </div>
        <div className='flex flow-row space-x-1'>
            <IoTime />
              <p>
                {e.Time}
              </p>

        </div>

  
   
      </div>
    </div>
      ))
    }

    </div>
    </div>
    </div>
  )
}

export default Events