import React, {useEffect, useState} from 'react'
import { isUserExist } from '../../services/userServices'
// import {GrView} from 'react-icons/gr'
import Box from "./box"
import Stats from "./stats"
import { Link } from "react-router-dom";
import PageTitle from '../general/pageTitle';


const Dashboard = () => {
 
  const [numberOfUsers, setNumberOfUsers] = useState('');



  useEffect( () => {
    async function fetchData() {
        const response = await isUserExist();
        setNumberOfUsers(response.data.length)
      }
      fetchData();
  },[])

  return (
    <div className='mx-2'>
    
      <PageTitle title="Dashboard" subTitle="Menu"/>
      <Box />
      <Stats />
    {/* <div className='mx-3 flex flex-row justify-center items-center w-full '>
       <div className='bg-white shadow-md flex justify-center relative rounded-b-md'>
        <div className='bg-[#0802F3] h-[6px] w-full absolute'></div>
        <div className='flex justify-center flex-col items-center p-6 w-full text-[#0802F3]'>
          <p className='font-semibold text-[30px]'>{numberOfUsers}</p>
          <Link className='text-[18px] hover:underline'>Total Users</Link>
        </div>
        </div>
      </div> */}
      </div>

  )
}

export default Dashboard