import React from "react";



const FacultyAlumni = props => {

 
  return (<div  className="text-black text-justify mt-10 xl:mx-[300px] md:mx-[100px] ">
         
      </div>

  );
};

export default FacultyAlumni;