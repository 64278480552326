import React from 'react'
import Myfile from "./CertificateRequestForm.pdf"
import IntershipForm from "./Internship-Form.pdf"
import transfereRequest from "./transfere-request-form.pdf"


const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';
const cover =  backet + '/postgrad/froms.jpg'


const FormsList = (props) => {
  const forms = [
    {id:1, desc: "Certificate Request Form", date:"September 30, 2023", frm: Myfile, fileName:"Certificate-Request-Form"},
    {id:2, desc: "Letter of Internship Completion", date:"January 14, 2024", frm: IntershipForm,fileName:"Intership-form"},
    {id:3, desc: "Transfere Request From", date:"Augaugust 17, 2024", frm: transfereRequest,fileName:"Transfere-Request-From"},
  ]
  return (
    // panel
    <div className=''>
        <div className='relative w-full h-[400px] mx-h-[400px]'>
          <img src={cover} className='w-full object-cover h-[400px] mx-h-[400px]' alt="cover"/>
          <div className='absolute top-[70%]  w-full h-[15%] bg-white opacity-50 flex justify-center items-center'> </div>
        <p className=' text-6xl xs:text-4xl  font-bold text-[#0843aafe] text-center uppercase absolute w-full top-[73%]'>
            Forms
          </p>
        </div>
        <div className='w-full h-[1px] bg-[#0863aa]'></div>
        <div className='flex justify-center items-start md:h-[600px] xl:h-[600px]'>
        <table className="table-auto border-collapse border border-[#e8d5d6] my-10 ">
            <thead className='bg-[#0863aa] text-xl uppercase text-white'>
              <tr className='py-3'>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>Form Name</th>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>Last Revision Date</th>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>Download Link</th>
              </tr>
            </thead>
            <tbody>
              {
                forms.map( f => (
              <tr className='font-semibold'>
                <td className='border border-[#e8d5d6] w-[300px] py-2 xl:px-5 md:px-5 px-2 text-left'>{f.desc}</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>{f.date}</td>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>
                <a href={f.frm} download={f.fileName} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  <span>Download</span>
                </a>
                </td>
              </tr>
                ))
              }
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default FormsList;