import React, { useState, useEffect } from "react";
import api from "../../config.json";
import parse from "html-react-parser";
import { FacebookShareButton, FacebookIcon } from "react-share";
import { Link, useParams, useLocation } from "react-router-dom";
import { MdWatchLater } from "react-icons/md";
import NewsSlider from "./newsslider";
import _ from "lodash";
import { pubs } from "../../data/pubs";
import { getAllNews } from "../../services/newsServices";

const NewsDetails = () => {
  // const backet =  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";
  // const img1 = backet + "/news/1.jpg";
  // const img2 = backet + "/news/2.jpg"
  // const img3 = backet + "/news/3.jpg";
  // const { slug } = useParams();
  const [newsData, setNewsData] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const [myNews, setMyNews] = useState([]);
  const { state } = useLocation();
  const prm = useParams();
  let currentNews;
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  useEffect(() => {
    async function fetchData() {
      const response = await getAllNews();
      const news = response.data;
      const sortedNews = _.orderBy(news, "createdAt", "desc");
      //   setMyNews(sortedNews)

      if (currentNews) {
        const lts = _.filter(sortedNews, ["tag", currentNews.tag]);
        if (lts.length > 4) {
          setNewsData(_.slice(lts, 0, 4));
        } else {
          setNewsData(lts);
        }
      }
      setMyNews(news);
      setAllNews(_.slice(sortedNews, 0, 10));
    }

    fetchData();
  }, [myNews]);

  if (state) {
    currentNews = state.news;
  } else if (prm) {
    const slug = prm.slug;
    currentNews = _.find(myNews, ["slug", slug]);
  }

  const pub = _.last(pubs);
  const research = _.last(pub.item);

  const url = api.url;
  return (
    currentNews && (
      <div>
        <div className="grid grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 justify-center mx-10 md:py-48 xl:py-48 sm:py-32 xs:py-24 gap-6 relative">
          {/* left */}
          <div className="sm:hidden xs:hidden">
            <div className="bg-[#EDF4F4] rounded-md p-6">
              <h2 className="font-bold text-[23px]">Find on the page</h2>
              <a href={research.doi} target="_blank" rel="noopener noreferrer">
                <p className="mt-1">{research.title}</p>
              </a>

              <hr className="bg-white border-white mt-1" />
              <p className="mt-1">Publication</p>
            </div>
          </div>

          {/* middle */}
          <div className="md:col-span-2 xl:col-span-2 xs:col-span-1 sm:col-span-1">
            <div className="flex items-center">
              <MdWatchLater className="xs:hidden mr-3" />
              <p className="sm:text-[14px] xs:text-[14px]">
                Published on{" "}
                {new Date(currentNews.createdAt).toLocaleDateString(
                  "en-US",
                  options
                )}{" "}
                | Updated on{" "}
                {new Date(currentNews.updatedAt).toLocaleDateString(
                  "en-US",
                  options
                )}{" "}
              </p>
            </div>

            <div className="my-2">
              <p className="font-semibold md:text-[30px] xl:text-[30px] tex-[22px] mb-6">
                {currentNews.title}
              </p>
              <img
                src={url + currentNews.titleImageUrl}
                className="rounded-md object-cover"
                alt="bandhig"
              />
              <p className="text-[12px]">{currentNews.titleImageCaption}</p>
              <div>
                <p className="my-4 whitespace-pre-line">
                  {parse(currentNews.newsContent)}
                </p>
              </div>
              <div className="flex justify-center items-center w-full ">
                <NewsSlider list={currentNews.imageList} />
              </div>
            </div>

            {/* author */}
            <div className="border-l-0 border-r-0 border-b-0 border py-6 mt-6">
              Updated by:
              {currentNews.updatedby}
            </div>

            {/* Share */}

            <div className="flex gap-4 py-6 border-l-0 border-r-0 border items-center">
              <p>Share</p>
              <FacebookShareButton
                url={`https://bu.edu.so/news/${currentNews.slug}`}
                quote={currentNews.title}
                hashtag={currentNews.tag}
                description={currentNews.newsContent}
                className=""
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <br />
              {/* <TwitterShareButton
                        title={"test"}
                        url={"https://peing.net/ja/"}
                        hashtags={["hashtag1", "hashtag2"]}
                    >
                        <TwitterIcon size={32} round />
                        Twitterでもshare
                    </TwitterShareButton> */}
            </div>

            {/* Related articles */}
            <div className="my-6">
              <div className="my-2">
                <p className="font-semibold text-[23px]">Related articles</p>
              </div>
              <div className="grid xl:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1  md:gap-2 xl:gap-2 gap-8">
                {/* one */}
                {newsData.map((l) => (
                  <div>
                    <img src={url + l.titleImageUrl} alt="bandhig" />
                    <p className="text-[11px]">{l.createdAt}</p>
                    <Link to={`/news/${l.slug}`} state={{ news: l }}>
                      <p className="font-meduim text-[20px]">{l.title}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Righ */}
          <div className="xs:hidden sm:hidden">
            <div className="bg-white border border-slate-300 p-6 rounded-md flex flex-col gap-4">
              <p className="font-bold text-[23px]">BU News</p>
              <div className="flex flex-col gap-4">
                {allNews.map((a) => (
                  <div className="flex flex-col gap-1">
                    <Link
                      to={`/news/${a.slug}`}
                      state={{ news: a }}
                      className="line-clamp-1 text-primary text-sm"
                    >
                      {a.title}
                    </Link>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default NewsDetails;
