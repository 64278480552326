import React from "react";
import foundation from '../../../assets/images/foundation.jpg'
import {   Link } from "react-router-dom";

const FacultyList =() => {
  const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

  const list = [
    {id: 0, program: "Foundation", img: foundation, link: "/academics/foundation/about"},
    {id: 1, program: "Undergrdaute", img: backet + '/undergrad/undergrad.jpg', link: "/academics/undergraduate"},
    {id: 2, program: "Postgraduate", img: backet + '/page-links/postgrad.jpg', link: "/academics/postgraduate"}
  ]
   
  return (
     
       <div className=" grid  md:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-10 justify-center items-center  w-fit   py-5 px-3 " >
       {
         list.map( m => (
          <Link key={m.id} to={`${m.link}`}>
           <div  className="flex flex-col justify-center items-center  w-[250px]  border-2 border-solid border-gray-300 " >
             <img src={m.img} className="h-[152px]  w-full " alt="faculty"/>
             <p className="my-2 py-3 text-[#0863AA] text-xl text-center font-bold">{m.program}</p>
          
           <p></p>
       </div>
       </Link>
         ))
       }
     </div>

  );
};

export default FacultyList;