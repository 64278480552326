import React, { useState,useEffect} from 'react'
// import Swipe from "react-easy-swipe";
import Swipe from "react-easy-swipe"
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import Showcase from './Showcase';


const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

function Carousels1() {
    const slides = [
      {url: backet + '/carousels/1.jpg', title: "Banadir University", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Apply now", link: "#"},
      {url: backet + '/carousels/2.jpg',title: "Campus Tour", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Register here", link: "#"},
      {url: backet + '/carousels/3.jpg',title: "Banadir University", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Apply now", link: "#"},
      {url: backet + '/carousels/4.jpg',title: "Banadir University", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Apply now", link: "#"},
      {url: backet + '/carousels/5.jpg',title: "Banadir University", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Apply now", link: "#"},
      {url: backet + '/carousels/6.jpg',title: "Banadir University", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Apply now", link: "#"},
      {url: backet + '/carousels/7.jpg',title: "Banadir University", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Apply now", link: "#"},
      {url: backet + '/carousels/8.jpg',title: "Banadir University", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Apply now", link: "#"},
      {url: backet + '/carousels/9.jpg',title: "Banadir University", description: "In Somalia, BU has the largest range of medical educations and accounts for the single largest share of medical academic research.", command:"Apply now", link: "#"}
    ]

    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect( ()=> {
      const interval = setInterval(() => {
        const isLastSlide = currentIndex === slides.length -1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)
      }, 4000);
      return () => clearInterval(interval);
    })

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1: currentIndex -1;
        setCurrentIndex(newIndex)
    }
    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length -1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)
    }
    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
      };
  return (
    <Swipe onSwipeLeft={prevSlide} onSwipeRight={nextSlide} >
    <div className='  w-full  xl:pt-[95px] md:pt-[95px] sm:pt-[95px] xs:pt-[75px] relative group'>
      <img src={slides[currentIndex].url} alt="carousels" className='w-full h-full   bg-cover bg-center duration-500'/>
    {/* <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }} className='w-full h-full   bg-cover bg-center duration-500' > */}
      {/* <Showcase title={slides[currentIndex].title} description={slides[currentIndex].description} command={slides[currentIndex].command} link={slides[currentIndex].link}/> */}
    {/* </div> */}
  
    {/* Left Arrow */}
    <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
      <BsChevronCompactLeft onClick={prevSlide} size={30} />
    </div>
    {/* Right Arrow */}
    <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
      <BsChevronCompactRight onClick={nextSlide} size={30} />
    </div>
    <div className='absolute w-full flex justify-center bottom-0 xs:hidden'>
      {slides.map((slide, slideIndex) => (
        <div
          key={slideIndex}
          onClick={() => goToSlide(slideIndex)}
          className={slideIndex === currentIndex
                      ? "h-4 w-4 bg-blue-700 rounded-full mx-2 mb-2 cursor-pointer"
                      : "h-4 w-4 bg-white rounded-full mx-2 mb-2 cursor-pointer"}
        >
        </div>
      ))}
    </div>
  </div>
    </Swipe>
  )
}

export default Carousels1