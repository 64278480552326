import React, { useState,useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineClose,AiOutlineWarning } from 'react-icons/ai';
import * as yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import PageTitle from "../../general/pageTitle";
import { MdDelete } from "react-icons/md";
import { getAllDepts } from "../../../services/departmentServices";
import { getAllStaff } from "../../../services/staffServices";
import {  getAllEmploy, saveEmploy,deleteEmploy } from "../../../services/employStaff";


import _ from "lodash"


function EmployStaff(props) {
    const [depts, setDepts] = useState([])
    const [employ, setEmploy] = useState([])
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const [staffData, setStaffData] = useState([]);
    const [staffId, setStaffId] = useState();
    const [staffName, setStaffName] = useState();

    const [togle, setTogle] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [currentEmployData, setCurrentEmployData] = useState()
    const [cofirmError, setCofirmError] = useState('')

  
    const fields = [{name: "Staff ID", value:"staffId"}, 
                      {name: "Email", value:"email"}, 
                      {name: "Mobile No", value:"mobileNumber"}]


  const validationSchema = yup.object().shape({
    occupation: yup.string("enter Occupation").required("enter Occupation"),
    department: yup
      .string("Select department name.")
      .required("Select department name."),
  });

  useEffect( () => {

    async function fetchDeptsData() {
        const response = await getAllDepts();
        // const userNames = response.data.map(res => res.username)
        setDepts(response.data);
      }
      fetchDeptsData();
      async function fetchStaffData() {
        const response = await getAllStaff();
        setStaffData(response.data);
      }
      fetchStaffData();
      async function fetchEmployData() {
        const response = await getAllEmploy();
        setEmploy(response.data);
      }
      fetchEmployData();
  },[employ])

  const handleDelete = async(e) => {
    if(e) 
    {const d = await deleteEmploy(e._id)}
    setTogle(!togle)
    setCurrentEmployData(e)
  }

  const handleSelect = (e) => {
    setFilter(e.currentTarget.value)
}
const handleSearch = () => {
    const result = _.find(staffData,[filter, search.trim()])
    // setStaffData([result])
    if(result){
    setStaffId(result.staffId)
    setStaffName(result.fullName)
    }
    else{
        setStaffId("no staff found")
        setStaffName("no staff found")
    }
}
const handleDelateEmploy = async(e) => {
  if(e){
  if( confirm === e._id){
    setCofirmError("")
    setTogle(!togle)
    } 
    else{
      setCofirmError('pleaes confirm deletion!')
    }
  }
}
const showModel = () =>{
  setConfirm("")
  setCofirmError('')
  setTogle(!togle)
}

  return (
    <>
    <div>
      <PageTitle title="Employ Staff" subTitle="Staff" />

      <div className=" w-full overflow-auto  ">
        <div className="flex flex-col justify-center p-4 ">
          <Formik
            initialValues={{
              occupation: "",
              department: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              if (values.department === "--select department--") {
                actions.setSubmitting(false);
                actions.setErrors({ department: `please select correct department` });
                return;
              }
              if(staffId === "no staff found" || staffName === "no staff found"){
                setStaffId("Please select the staff-ID");
                setStaffName("Please select the staff-Name");

                return;
              }
              if(staffId === undefined || staffName === undefined){
                setStaffId("Please select the staff-ID");
                setStaffName("Please select the staff-Name");

                return;
              }
              const resp = await saveEmploy({staffId,staffName,...values})
              actions.setFieldValue("occupation","")
              setStaffName("")
              setStaffId("")
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col bg-white border shadow-sm rounded-xl"
              >
                <div className="flex justify-between items-center py-2.5 px-4 border-b">
                
                <div className="flex justify-center items-center  px-4 ">
                <div className="flex flex-row items-center">
                  <select
                    name="filter"
                    value={filter}
                    onChange={(e) => handleSelect(e)}
                    className="form-select md:w-[150px] font-semibold"
                  >
                    <option value="--select filter--">--select filter--</option>
                    {fields.map((f, i) => (
                      <option key={i} value={f.value}>
                        {f.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="form-input md:w-[400px] mx-3 text-[15px] "
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleSearch}
                  type="button"
                  className="btn bg-primary text-white mx-2"
                >
                  Find
                </button>
              </div>
                  <Link
                    to="/staff-list"
                    className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                  >
                    <IoMdClose />
                  </Link>
                </div>
                <div className="p-4 overflow-y-auto">
                  <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Staff ID :
                      </label>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mx-5"
                      >
                        {staffId}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="text-gray-800 text-sm font-medium inline-block mb-2">
                        Full Name :
                      </label>
                      <label className="text-gray-800 text-sm font-medium inline-block mx-5">
                        {staffName}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-6 mt-10">
                  <div>
                      <label
                        htmlFor="example-select"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Department
                      </label>
                      <select
                        name="department"
                        value={values.department}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-select"
                      >
                          <option value="--select department--">--select department--</option>
                        {depts.map( d => (
                            <option key={d.code} value={d.department}>{d.department}</option>
                        ))}
                       
                      </select>
                      {errors.department && touched.department && errors.department}
                    </div>
                    <div>
                      <label
                        htmlFor="example-select"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Occupation{" "}
                      </label>
                      <input
                        className="form-input"
                        name="occupation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.occupation}
                      />
                      {errors.occupation && touched.occupation && errors.occupation}
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t my-4">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn bg-primary text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>


                <div className="p-4">
                    <div className="border rounded-lg overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50 xs:text-[10px]">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-start xs:text-[10px] text-sm text-gray-500">Staff ID</th>
                                    <th scope="col" className="px-6 py-3 text-start text-sm sm:hidden xs:hidden text-gray-500">Staff Name</th>
                                    <th scope="col" className="px-6 py-3 text-start xs:text-[10px] text-sm text-gray-500">Department name</th>
                                    <th scope="col" className="px-6 py-3 text-start sm:hidden xs:hidden text-sm text-gray-500">Occupation</th>
                                    <th scope="col" className="px-6 py-3 text-sm text-gray-500  text-center md:w-[200px] xl:w-[200px] xs:text-[10]">Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 md:text-[12px] ">
                                {employ &&
                                    employ.map((e,i) => (
                                        <tr key={i}>
                                        <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-800 xs:text-[10px]">{e.staffId}</td>
                                        <td className="px-6 py-4 whitespace-nowrap sm:hidden xs:hidden font-medium text-gray-800">{e.staffName}</td>
                                        <td className="xs:text-[10px] px-6 py-4 whitespace-nowrap  font-medium text-gray-800">{e.department}</td>
                                        <td className="px-6 py-4 whitespace-nowrap sm:hidden xs:hidden font-medium text-gray-800">{e.occupation}</td>
                                       
                                       
                                        <td className="px-6 py-4 whitespace-nowrap text-end  font-medium flex flex-row  justify-center">
                                            <Link onClick={() => handleDelete(e)}>
                                                <MdDelete
                                                    size={20}
                                                    className="cursor-pointer m-1 hover:text-primary"/>
                                            </Link>
                                        </td>
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            
              </form>
            )}

          </Formik>
        </div>
      </div>
</div>
<div className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-30 ease-in duration-300 ${togle ? "" : "hidden"}`} >

{/* <div className='bg-[#EDF4F4] xs:w-full  w-[300px] max:w-[500px]  overflow-auto pt-4 fixed '> */}
  <div className='flex flex-col justify-center '>

  <div className="flex flex-col bg-white border shadow-sm rounded-xl">
                                            <div className="flex justify-between items-center py-2.5 px-4 border-b">
                                                <h3 className="font-bold text-gray-800">
                                                    Firing Staff
                                                </h3>
                                                <button data-fc-dismiss type="button" className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm" onClick={showModel}>
                                                    <AiOutlineClose className="text-xl" />
                                                </button>
                                            </div>
                                            <div className="p-4 overflow-y-auto flex flex-row">
                                              <div className="mx-10">
                                              <AiOutlineWarning  className="text-7xl text-danger"/>
                                              </div>
                                              <div className="mx-7">
                                                <p className="mt-1 text-gray-800">
                                                    Do you want to Fire the staff <span className="font-semibold">{currentEmployData && currentEmployData.staffName}</span> with this code :
                                                    <br />
                                                    <span className="font-semibold">
                                                    {currentEmployData && currentEmployData._id}
                                                    </span>
                                                </p>
                                              </div>
                                            </div>
                                            
                                            <div className="w-full px-10 my-3">
                                              <input required
                                                placeholder="write the code here to confirm"
                                                name="titleImageCaption"
                                                onChange={(e) => setConfirm(e.target.value)}
                                                value={confirm}
                                                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                              />
                                              <div className="mx-4 my-2">
                                                  {cofirmError}
                                              </div>
                                            </div>
                                            <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t">
                                                <button data-fc-dismiss type="button" className="btn bg-secondary text-white" onClick={showModel}>
                                                    No
                                                </button>
                                                <button data-fc-dismiss type="button" className="btn bg-warning text-white" onClick={() => handleDelateEmploy(currentEmployData)}>
                                                    Yes
                                                </button>
                                               
                                            </div>
                                        </div>

  </div> 
  {/* </div/> */}

  </div>
    </>
  );
}

export default EmployStaff;
