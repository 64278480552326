import React, {useState, useEffect} from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import {Link, useNavigate} from "react-router-dom"
import {register,isUserExist } from "../../../services/userServices"


const Register = () => {
    const [usersList, setUsersList] = useState([]);
    const [emailList, setEmailList] = useState([]);

  const navigate = useNavigate();
  
  useEffect( () => {

    async function fetchData() {
        const response = await isUserExist();
        const userNames = response.data.map(res => res.username)
        setUsersList(userNames);
        const allemails = response.data.map(res => res.email)
        setEmailList(allemails);
      }
      fetchData();
  },[])


    const validationSchema = yup.object({
        username: yup
          .string('Enter your a user name')
          .required('user name is required'),
        email: yup
          .string('Enter your email')
          .email('Enter a valid email')
          .required('Email is required'),
        password: yup
          .string('Enter your password')
          .min(5, 'Password should be of minimum 5 characters length')
          .required('Password is required'),
        confirmPassword: yup
          .string('Confrim password')
          .oneOf([yup.ref('password'), null], "Passwords don't match")
      });
    return  (
    <div className=''>
    <div className='px-5 py-6 mx-5 my-3'>

       
         
    
    <Formik
      initialValues={{ username: '',email: '', password: '',confirmPassword: '' }}
      validationSchema={validationSchema}
      onSubmit={ async(values, actions) => {
        if(usersList.includes(values.username.trim())) {
            actions.setSubmitting(false)
            actions.setErrors({ username: `user ${values.username} is already taken.`})
            return
        }else if(emailList.includes(values.email.trim())) {
            actions.setSubmitting(false)
            actions.setErrors({ email: `already register, please login`})
            return
        }else{
            actions.setErrors({username:'', email: ''})
            await register(values)
            actions.setSubmitting(true)
            navigate('/login')
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
        }) => 
        (
        <form onSubmit={handleSubmit} className='w-full flex justify-center flex-col items-center px-8'>
           <div className='w-full mt-10'>
                <input
                    placeholder="User name"
                    name="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    className='border-2 border-gray-300 py-2 px-2 w-full focus:outline-none rounded-[3px]'
                />
                {errors.username && touched.username && errors.username }
                
           </div>
           <div className='w-full mt-3'>
                <input
                    placeholder="Email address"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className='border-2 border-gray-300 py-2 px-2  focus:outline-none rounded-[3px]'
                />
            {errors.email && touched.email && errors.email}
           </div>
           <div className='w-full mt-3'>
                <input
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className='border-2 border-gray-300 py-2 px-2 w-full focus:outline-none rounded-[3px]'
                />
               {errors.password && touched.password && errors.password}
           </div>
           <div className='w-full mt-3'>
                <input
                    placeholder="Confirm password"
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    className='border-2 border-gray-300 py-2 px-2 w-full focus:outline-none rounded-[3px]'
                />
                {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
           </div>
           <div className='w-full mt-12 space-y-3'>

          <button type="submit" disabled={isSubmitting} className='bg-blue-500 text-[18px] font-medium text-white py-3 px-2 w-full focus:outline-none rounded-[3px] uppercase hover:bg-[#465B53]'>
            sing up
          </button>
           </div>

          <div className='my-5 text-blue-500 flex justify-end w-full text-[20px]'>
              <Link to="/login" className='hover:underline'>have account, sign in</Link>
            </div>
        </form>
      )}
    </Formik>
  </div>
  </div>
)};

export default Register;