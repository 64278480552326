import React, { useState } from 'react'
import _ from "lodash";
import shahid from "../../../assets/images/profle/campus/1.jpg"
import adaw from "../../../assets/images/profle/campus/2.jpg"
import Library from "../../../assets/images/profle/campus/3.jpg"
const Campus = () => {
  return (
    <div className='flex flex-col justify-center items-center'>
          <p className='uppercase text-5xl sm:text-2xl xs:text-2xl text-black font-bold mx-10 font-montserrat'>Our Campus</p>
          <div className='space-y-5 mb-20'>
            <p className='text-3xl sm:text-base xs:text-base text-black'>1. Dr. Adaw Campus</p>
            <img src={shahid} alt="shihid campus" />
            <p className='text-3xl text-black sm:text-base xs:text-base'>2. Dr. Shahid Campus</p>
            <img src={adaw} alt="adaw campus" />
            
            <img src={Library} alt="" />
          </div>
          

</div>
)
}

export default Campus