import React, { useState } from "react";
import _ from "lodash";
import shahid from "../../../assets/images/profle/campus/1.jpg";
import adaw from "../../../assets/images/profle/campus/2.jpg";
import Library from "../../../assets/images/profle/campus/3.jpg";
import BioNav from "./bioNav";

const LeaderNav = ({ children }) => {
  // const
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-[80%] justify-center items-center">
        <div>
          <p className="uppercase text-5xl sm:text-2xl xs:text-2xl text-black font-bold mx-10 font-montserrat">
            University Leadership
          </p>
        </div>
        <div className="mt-5 h-[2px] bg-black w-full"></div>
        <div className="flex flex-row sm:flex-col xs:flex-col w-ful space-x-3 ">
          <BioNav />
          <div className=" w-full md:border-l-[#0863AA] xl:border-l-[#0863AA] md:border-l-2 xl:border-l-2">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderNav;
