import React, { useState, useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { PreviewFile } from "./preview";

function getAllowedExt(type) {
  return validFileExtensions[type].map((e) => `.${e}`).toString();
}

const validFileExtensions = {
  image: ["jpg", "png", "jpeg"],
};


export const RenderMultipleUploadButton = (
  setFieldValue,
  values,
  errors,
  inputName,inputType
) => {
  let allowedExts = getAllowedExt(inputType);




  return (
    <>
      <div className="button-wrap">
      <div className="flex flex-col justify-start items-start">
        <label className="button label" htmlFor={inputName}>
          <span>Upload image title </span>
          <span className="ext">[{allowedExts}]</span>
        </label>
       
        <div className="flex flex-row">
        <input
        multiple
        id={inputName}
        name={inputName}
        type="file"
        accept={allowedExts}
        placeholder="Upload image"
        className="block w-fit text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 my-3"
          onChange={(event) => {
            setFieldValue(inputName, event.currentTarget.files);
          }}
        />
         
        </div>
        </div>
        {/* {values[inputName] ? (
         <PreviewFile
            className={{ margin: "auto" }}
            width={500}
            height={"auto"}
            file={values[inputName]}
          />        
        ) : null} */}
      </div>
      <div className="error">
        <ErrorMessage name={inputName} />
      </div>
    </>
  );
};
