import React, { useState} from 'react'
import Swipe from "react-easy-swipe";
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import api from "../../config.json";



// const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

function NewsSlider({list: slides}) {

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1: currentIndex -1;
        setCurrentIndex(newIndex)
    }
    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length -1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)
    }
    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
      };
  
    const url = api.url
    

  return (
    <Swipe onSwipeLeft={prevSlide} onSwipeRight={nextSlide} >
    <div className=' relative group bg-slate-500 w-fit'>
    <img  src={url + slides[currentIndex].imageUrls} className='rounded-md object-cover  xl:w-[800px] xl:h-[600px] ' alt="bandhig"/>
   

    {/* Left Arrow */}
    <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
      <BsChevronCompactLeft onClick={prevSlide} size={30} />
    </div>
    {/* Right Arrow */}
    <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
      <BsChevronCompactRight onClick={nextSlide} size={30} />
    </div>
    <div className='absolute w-full flex justify-center bottom-0'>
      {slides.map((slide, slideIndex) => (
        <div
          key={slideIndex}
          onClick={() => goToSlide(slideIndex)}
          className={slideIndex === currentIndex
                      ? "h-4 w-4 bg-blue-700 rounded-full mx-2 mb-2 cursor-pointer"
                      : "h-4 w-4 bg-white rounded-full mx-2 mb-2 cursor-pointer"}
        >
        </div>
      ))}
    </div>
  </div>
    </Swipe>
  )
}

export default NewsSlider