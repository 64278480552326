import img from "../assets/images/research/conference/1.jpg"
import img1 from "../assets/images/research/conference/2.jpeg"
export const upcoming = [
    {id: 1,
    img: img,
    title: "2<sup>nd</sup> Annual Reseach and Innovation Conference", 
    desc: `Welcome to the 2nd Annual Research and Innovation Conference, where pioneering minds converge to explore the frontiers of knowledge and creativity. This dynamic event serves as a nexus for scholars, researchers, innovators, and industry leaders from around the globe to exchange ideas, collaborate on groundbreaking projects, and shape the future of various disciplines.

    Join us as we delve into the latest advancements across a spectrum of fields, from science and technology to social sciences, humanities, and beyond. Engage in stimulating discussions, gain insights from esteemed keynote speakers, and network with fellow visionaries who are driving innovation forward.
    
    Whether you're a seasoned academic, a budding entrepreneur, or a passionate advocate for progress, the 2nd Annual Research and Innovation Conference offers a platform for discovery, inspiration, and impact. Together, let's chart new horizons, unlock potential, and propel humanity towards a brighter tomorrow.
    
    Mark your calendars and reserve your spot at this transformative gathering. Together, let's shape the future through research and innovation!`, 
    date: "23/20/2024"
    },
    {id: 2,
      img: img1,
      title: "2<sup>nd</sup> Annual Reseach and Innovation Conference", 
      desc: `Welcome to the 2nd Annual Research and Innovation Conference, where pioneering minds converge to explore the frontiers of knowledge and creativity. This dynamic event serves as a nexus for scholars, researchers, innovators, and industry leaders from around the globe to exchange ideas, collaborate on groundbreaking projects, and shape the future of various disciplines.
  
      Join us as we delve into the latest advancements across a spectrum of fields, from science and technology to social sciences, humanities, and beyond. Engage in stimulating discussions, gain insights from esteemed keynote speakers, and network with fellow visionaries who are driving innovation forward.
      
      Whether you're a seasoned academic, a budding entrepreneur, or a passionate advocate for progress, the 2nd Annual Research and Innovation Conference offers a platform for discovery, inspiration, and impact. Together, let's chart new horizons, unlock potential, and propel humanity towards a brighter tomorrow.
      
      Mark your calendars and reserve your spot at this transformative gathering. Together, let's shape the future through research and innovation!`, 
      date: "23/20/2024"
      },
      {id: 3,
        img: img,
        title: "2<sup>nd</sup> Annual Reseach and Innovation Conference", 
        desc: `Welcome to the 2nd Annual Research and Innovation Conference, where pioneering minds converge to explore the frontiers of knowledge and creativity. This dynamic event serves as a nexus for scholars, researchers, innovators, and industry leaders from around the globe to exchange ideas, collaborate on groundbreaking projects, and shape the future of various disciplines.
    
        Join us as we delve into the latest advancements across a spectrum of fields, from science and technology to social sciences, humanities, and beyond. Engage in stimulating discussions, gain insights from esteemed keynote speakers, and network with fellow visionaries who are driving innovation forward.
        
        Whether you're a seasoned academic, a budding entrepreneur, or a passionate advocate for progress, the 2nd Annual Research and Innovation Conference offers a platform for discovery, inspiration, and impact. Together, let's chart new horizons, unlock potential, and propel humanity towards a brighter tomorrow.
        
        Mark your calendars and reserve your spot at this transformative gathering. Together, let's shape the future through research and innovation!`, 
        date: "23/20/2024"
        },
  ]

 export const recent = [
    {id: 1,
    img: img,
    title: "2<sup>nd</sup> Annual Reseach and Innovation Conference", 
    desc: `Welcome to the 2nd Annual Research and Innovation Conference, where pioneering minds converge to explore the frontiers of knowledge and creativity. This dynamic event serves as a nexus for scholars, researchers, innovators, and industry leaders from around the globe to exchange ideas, collaborate on groundbreaking projects, and shape the future of various disciplines.

    Join us as we delve into the latest advancements across a spectrum of fields, from science and technology to social sciences, humanities, and beyond. Engage in stimulating discussions, gain insights from esteemed keynote speakers, and network with fellow visionaries who are driving innovation forward.
    
    Whether you're a seasoned academic, a budding entrepreneur, or a passionate advocate for progress, the 2nd Annual Research and Innovation Conference offers a platform for discovery, inspiration, and impact. Together, let's chart new horizons, unlock potential, and propel humanity towards a brighter tomorrow.
    
    Mark your calendars and reserve your spot at this transformative gathering. Together, let's shape the future through research and innovation!`, 
    date: "23/20/2024"
    },
    {id: 2,
      img: img1,
      title: "2<sup>nd</sup> Annual Reseach and Innovation Conference", 
      desc: `Welcome to the 2nd Annual Research and Innovation Conference, where pioneering minds converge to explore the frontiers of knowledge and creativity. This dynamic event serves as a nexus for scholars, researchers, innovators, and industry leaders from around the globe to exchange ideas, collaborate on groundbreaking projects, and shape the future of various disciplines.
  
      Join us as we delve into the latest advancements across a spectrum of fields, from science and technology to social sciences, humanities, and beyond. Engage in stimulating discussions, gain insights from esteemed keynote speakers, and network with fellow visionaries who are driving innovation forward.
      
      Whether you're a seasoned academic, a budding entrepreneur, or a passionate advocate for progress, the 2nd Annual Research and Innovation Conference offers a platform for discovery, inspiration, and impact. Together, let's chart new horizons, unlock potential, and propel humanity towards a brighter tomorrow.
      
      Mark your calendars and reserve your spot at this transformative gathering. Together, let's shape the future through research and innovation!`, 
      date: "23/20/2024"
      },
      {id: 3,
        img: img,
        title: "2<sup>nd</sup> Annual Reseach and Innovation Conference", 
        desc: `Welcome to the 2nd Annual Research and Innovation Conference, where pioneering minds converge to explore the frontiers of knowledge and creativity. This dynamic event serves as a nexus for scholars, researchers, innovators, and industry leaders from around the globe to exchange ideas, collaborate on groundbreaking projects, and shape the future of various disciplines.
    
        Join us as we delve into the latest advancements across a spectrum of fields, from science and technology to social sciences, humanities, and beyond. Engage in stimulating discussions, gain insights from esteemed keynote speakers, and network with fellow visionaries who are driving innovation forward.
        
        Whether you're a seasoned academic, a budding entrepreneur, or a passionate advocate for progress, the 2nd Annual Research and Innovation Conference offers a platform for discovery, inspiration, and impact. Together, let's chart new horizons, unlock potential, and propel humanity towards a brighter tomorrow.
        
        Mark your calendars and reserve your spot at this transformative gathering. Together, let's shape the future through research and innovation!`, 
        date: "23/20/2024"
        },
  ]