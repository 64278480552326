import React,{useState, useEffect} from "react";
import PaginatedItems from "./PaginatedItems";
import { programs } from "../../../data/programs";
import _ from "lodash";
import SelectInput from './selectInput'
import { getAllDepts } from "../../../services/departmentServices";

const AllStaff = () => {
  const [depts, setDepts] = useState();


  useEffect( () =>{
    async function fetchData() {
      const response = await getAllDepts();
      setDepts(response.data);
    }
    fetchData();
  },[])

  return (
    <div className=" w-full  xl:pt-[95px] md:pt-[95px] sm:pt-[95px] xs:pt-[75px] relative group   ">
       <div className="w-full ">
      <SelectInput depts={depts} />
      </div>
     
    </div>
  );
};

export default AllStaff;
