import React, { useState } from 'react'
import {posts} from '../../data/posts'
import { Link } from 'react-router-dom'


const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

const campus =  backet + '/others/campus.png'
const Slide5 = backet + '/others/Slide5.jpg'
const agri = backet + '/others/agri.jpg'
const future = backet + '/others/future.jpg'
const ir = backet + '/others/ir.jpeg'
const cool = backet + '/others/cool.jpg'

const Details = () => {
  const [post, setPost] = useState(posts);

  // console.log(post)
  
  return (
    <div className=''>
        <div className='relative w-full h-[558px] mx-h-[558px]'>
          <img src={campus} className='w-full object-cover h-[558px] mx-h-[558px]' alt="details"/>
          <div className='bg-[#0802F3] absolute top-0 w-full h-full opacity-70'>
          </div>
          <div className='absolute top-[10%] md:top-[30%] left-[5%] md:w-[50%]'>
          <p className='text-[30px] md:text-[40px] font-bold text-white'>Support banadir Institutet's research</p>
          <p className='mt-8 text-white'>Do you want to contribute to new breakthroughs for life? Both large and small donations mean a lot to the success of research.</p>
          <button className='bg-white text-[#0802F3] font-medium py-3 px-6 rounded-sm mt-10'>Give a gift</button>
          </div>
          
        </div>

        {/* menu of this area */}
        <div className='bg-[#EDF4F4] px-4 md:px-10 py-12 mb-10'>
          <p className='text-[23px] font-semibold'>Menu of this area</p>
          
          <div className='grid md:grid-cols-3 mt-3 gap-4'>
            
            
          <div className='border-2 border-[#0802F3] hover:bg-[#0802F3] text-[#0802F3] py-4 hover:text-white flex items-center justify-center rounded-md'>
            <p className='font-medium text-[16px] '>Examples of the research you can support</p>
            </div>
           

            <div className='border-2 border-[#0802F3] hover:bg-[#0802F3] text-[#0802F3] py-4 hover:text-white flex items-center justify-center rounded-md'>
            <p className='font-medium text-[16px] '>Examples of the research you can support</p>
            </div>

            <div className='border-2 border-[#0802F3] hover:bg-[#0802F3] text-[#0802F3] py-4 hover:text-white flex items-center justify-center rounded-md'>
            <p className='font-medium text-[16px] '>Examples of the research you can support</p>
            </div>
          </div>

        </div>

        {/* no name */}
        <div className='grid md:grid-cols-3 mx-4 md:mx-10 mb-10 gap-4'>

        <div className='bg-white rounded-lg shadow-lg hover:border-2 hover:border-[#000] cursor-pointer'>
       <img src={Slide5} className='rounded-t-lg object-cover'/>

       <div className='px-6 mt-8 mb-10'>
       <p className='font-bold text-[16px] md:text-[1.5rem] '>Welcome to BU</p>
       <p className='text-star  font-normal tracking-wider'>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program, the medical program and the nursing program, as well as independent courses, will start. Prepare for the start of the semester already now on our welcome pagessssss.</p>
       </div>
    
       </div>


        <div className='bg-white rounded-lg shadow-lg hover:border-2 hover:border-[#000] cursor-pointer'>
       <img src={Slide5} className='rounded-t-lg object-cover'/>


       <div className='px-6 mt-8 mb-10'>
       <p className='font-bold text-[16px] md:text-[1.5rem] '>Welcome to BU</p>
       <p className='text-start font-normal tracking-wider text-slate-600'>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program, the medical program and the nursing program, as well as independent courses, will start. Prepare for the start of the semester already now on our welcome pages.</p>
       </div>
    

       </div>


        <div className='bg-white rounded-lg shadow-lg hover:border-2 hover:border-[#000] cursor-pointer'>
       <img src={Slide5} className='rounded-t-lg object-cover'/>

       <div className='px-6 mt-8 mb-10'>
       <p className='font-bold text-[16px] md:text-[1.5rem] '>Welcome to BU</p>
       <p className='text-start font-normal tracking-wider text-slate-600'>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program, the medical program and the nursing program, as well as independent courses, will start. Prepare for the start of the semester already now on our welcome pages.</p>
       </div>
    
       </div>

       
        </div>

        {/* No Name 2*/}
        <div className='grid md:grid-cols-2 gap-4 md:mx-10 mb-10'>

          <div className='grid grid-cols-2 border hover:border-black rounded-lg'>

            <div className='h-full w-full rounded-lg'>
              <img src={Slide5} className='rounded-lg object-cover h-full'/>
            </div>

            <div className='px-6 py-6'>
              <p className='font-bold tracking-wider my-2'>Give a gift</p>
              <p>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program.</p>
            </div>
          </div>
          
          <div className='grid grid-cols-2 border hover:border-black rounded-lg'>

            <div className='h-full w-full rounded-lg'>
              <img src={Slide5} className='rounded-lg object-cover h-full'/>
            </div>

            <div className='px-6 py-6'>
              <p className='font-bold tracking-wider my-2'>Give a gift</p>
              <p>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program.</p>
            </div>
          </div>

          
        </div>


      {/* Inspired by our donors*/}
      <div className=' mx-4 md:mx-10 mb-10'>

        <p className='font-bold text-[30px] my-2'>Inspired by our donors</p>

        <div className='grid md:grid-cols-4 gap-4'>

      <div className='bg-white rounded-lg border cursor-pointer'>
      <img src={Slide5} className='rounded-t-lg object-cover'/>

      <div className='px-6 mt-8 mb-10'>
      <p className='font-bold text-[16px] md:text-[1.5rem] '>Welcome to BU</p>
      <p className='text-start font-normal tracking-wider text-slate-600'>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring.</p>
      </div>

      </div>

      <div className='bg-white rounded-lg border cursor-pointer'>
      <img src={Slide5} className='rounded-t-lg object-cover'/>

      <div className='px-6 mt-8 mb-10'>
      <p className='font-bold text-[16px] md:text-[1.5rem] '>Welcome to BU</p>
      <p className='text-start font-normal tracking-wider text-slate-600'>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring.</p>
      </div>

      </div>

      <div className='bg-white rounded-lg border cursor-pointer'>
      <img src={Slide5} className='rounded-t-lg object-cover'/>

      <div className='px-6 mt-8 mb-10'>
      <p className='font-bold text-[16px] md:text-[1.5rem] '>Welcome to BU</p>
      <p className='text-start font-normal tracking-wider text-slate-600'>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring.</p>
      </div>

      </div>

      <div className='bg-white rounded-lg border cursor-pointer'>
      <img src={Slide5} className='rounded-t-lg object-cover'/>

      <div className='px-6 mt-8 mb-10'>
      <p className='font-bold text-[16px] md:text-[1.5rem] '>Welcome to BU</p>
      <p className='text-start font-normal tracking-wider text-slate-600'>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring.</p>
      </div>

      </div>

      </div>

      </div>

      {/* No name 3 */}
        <div className=''>
      <div className='grid md:grid-cols-4 gap-4 mb-10 p-10 bg-[#f1f1f1]'>

      <div className='bg-white rounded-md hover:border-2 hover:border-[#000] cursor-pointer'>
      <img src={Slide5} className='rounded-t-lg object-cover'/>

      <div className='px-6 mt-8 mb-10'>
      <p className='font-medium text-[20px] '>Welcome to BU</p>
      </div>

      </div>


      <div className='bg-white rounded-md hover:border-2 hover:border-[#000] cursor-pointer'>
      <img src={Slide5} className='rounded-t-lg object-cover'/>

      <div className='px-6 mt-8 mb-10'>
      <p className='font-medium text-[20px]'>Welcome to BU</p>
      </div>

      </div>


      <div className='bg-white rounded-md hover:border-2 hover:border-[#000] cursor-pointer'>
      <img src={Slide5} className='rounded-t-lg object-cover'/>

      <div className='px-6 mt-8 mb-10'>
      <p className='font-medium text-[20px]'>Welcome to BU</p>
      </div>

      </div>


      <div className='bg-white rounded-md hover:border-2 hover:border-[#000] cursor-pointer'>
      <img src={Slide5} className='rounded-t-lg object-cover'/>

      <div className='px-6 mt-8 mb-10'>
      <p className='font-medium text-[20px]'>Welcome to BU</p>
      </div>

      </div>

        
      </div>
      </div>


    {/* cards */}
    <div className='grid grid-cols-1 md:grid-cols-3 mt-10 mx-4 md:mx-10 mb-5 gap-y-4 md:gap-6 justify-center items-center'>
        {/* card one */}
       <div className='bg-white rounded-lg shadow-lg hover:border-2 hover:border-[#000] cursor-pointer'>
       <img src={Slide5} className='rounded-t-lg object-cover'/>

       <div className='px-6 mt-8 mb-10'>
       <p className='font-bold text-[16px] md:text-[1.5rem] '>Welcome to BU</p>
       <p className='text-start text-[13px]'>The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program, the medical program and the nursing program, as well as independent courses, will start. Prepare for the start of the semester already now on our welcome pages.</p>
       </div>
    
       </div>

        {/* card two */}
        <div className='col-span-2'>

          <div className='grid grid-cols-2 gap-2 md:gap-6'> 

          {/* post one */}
         <div className='relative cursor-pointer border-[1px] hover:border-2 hover:border-[#000] rounded-lg h-[200px] mx-h-[200px]'>
          <Link to='/details'>
         <img src={agri} className='rounded-lg w-full object-cover h-full'/>
          <div className='bg-white rounded-b-lg absolute bottom-0 w-full opacity-90'>
                <p className='px-2 py-4 text-[20px] font-semibold'>Make a gift Research</p>
          </div>         
          </Link>
         </div>


          {/* post two */}
         <div className='relative cursor-pointer border-[1px] hover:border-2 hover:border-[#000] rounded-lg h-[200px] mx-h-[200px]'>
         <img src={future} className='rounded-lg w-full object-cover h-full'/>
          <div className='bg-white rounded-b-lg absolute bottom-0 w-full opacity-90'>
                <p className='px-2 py-4 text-[20px] font-semibold'>Make a gift Research</p>
          </div>
         </div>


          {/* post three */}
         <div className='relative cursor-pointer border-[1px] hover:border-2 hover:border-[#000] rounded-lg h-[200px] mx-h-[200px]'>
         <img src={ir} className='rounded-lg object-cover w-full h-full'/>
          <div className='bg-white rounded-b-lg absolute bottom-0 w-full opacity-90'>
                <p className='px-2 py-4 text-[20px] font-semibold'>Make a gift Research</p>
          </div>
         </div>


          {/* post four */}
         <div className='relative cursor-pointer border-[1px] hover:border-2 hover:border-[#000] rounded-lg h-[200px] mx-h-[200px]'>
         <img src={cool} className='rounded-lg object-cover w-full h-full'/>
          <div className='bg-white rounded-b-lg absolute bottom-0 w-full opacity-90'>
                <p className='px-2 py-4 text-[20px] font-semibold'>Make a gift Research</p>
          </div>
         </div>

          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Details