import React from "react";
import {   Link } from "react-router-dom";

const Popular = ({list}) => {

    // list.push({id: 1})

  return (
         <div className="flex flex-col space-y-2" >
        {
          list.map( m => (
           
            <Link key={m.id} to={`/academics/undergraduate/${m.slug}/about`} className=" font-serif w-fit font-normal text-base text-[#0863AA] my-2 hover:underline">
              <p className=" text-base font-normal ">{m.program}</p>
             
            </Link>
           
          ))
        }
      </div>

  );
};

export default Popular;