import React from 'react'
import {programs} from "../../../data/programs"
import {Link} from 'react-router-dom'


const Schools = () => {
  return (
    <div className='py-5 px-4'>
        <div className='bg-slate-200  py-3 px-5  flex justify-between'>
            <p className='text-lg font-semibold'>
            Schools
            </p> 
            <Link to="/newschool" className="font-medium bg-blue-700 text-white dark:text-blue-500 hover:bg-blue-900 px-3 py-1 rounded-lg">Post a school</Link>
        </div>
        <div className="overflow-x-auto relative shadow-md ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs font-bold text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            sno
                        </th>
                        <th scope="col" className="py-3 px-6">
                            School
                        </th>
                      
                     
                        <th scope="col" className="py-3 px-6">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {programs[0].lists.map( (n,i) => (
                        <tr key={i} className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {n.id}
                        </th>
                        <td className="py-4 px-6">
                        <Link href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">{n.program}</Link>
                        </td>
                        <td className="py-4 px-6 flex justify-between">
                        <Link href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</Link>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Schools