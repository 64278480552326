import React from "react";
import { Link } from "react-router-dom";
import { careers } from "../../../data/careers";

const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";

const icon1 = backet + "/careers/cameraman.PNG";
const icon2 = backet + "/careers/professional.PNG";
const cover = backet + "/others/cover.jpeg";

const Careers = () => {
  const classLink_active = `uppercase py-1 px-5 rounded-full text-white bg-[#0843aafe] hover:bg-[#5ab848]`;
  const classLink_disabled = `uppercase py-1 px-5 rounded-full text-white bg-[#cfcfcffe] hover:bg-[#5ab848] disabled-link`;

  const today = new Date();

  const careerTitle = "Join us in building a better world";
  const careerBody =
    "Did you know over 379 people work at Benadir University? Our dynamic environment, distinct culture, and diverse community foster collaboration and excellence in every corner of the university. We offer an extensive range of benefits and resources that simply aren’t found together anywhere else.";

  return (
    <div className="bg-[#fff] flex flex-col ">
      <div className="relative w-full h-[558px] mx-h-[558px]">
        <img
          src={cover}
          className="w-full object-cover h-[558px] mx-h-[558px]"
          alt="admission"
        />
        <div className="absolute top-[80%]  w-full h-[15%] bg-white opacity-50 flex justify-center items-center">
          {" "}
        </div>
        <p className=" text-6xl xs:text-4xl  font-bold text-[#0843aafe] text-center uppercase absolute w-full top-[83%]">
          Careers
        </p>
      </div>
      <div className="my-10">
        <p className="text-3xl font-semibold text-center py-5">{careerTitle}</p>
        <div className="flex flex-col justify-center items-center ">
          <p className="text-left md:w-[800px] xl:w-[800px] text-xl leading-6 tracking-normal py-4 my-3 mx-3">
            {careerBody}
          </p>

          <div className="p-3 grid grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 gap-3 justify-center items-center place-items-center  md:w-[800px] xl:w-[800px]">
            {careers.map((c) => (
              <div
                key={c.id}
                className="border border-[#6c6b6c] solid rounded-3xl p-3 flex flex-col justify-center items-center space-y-2"
              >
                <img src={c.icon} alt="icon" className="h-[70px] w-[70px]" />
                <p className="font-bold text-base text-center line-clamp-2">
                  {c.title}
                </p>
                <p className="text-base text-center line-clamp-2">{c.desc}</p>
                <Link
                  to={`${c.careerid}`}
                  className={
                    c.isActive
                      ? classLink_active
                      : classLink_disabled
                  }
                >
                  {c.isActive ? "View" : "Expired"}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
