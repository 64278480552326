import React from "react";
import { NavLink, Link } from "react-router-dom";

const sample =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/ractor.jpg";

const ResearchAllience = (props) => {
  return (
    <div>
      <p className="text-2xl">Commig soon....</p>

      {/* <div className='flex justify-center items-center'>
                  <p className='text-5xl text-[#0863AA] font-semibold'>
                    
                  </p>
                </div>
          <div>
            <p className="py-5 text-2xl ">
               commig soon
             
            </p>
         
   

          </div> */}
    </div>
  );
};

export default ResearchAllience;
