import React from 'react'
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { Link } from 'react-router-dom';



function Box() {
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 gap-6 mb-6 font-base ">
        <div className="col-xl-3 col-md-6">
            <div className="card">
                <div className="p-5">
                    <FaUsers className="float-right text-3xl text-muted text-slate-400"/>
                    <Link to="">
                    <h6 className="text-muted text-sm uppercase hover:underline hover:text-primary ">Number Of Students</h6>
                    </Link>
                    <h3 className="text-2xl mb-3" data-plugin="counterup">1,587</h3>
                    <span className="inline-flex items-center gap-1.5 py-0.5 px-1.5 text-xs font-medium bg-green-500 text-white rounded me-1"> +11% </span> <span className="text-muted">From previous period</span>
                </div>
            </div>
        </div>

        <div className="col-xl-3 col-md-6">
            <div className="card">
                <div className="p-5">
                    <RiMoneyDollarCircleLine className="float-right text-3xl text-muted text-slate-400"/>
                    <h6 className="text-muted text-sm uppercase">Alumni</h6>
                    <h3 className="text-2xl mb-3">$<span data-plugin="counterup">46,782</span></h3>
                    <span className="inline-flex items-center gap-1.5 py-0.5 px-1.5 text-xs font-medium bg-danger text-white rounded me-1"> -29% </span> <span className="text-muted">From previous period</span>
                </div>
            </div>
        </div>

        <div className="col-xl-3 col-md-6">
            <div className="card">
                <div className="p-5">
                    <i className="bx bx-bx bx-analyse float-right text-3xl text-muted"></i>
                    <h6 className="text-muted text-sm uppercase">Number of staff</h6>
                    <h3 className="text-2xl mb-3">$<span data-plugin="counterup">15.9</span></h3>
                    <span className="inline-flex items-center gap-1.5 py-0.5 px-1.5 text-xs font-medium bg-warning text-white rounded me-1"> 0% </span> <span className="text-muted">From previous period</span>
                </div>
            </div>
        </div>

        <div className="col-xl-3 col-md-6">
            <div className="card">
                <div className="p-5">
                    <i className="bx bx-basket float-right text-3xl text-muted"></i>
                    <h6 className="text-muted text-sm uppercase">Active programs</h6>
                    <h3 className="text-2xl mb-3" data-plugin="counterup">1,890</h3>
                    <span className="inline-flex items-center gap-1.5 py-0.5 px-1.5 text-xs font-medium bg-success text-white rounded me-1"> +89% </span> <span className="text-muted">Last year</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Box