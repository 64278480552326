import { http } from "./httpServices";
import api from "../config.json";

// const url = api.local + "/activity";
const url = api.global + "/activity";


function activityUrl(id) {
  return `${url}/${id}`;
}

export function getAllActivity() {
  return http.get(url);
}

export async function getActivityHeaders(){
  const activity = await getAllActivity()
  return activity.data
}

export function deleteActivity(activityId) {
  return http.delete(activityUrl(activityId));
}

export async function getActivity(activityId) {
  return http.get(activityUrl(activityId));
}

export function saveActivity(activity) {
  return http.post(url, activity,
    {
      headers: {'Content-Type': 'multipart/form-data'}
    });
}

export function updateActivity(activity) {
  // console.log(activity)
    return http.put(activityUrl(activity._id), activity,{
      headers: {'Content-Type': 'multipart/form-data'}
    });

}
