import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Requirements = props => {

  const list = [
    "Completed application form",
    "Higher/Secondary Certificate or equivalents (e.g. O/A’Level)",
    "Evidence of English Language competence: TOEFL (65 IBT) or IELTS (5.5). Students without these",
    "documents will take the BU English proficiency exam on campus following arrival",
    "Scanned copy of international passport/birth certificate",
    "Fully completed and signed BU Rules and Regulations document (which can be downloaded during the online application)"
  ]

  const list1 = [
    "Application Letter",
    "Secondary Leaving Certificate",
    "Birth certificate or a notarized copy of it",
    "Four passport photo size",
    "Pay 25$ Admission Fee Entrance Exam"
  ]
  
  return (
         <div  className="text-black text-justify my-10 xl:mx-[300px] md:mx-[100px]">
          <div className="md:mx-10 xl:mx-10">
          <Tabs className="w-[80%] ">
            <TabList className="flex flex-row font-bold bg-[#E8EDED] w-fit border-b-2 border-[#0863AA]">
              <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-xl text-[#0863AA] hover:cursor-pointer hover:bg-[#0863AA] hover:text-white">Citizens</Tab>
              <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-xl text-[#0863AA] hover:cursor-pointer hover:bg-[#0863AA] hover:text-white">International</Tab>
            </TabList>
            <TabPanel>
              <p className="my-3 md:mx-10 xl:mx-10">Somali citizens are candidate students who have completed their entire high school education. They are placed in undergraduate programs in line with their success in the BU Student Placement Exam and the programs they prefer.
              <ol className="list-disc md:mx-10 xl:mx-10 mx-3">
              {
                list1.map( (l,i) => (
                  <li key={i}>{l}</li>
                ))
              }
              </ol>
              </p>
            </TabPanel>
            <TabPanel>
              <div className="my-3 md:mx-10 xl:mx-10">
              <p>Applicants can directly apply online to our undergraduate programs by using the application portal. Please fill in your details correctly and upload all the required documents listed on the last page of the application form.</p>
              <p className="text-2xl font-semibold my-5">
              Required documents
              </p>
              <ol className="list-disc md:mx-10 xl:mx-10 mx-3">
              {
                list.map( (l,i) => (
                  <li key={i}>{l}</li>
                ))
              }
              </ol>
              </div>
            
            </TabPanel>
        </Tabs>
          </div>
      </div>

  );
};

export default Requirements;