import React, { useState } from 'react'
import _ from "lodash";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import pan from "../../../assets/images/profle/counceling/1.jpg"
import dre from "../../../assets/images/profle/counceling/6.png"

const Counceling = () => {
  const lst = [
    'Personal',
    'Educational',
    'Vocational',
    'Psychological assesment',
    'Mental health',
    'Career counseling'
  ];

  const doc = {
    name: "Dr. Abdullahi Ali Hayle",
    title: "PhD in health governance",
    phone: "+25261 9928273",
    text: "+25261 5559323",
    email: "abdullahixayle@gmail.com",
    Office: "Dr. Adaw Campus, Floor 5, B-04",
    photo: dre
  }

  return (
    <div className='flex flex-col justify-center items-center'>

     <div className='my-5'>
      <img src={pan} alt="" className='w-full ' />
     </div>

     <div className='md:w-[73%] xl:w-[73%]'>
        <p className='text-base font-normal whitespace-pre-line text-black font-montserrat leading-loose my-2'>
        A counseling center at a university serves as a vital resource, offering a range of support services to enhance students' mental health and well-being. These centers provide confidential counseling sessions with trained professionals to help students navigate personal, academic, and emotional challenges.</p>
        <p className='text-base font-normal whitespace-pre-line text-black font-montserrat leading-loose my-2'>
        Whether it's coping with stress, managing relationships, or addressing mental health concerns such as anxiety or depression, counselors offer guidance and strategies tailored to individual needs. Additionally, counseling centers often organize workshops, group therapy sessions, and outreach programs to promote mental wellness and resilience across campus. 
        </p>
        <p className='text-base font-normal whitespace-pre-line text-black font-montserrat leading-loose my-2'>
        By fostering a supportive and inclusive environment, these centers play a crucial role in empowering students to thrive academically and personally throughout their university journey.
        </p>
     </div>

     
      
      <Tabs className="md:w-[73%] xl:w-[73%] ">
            <TabList className="flex flex-row font-bold bg-[#E8EDED] w-fit border-b-2 border-[#0863AA]">
              <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 sm:text-base xs:text-base text-xl text-[#0863AA] hover:cursor-pointer hover:bg-[#0863AA] hover:text-white">Our services</Tab>
              <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-xl text-[#0863AA] hover:cursor-pointer hover:bg-[#0863AA] hover:text-white sm:text-base xs:text-base">Contact us</Tab>
            </TabList>
            <TabPanel>
             
              <p className="my-3  text-black font-montserrat">
                Counseling office at BU is home to a diverse group of provides who share one common goal that is to serve our community by supporting the mental and emotional health of everyone who walks through our doors.</p>
                <p className="text-xl text-black  my-5 ">
                Counseling services offered:
              </p>
                <ol className='md:mx-16 xl:mx-16 font-normal my-1 text-sm text-black list-disc '>
                  {lst.map( l => (
                    <li key={l} className='my-2'>{l}</li>
                  ))}
                </ol>
              
            </TabPanel>
            <TabPanel>
              <div className="my-3 md:mx-10 xl:mx-10">
                <img src={doc.photo} alt="doctor" className='h-[200px] w-[200px]'/>
              <p className=' font-montserrat text-black my-1'>
                  {doc.name}
              </p>
              <p className=' font-montserrat text-black'>
                  {doc.title}
              </p>
              <p className=' font-montserrat text-black'>
                  Call us: {doc.phone}
              </p>
              <p className=' font-montserrat text-black'>
                  Text or Whatsapp : {doc.text}
              </p>
              <p className=' font-montserrat text-black'>
                  Email : {doc.email}
              </p>
              <p className=' font-montserrat text-black'>
                  Office : {doc.Office}
              </p>
             
              </div>
            </TabPanel>
        </Tabs>
     
    

        
</div>
)
}

export default Counceling