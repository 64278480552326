import React, { useState } from 'react'
import _ from "lodash";
import Bidey from "../../../assets/images/profle/leadership/1.jpg"
import {Rectorate} from "../../../data/leaders"
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';


const DBio = () => {
    const {state} = useLocation()
    const bio = state.bio
  return (
    <div className='flex flex-col justify-center items-center'>

    
    <div className="w-[80%] justify-center items-center">
        <div>
          <p className="uppercase text-5xl sm:text-2xl xs:text-2xl text-black font-bold mx-10 font-montserrat">
            University Leadership
          </p>
        </div>
        <div className="mt-5 h-[2px] bg-black w-full"></div>
        

    <div className='flex flex-col my-2 md:mx-3 xl:mx-3'>
    <p className='text-black text-3xl sm:text-2xl xs:text-base md:my-3 xl:my-3 sm:my-2 xs:my-2'>
    The {bio.tiltle} at Benadir University
    </p>
    <div className='flex flex-row sm:flex-col xs:flex-col'>
    <img src={bio.photo} alt="rector" className='h-[250px] w-[250px] rounded-md' />
    <div className='md:mx-10 xl:mx-10 mx-3 text-black font-montserrat space-y-4'>
          <p>{bio.name}</p>
          <p className='whitespace-pre-line'>{parse(bio.titleDesc)}</p>
    </div>
    </div>
    <div className=' text-black space-y-3 md:my-3 xl:my-3 sm:my-10 xs:my-10 font-montserrat'>
     <p className='whitespace-pre-line'>{bio.desc}</p>
    </div>
    </div>
    </div>
    </div>
)
}

export default DBio