import React from 'react'
import { Link, useLocation } from 'react-router-dom'


function PartnerDetails() {
  const {state} = useLocation()
  const s = state.partner;
  // console.log(s)

  return (
    <div className='flex flex-col  pt-32'>

        <div className='flex flex-col justify-start items-start mx-10'>
          <img src={s.logo} alt="name" className='h-[200px]' />
          <p className='text-2xl font-semibold'>{s.name}</p>
          <p className='text-xl font-semibold'>{s.country}</p>
        <hr />
        <p>{s.desc}</p>
        </div>
        <div className='grid md:grid-cols-3 xl:grid-cols-4 grid-cols-1'>
          {
            s.imgs.length > 0 && 
            s.imgs.map( (m,i) => (
              <img key={i} src={m} alt="name" className='w-[300px]' />
            ))
          }
        </div>


    </div>
  )
}

export default PartnerDetails