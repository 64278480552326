import React from "react";
import {  NavLink, Link } from "react-router-dom";

import parse from 'html-react-parser';
import { MdOutlineDateRange } from "react-icons/md";
import { recent, upcoming } from "../../../data/conference";

const sample = "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/ractor.jpg";

const Conferences = props => {

  
  return (
      <div className="w-full flex flex-col" >
        <div className="mx-10 md:my-5 xl:my-5 my-3">
          <p className="text-3xl sm:text-xl xs:text-xl">Upcoming Conferences</p>
          <div className="grid grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 gap-4 my-5 text-black">
            {
              upcoming.map( u => (
              <Link key={u.id} to="" className="flex flex-col border-2 shadow-lg hover:shadow-2xl">
              <img src={u.img} alt="research confrernce" className="w-full md:h-[300px] xl:h-[300px]sm:h-[200px] xs:h-[200px]" />
              <p className="ml-3 my-1 text-xl xs:text-base xs:font-semibold line-clamp-1">{parse(u.title)}</p>
              <p className="ml-3 text-base xs:text-sm line-clamp-1">{parse(u.desc)}</p>
              <p className="ml-3 flex flex-row space-x-1 my-3 place-items-center italic sm:text-sm"><MdOutlineDateRange /><span className="font-inriaserif">{u.date}</span></p>
              </Link>
              ))
            }
          </div>
        </div>

        <div className="mx-10 my-5">
          <p className="text-3xl sm:text-xl xs:text-xl">Recent Conferences</p>
          <div className="grid grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 gap-4 my-5 text-black">
            
            {
              upcoming.map( u => (
              <Link key={u.id} to="" className="flex flex-col border-2 shadow-lg hover:shadow-2xl">
              <img src={u.img} alt="research confrernce" className="w-full md:h-[300px] xl:h-[300px]sm:h-[200px] xs:h-[200px]" />
              <p className="ml-3 my-1 text-xl xs:text-base xs:font-semibold line-clamp-1">{parse(u.title)}</p>
              <p className="ml-3 text-base xs:text-sm line-clamp-1">{parse(u.desc)}</p>
              <p className="ml-3 flex flex-row space-x-1 my-3 place-items-center italic sm:text-sm"><MdOutlineDateRange /><span className="font-inriaserif">{u.date}</span></p>
              </Link>
              ))
            }
          </div>
        </div>
        
      </div>

  );
};

export default Conferences;