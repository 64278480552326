import React from 'react'
import {  useParams,useNavigate } from 'react-router-dom'
import {careers} from '../../../data/careers'
import { GrLinkPrevious } from 'react-icons/gr';
import parse from "html-react-parser";


const backet = "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets"

const icon1 = backet + "/careers/cameraman.PNG"
const icon2 = backet + "/careers/professional.PNG"
const cover =  backet + '/others/cover.jpeg'

const SingleCareer = () => {
    const {careerid} = useParams();
    const navigate = useNavigate();

  return (
    <div className='bg-[#fff] flex flex-col '>
        <div className='relative w-full h-[558px] mx-h-[558px]'>
            <img src={cover} className='w-full object-cover h-[558px] mx-h-[558px]' alt="admission"/>
            <div className='absolute top-[80%]  w-full h-[15%] bg-white opacity-50 flex justify-center items-center'> </div>
            <p className=' text-6xl xs:text-4xl  font-bold text-[#0843aafe] text-center uppercase absolute w-full top-[83%]'>
                Careers
            </p>
        
        </div>
    <div className='my-10 md:mx-20 xl:mx-20 md:px-20 xl:px-20 mx-3'>
        <div className=''>
            <GrLinkPrevious onClick={() => navigate(-1)} className='bg-[#29b451fe] hover:bg-[#0843aafe] rounded-full p-3 text-white text-5xl'/>
        </div>
    <div className='grid grid-rows-1 mt-10 text-black'>
  
      {   careers.filter((c)=>{
                  if((c.careerid).toString() === careerid){
                    return c
                  }
                  else{
                    (<div>404 not found</div>)
                    return 0;
                  }
                }).map((c,i)=> ( 
                <div key={c.id}>
                  <div  className='flex flex-row  space-y-1'>
                    <div>
                        <div className='flex flex-row justify-start space-x-5 my-3 '>
                            <p className='text-base font-bold w-[80px]'>Title:</p>
                            <p className='text-base font-normal'>{c.title}</p>
                        </div>
                        <div className='flex flex-row justify-start space-x-5 my-3'>
                            <p className='text-base font-bold w-[80px]'>Location:</p>
                            <p className='text-base font-normal'>{c.location}</p>
                        </div>
                    {  c.jobType &&  <div className='flex flex-row justify-start space-x-5 my-3'>
                            <p className='text-base font-bold w-[80px]'>Job Type:</p>
                            <p className='text-base font-normal'>{c.jobType}</p>
                        </div>}
                        {c.role && <div className='flex flex-row justify-start space-x-5 my-3'>
                            <p className='text-base font-bold w-[80px]'>Role:</p>
                            <p className='text-base font-normal'>{c.role}</p>
                        </div>}
                        <div className='flex flex-col justify-start space-x-5'>
                            <p className='text-base font-bold'>{c.title1.title}</p>
                            <p className='text-base font-normal my-3 whitespace-pre-line'>{parse(c.title1.desc)}</p>
                        </div>
                        {c.title2 && 
                            <div className='flex flex-col justify-start space-x-5'>
                                <p className='text-base font-bold my-3'>{c.title2.title}</p>
                                <p className='text-base my-1 font-semibold'>{c.title2.desc}</p>
                                <ol className='list-disc'>
                                    {c.title2.lst.map( (q,i) => (<li key={i} className='text-gray-700 mx-3 py-1'>{q}</li> ))}</ol>
                            </div>
                        }
                        {c.title3 && 
                            <div className='flex flex-col justify-start space-x-5'>
                                <p className='text-base font-bold my-3'>{c.title3.title}</p>
                                <p className='text-base my-1 font-semibold'>{c.title3.desc}</p>

                                <ol className='list-disc'>
                                    {c.title3.lst.map( (re,i) => (<li key={i} className='text-gray-700 py-1 mx-3 '>{re}</li> ))}</ol>
                            </div>
                        }
                       
                        {c.Howtoapply.length > 0 && 
                            <div className='flex flex-col justify-start space-x-5'>
                                <p className='text-base font-bold my-3'>How to apply</p>
                                <ol className='list-disc'>
                                    {c.Howtoapply.map( (r,i) => (<li key={i} className='text-gray-700 py-1 mx-3'>{parse(r)}</li> ))}</ol>
                            </div>
                        }
                    </div>
                  </div>
                      </div>
        ))}
    </div>
     
        </div>
    </div>
  )
}

export default SingleCareer