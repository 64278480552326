import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import api from "../../../config.json";
import { Formik, Field } from "formik";
import { RenderUploadButton } from "../../general/renderSingleFile";
import { RenderMultipleUploadButton } from "../../general/renderMultipleFiles";
import { updateNews } from "../../../services/newsServices";

const FILE_SIZE = 1024;
const MAX_FILE_SIZE = 1024 * 1024 * 3;
function EditNews() {
  const { state } = useLocation();
  const [titleImageFile, setTitleImageFile] = useState();
  const [imageLists, setImageLists] = useState([]);

  const navigate = useNavigate();

  const validFileExtensions = {
    image: ["jpg", "png", "jpeg"],
  };

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  const validationSchema = yup.object().shape({
    title: yup.string("Enter news title").required("title is required"),
    content: yup.string("Enter news content").required("content is required"),
    updatedby: yup.string("Enter updated by").required("Updated by required"),
    titleImageCaption: yup
      .string("Enter title image caption")
      .required("image caption is required"),
    titleImage: yup
      .mixed()
      .required("upload title image.")
      .test("is-valid-type", "Not a valid image type", (value) =>
        isValidFileType(value && value.name, "image")
      )
      .test(
        "FILE_SIZE",
        "Max allowed size is 3MB",
        (value) => !value || (value && value.size <= MAX_FILE_SIZE)
      ),

    imageList: yup
      .mixed()
      .required("upload atleas one image.")
      .test("is-valid-type", "Not a valid image type", (value) => {
        if (!value || (value && value?.length > 0)) {
          for (let i = 0; i < value.length; i++) {
            if (!isValidFileType(value && value[i].name, "image")) {
              return false;
            }
          }
        }
        return true;
      })
      .test("FILE_SIZE", "Max allowed size is 3MB", (value) => {
        if (!value || (value && value?.length > 0)) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].size <= MAX_FILE_SIZE) {
              return true;
            }
          }
        }
        return false;
      }),
  });

  // const url = "http://localhost:4000/";
  const url = api.url;

  function GetFilename(url) {
    if (url) return url.substring(url.lastIndexOf("\\") + 1);
    return "";
  }

  useEffect(() => {
    async function createFile(url, path) {
      if (path !== null || path !== undefined) {
        const fileName = GetFilename(path);
        const path1 = path.replace(/\\/g, "/");
        const response = await fetch(url + path1);
        const data = await response.blob();
        let metadata = {
          type: data.type || "image/jpeg",
        };
        let file = new File([data], fileName, metadata);
        setTitleImageFile(file);
      }
    }
    createFile(url, state.news.titleImageUrl);

    async function createFileList(url, path) {
      setImageLists([]);
      if (path !== null || path !== undefined) {
        var images = [];

        path.forEach(async (e) => {
          const fileName = GetFilename(e.imageUrls);
          const path1 = e.imageUrls.replace(/\\/g, "/");
          const response = await fetch(url + path1);
          const data = await response.blob();
          let metadata = {
            type: data.type || "image/jpeg",
          };
          let file = new File([data], fileName, metadata);
          images.push(file);
        });

        setImageLists(images);
      }
    }
    createFileList(url, state.news.imageList);
  }, []);

  return (
    <div>
      <div className="px-5 py-6 mx-5 my-3">
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: state.news.title,
            tag: state.news.tag,
            titleImage: titleImageFile,
            titleImageCaption: state.news.titleImageCaption,
            content: state.news.newsContent,
            imageList: imageLists,
            updatedby: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const resp = await updateNews({
              newsId: state.news.newsId,
              _id: state.news._id,
              ...values,
            });
            navigate("/mynews");
          }}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form
              onSubmit={handleSubmit}
              className="w-full flex justify-start flex-col items-start px-8">
              <div className="w-full mt-10">
                <input
                  placeholder="Enter news title"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                />
                {errors.title && touched.title && errors.title}
              </div>
              <div className="w-full mt-10">
                <input
                  placeholder="Enter news tag"
                  name="tag"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tag}
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                />
                {errors.tag && touched.tag && errors.tag}
              </div>
              <div className="my-10 border-2 shadow-lg w-full p-3">
                {RenderUploadButton(
                  setFieldValue,
                  values,
                  errors,
                  "titleImage",
                  "image"
                )}
                {errors.titleImage && touched.titleImage && errors.titleImage}
              </div>

              <div className="w-full mt-10">
                <input
                  placeholder="Enter image caption"
                  name="titleImageCaption"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.titleImageCaption}
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                />
                {errors.titleImageCaption &&
                  touched.titleImageCaption &&
                  errors.titleImageCaption}
              </div>
              <div className="w-full mt-10">
                <textarea
                  className="my-2 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="News Content"
                  name="content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                  rows="8"
                  cols="50"
                />
                {errors.content && touched.content && errors.content}
              </div>
              <div className="my-10 border-2 shadow-lg w-full p-3">
                {RenderMultipleUploadButton(
                  setFieldValue,
                  values,
                  errors,
                  "imageList",
                  "image"
                )}
                {errors.imageList && touched.imageList && errors.imageList}
                <hr className="my-3" />

                {values.imageList && (
                  <div className="flex flex-row overflow-x-auto">
                    {Array.isArray(values.imageList)
                      ? values.imageList.map((f, i) => (
                          <div key={i} className="m-3 flex ">
                            <div className="flex w-fit justify-between mr-5  absolute bg-white  p-2 text-black font-bold">
                              {f.size > 1024 && f.size < 1000000 ? (
                                <span className="italic">{`${Math.round(
                                  parseFloat(f.size) / FILE_SIZE
                                )} KB`}</span>
                              ) : (
                                <span className="italic">{`${Math.round(
                                  parseFloat(f.size) / (1024 * 1024)
                                )} MB`}</span>
                              )}
                            </div>

                            <img
                              alt="not fount"
                              className="w-[550px]"
                              src={URL.createObjectURL(f)}
                            />
                          </div>
                        ))
                      : [...values.imageList].map((f, i) => (
                          <div key={i} className="m-3 flex ">
                            <div className="flex w-fit justify-between mr-5  absolute bg-white  p-2 text-black font-bold">
                              {f.size > 1024 && f.size < 1000000 ? (
                                <span className="italic">{`${Math.round(
                                  parseFloat(f.size) / FILE_SIZE
                                )} KB`}</span>
                              ) : (
                                <span className="italic">{`${Math.round(
                                  parseFloat(f.size) / (1024 * 1024)
                                )} MB`}</span>
                              )}
                            </div>

                            <img
                              alt="not fount"
                              className="w-[550px]"
                              src={URL.createObjectURL(f)}
                            />
                          </div>
                        ))}
                  </div>
                )}
              </div>

              <div className="w-full mt-10">
                <input
                  placeholder="Updated by"
                  name="updatedby"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.updatedby}
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                />
                {errors.updatedby && touched.updatedby && errors.updatedby}
              </div>
              <div className="w-full mt-12 space-y-3">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-blue-500 text-[18px] font-medium text-white py-3 w-fit px-5  focus:outline-none rounded-[3px] uppercase hover:bg-[#465B53]"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default EditNews;
