import React, { useState,useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineClose, AiOutlineWarning } from "react-icons/ai";
import * as yup from "yup";
import { Formik, Field, Form, useField, useFormikContext } from "formik";
import { Link } from "react-router-dom";
import PageTitle from "../../general/pageTitle";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { saveDepts,getAllDepts, deleteDepts } from "../../../services/departmentServices";
import { findStaffDepts } from "../../../services/employStaff";
import _ from 'lodash'


function CreateDepts(props) {
    const [depts, setDepts] = useState([])
    const [togle, setTogle] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [currentDept, setCurrentDept] = useState();
    const [staffDetps, setStaffDetps] = useState([]);
    const [cofirmError, setCofirmError] = useState("");
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");

    const fields = [{value: "code", name:"Department Code"}, 
                    {value: "department", name:"Department Name"}]


  const validationSchema = yup.object().shape({
    code: yup.string("enter department code").required("enter department code"),
    department: yup
      .string("enter department name.")
      .required("enter department name."),
  });

  useEffect( () => {

    async function fetchData() {
        const response = await getAllDepts();
        // const userNames = response.data.map(res => res.username)
        setDepts(response.data);
      }
      fetchData();
  },[])

  const handleDelete = async(d) => {
    const depts = await findStaffDepts(d.department);
    setStaffDetps(depts.data)
    setTogle(!togle);
    setCurrentDept(d);
  }

  const showModel = () => {
    setConfirm("");
    setCofirmError("");
    setTogle(!togle);
  };

  const handleDelateStaff = async (d) => {
    if (d) {
      if (confirm === d.code) {
        setCofirmError("");
        setConfirm("");
        const resp = await deleteDepts(d.code);
        if(resp.data){
          setTogle(!togle);
          await handleReferesh()}
      } else {
        setCofirmError("pleaes confirm deletion!");
      }
    }
  };

  const handleSelect = (e) => {
    setFilter(e.currentTarget.value)
    handleReferesh()
    setSearch("")
}
const handleSearch = () => {

  if(search){
    const result = _.find(depts,[filter, search.trim()])
    setDepts([result])
  }
}

const handleReferesh = async() => {
  const response = await getAllDepts();
  setDepts(response.data);
}

  return (
    <>
    <div>
      <PageTitle title="Create Departments" subTitle="Staff" />

      <div className=" w-full overflow-auto  ">
        <div className="flex flex-col justify-center p-4 ">
          <Formik
            initialValues={{
              code: "",
              department: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              const resp = await saveDepts(values)
              actions.setFieldValue("code","")
              actions.setFieldValue("department","")

            }}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col bg-white border shadow-sm rounded-xl"
              >
                <div className="flex justify-between items-center py-2.5 px-4 border-b">
                  <h3 className="font-bold text-gray-800">
                    Register new Department
                  </h3>
                  <Link
                    to="/staff-list"
                    className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                  >
                    <IoMdClose />
                  </Link>
                </div>
                <div className="p-4 overflow-y-auto">
                  <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Department code
                      </label>
                      <input
                        className="form-input"
                        name="code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                      />
                      {errors.code && touched.code && errors.code}
                    </div>
                    <div className="mb-3">
                      <label className="text-gray-800 text-sm font-medium inline-block mb-2">
                        Department
                      </label>
                      <input
                        className="form-input"
                        name="department"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.department}
                        placeholder="Department name"
                      />
                      {errors.department &&
                        touched.department &&
                        errors.department}
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t my-4">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn bg-primary text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="flex justify-center items-center  px-4 ">
                <div className="flex flex-row items-center">
                  <select
                    name="filter"
                    value={filter}
                    onChange={(e) => handleSelect(e)}
                    className="form-select md:w-[250px] font-semibold"
                  >
                    {fields.map((f, i) => (
                      <option key={i} value={f.value}>
                        {f.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="form-input md:w-[400px] mx-3 text-[15px] "
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleSearch}
                  type="button"
                  className="btn bg-primary text-white mx-2"
                >
                  Find
                </button>
              </div>


                <div className="p-4">
      <div className="border rounded-lg overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">code</th>
                                    <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Department name</th>
                                    <th scope="col" className="px-6 py-3 text-sm text-gray-500  text-center md:w-[200px] xl:w-[200px]">Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {
                                    depts.map((d,i) => (
                                        <tr key={i}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">{d.code}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">{d.department}</td>
                                       
                                       
                                        <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium flex flex-row  justify-center">
                                            <Link onClick={() => handleDelete(d)}>
                                                <MdDelete
                                                    size={20}
                                                    className="cursor-pointer m-1 hover:text-primary"/>
                                            </Link>
                                        </td>
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
     </div>
     </div>
            
              </form>
            )}

          </Formik>
        </div>
      </div>
  </div>
  <div
        className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-30 ease-in duration-300 ${
          togle ? "" : "hidden"
        }`}
      >
        {/* <div className='bg-[#EDF4F4] xs:w-full  w-[300px] max:w-[500px]  overflow-auto pt-4 fixed '> */}
        <div className="flex flex-col justify-center ">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl">
            <div className="flex justify-between items-center py-2.5 px-4 border-b">
              <h3 className="font-bold text-gray-800">Delete Staff</h3>
              <button
                data-fc-dismiss
                type="button"
                className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                onClick={showModel}
              >
                <AiOutlineClose className="text-xl" />
              </button>
            </div>
            <div className="p-4 overflow-y-auto flex flex-row">
              <div className="mx-10">
                <AiOutlineWarning className="text-7xl text-danger" />
              </div>
              <div className="mx-7">
                {
                  currentDept && 
                  (staffDetps.length >0 ? (
                    <div>
                      <p>You can not remove a department unless you fire all its staff.</p>
                      {staffDetps.map( (d,i) => (
                        <p key={i} className="mx-3 font-semibold">{d.staffName} <span className="mx-2 font-normal">, works this department</span></p>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <p className="mt-1 text-gray-800">
                        Do you want to remove this department:
                          <br /><span className="font-semibold">{currentDept.code} </span></p>
                          <div className="w-full  my-3">
                            <input
                              required
                              placeholder="write the staff-ID"
                              name="titleImageCaption"
                              onChange={(e) => setConfirm(e.target.value)}
                              value={confirm}
                              className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                            />
                            <div className="mx-4 my-2">{cofirmError}</div>
                          </div>
                    </div>  
                  ))
                }
              </div>
            </div>
            <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t">
              <button
                data-fc-dismiss
                type="button"
                className="btn bg-secondary text-white"
                onClick={showModel}
              >
                No
              </button>
              <button
                data-fc-dismiss
                type="button"
                disabled={staffDetps.length >0}
                className="btn bg-warning text-white"
                onClick={() => handleDelateStaff(currentDept)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateDepts;
