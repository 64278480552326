import david from "../assets/images/advisors/international/David.jpg";
import Mahasweta from "../assets/images/advisors/international/Mahasweta.jpg";
import Megan from "../assets/images/advisors/international/Megan.jpg";
import Hiis from "../assets/images/advisors/international/hiis.jpg";
import kasthuri from "../assets/images/advisors/international/kasthuri.jpg";
import Obinna from "../assets/images/advisors/international/Obinna.jpg";
import Gary from "../assets/images/advisors/international/Gary.jpg";
import oliver from "../assets/images/advisors/international/oliver.jpg";
import Valecia from "../assets/images/advisors/international/Valecia.jpg";
import marian from "../assets/images/advisors/international/marian.jpg";
import zeeshan from "../assets/images/advisors/international/zeeshan.jpg";
import shahid from "../assets/images/advisors/international/shahid.jpg";
import Nasiruddin from "../assets/images/advisors/international/nazir-uddin.jpg";
import Ehsan from "../assets/images/advisors/international/ehsan.jpg";
import Asif from "../assets/images/advisors/international/Asif.jpg";

// import rage from "../assets/images/advisors/local/rage.jpg"
import fuje from "../assets/images/advisors/local/fuje.jpg";
import hassanKafi from "../assets/images/advisors/local/hassanKafi.png";
// import jalil from "../assets/images/advisors/local/jalil.jpg"
import guled from "../assets/images/advisors/local/guled.jpg"

export const internationals = [
  // {
  //   id: 1,
  //   avatar: david,
  //   name: "David Schultz. Ph.D",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "Distinguished Professor",
  //       place: "University of Minnesota",
  //     },
  //     { id: 2, title: "Hamline University", place: "" },
  //   ],
  // },
  // {
  //   id: 2,
  //   avatar: Hiis,
  //   name: "Dr. Hiis Ilmi Ph.D. MBA. M.H.A",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "Sr. Academic Advisor & International Relations",
  //       place: "Benadir University",
  //     },
  //     {
  //       id: 2,
  //       title: "University of California Irvine",
  //       place: "Northwestern University",
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   avatar: kasthuri,
  //   name: "Dr. Kasthuri Henry, PhD, CTP",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "Distinguished Professor",
  //       place: "University of Chicago",
  //     },
  //     { id: 2, title: "Global Humanistic University", place: "" },
  //   ],
  // },
  // {
  //   id: 4,
  //   avatar: Megan,
  //   name: "Megan Mankerian.",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "M.APrincipal Expert",
  //       place: "International Education Advisor",
  //     },
  //     { id: 2, title: "Creighton University Omaha, Nebraska (USA)", place: "" },
  //   ],
  // },
  // {
  //   id: 5,
  //   avatar: Obinna,
  //   name: "Obinna Onyekwana MD, MHA",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "Deputy Director",
  //       place: "Bill & Melinda Gates Foundation",
  //     },
  //   ],
  // },
  // {
  //   id: 6,
  //   avatar: Mahasweta,
  //   name: "Mahasweta Sakar, Ph.D.",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "Senior Associate Dean & Professor",
  //       place: "San Diego State University",
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   avatar: Gary,
  //   name: "Gary L. Kreps Ph.D",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "Distinguished Professor",
  //       place: "George Mason University",
  //     },
  //     {
  //       id: 2,
  //       title: "Executive Director and Professor",
  //       place: "University of Nevada, Las Vegas",
  //     },
  //     {
  //       id: 3,
  //       title: "Professor and Director of Graduate Studies",
  //       place: "Northern Illinois University",
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   avatar: oliver,
  //   name: "Oliver William, Ph.D. M.P.H. MSW",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "Distinguished Professor",
  //       place: "University of Minnesota",
  //     },
  //   ],
  // },
  // {
  //   id: 9,
  //   avatar: Valecia,
  //   name: "Dr. Valecia Baldwin. Ph.D.",
  //   occ: [{ id: 1, title: "Virginia University", place: "" }],
  // },
  {
    id: 10,
    avatar: marian,
    name: "Marian Warsame, MD, MSc, Ph.D.",
    occ: [
      {
        id: 1,
        title: "Senior Researcher",
        place: "School of Public Health and Community Medicine",
      },
      { id: 1, title: "University of Gothenburg, Sweden", place: "" },
    ],
  },
  {
    id: 11,
    avatar: shahid,
    name: "Prof. Dr. Shahid Pervez",
    occ: [
      {
        id: 1,
        title: "M.B., B.S., D.C.P. (London), Ph.D. (Histopath. London),",
        place: "FCPS (Histopath. Pakistan), FRCPath (UK)",
      },
      { id: 2, title: "COMSTECH Distinguished Scholar", place: "Professor & Consultant Histopathology," },
      { id: 3, title: "Departments of Pathology & Laboratory Medicine", place: "Faculty of Health Sciences" },
      { id: 4, title: "Medical College, The Aga Khan University", place: "" },
    ],
  },
  {
    id: 12,
    avatar: zeeshan,
    name: "Dr. Zeeshan Uddin",
    occ: [
      {
        id: 1,
        title: "Assistant Professor and Consultant Histopathologist",
        place: "The Aga Khan University, Karachi, Pakistan",
      }
    ],
  },
  {
    id: 13,
    avatar: Nasiruddin,
    name: "Dr. Nasiruddin, MBBS, FCPS (Histo, PK)",
    occ: [
      {
        id: 1,
        title: "FRCPath (Histo, UK)",
        place: "General Histopathology, Bone and Soft Tissue, Head & Neck",
      },
      { id: 2, title: "The Aga Khan University, Karachi, Pakistan.", place: "" },
    ],
  },
  // {
  //   id: 14,
  //   avatar: Ehsan,
  //   name: "Dr. Seyed-Ehsan Elahi",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "PhD, University of Minnesota.",
  //       place: "Professor University of Massachusetts Boston",
  //     }
  //   ],
  // },
  // {
  //   id: 15,
  //   avatar: Asif,
  //   name: "Prof. Dr. Sheikh Asif S. Mizan",
  //   occ: [
  //     {
  //       id: 1,
  //       title: "BSS (Hon's), MSS, MBA, MACPM & PhD Rotarian, Criminologist, Good Governance & Human Rights Expert.",
  //       place: "Former Professor & Head, Department of Political Science, Asian University of Bangladesh. Life Member of Indian Political Science Association (IPSA)",
  //     }
  //   ],
  // },
];

export const local = [
  {
    id: 1,
    avatar: fuje,
    name: "Prof. Dr. Mohamed Mohamud Fuje",
    occ: [
      { id: 1, title: "Sr. Academic Advisor", place: "Benadir University" },
      {
        id: 2,
        title: "Professor & Dean of Postgraduate",
        place: "Benadir University",
      },
    ],
  },

  {
    id: 3,
    avatar: hassanKafi,
    name: "Dr. Hassan Kafi, PhD",
    occ: [
      {
        id: 1,
        title: "Sr. Envoiroment & climate Change advisor",
        place: "Benadir University",
      },
    ],
  },
  {id:4,
      avatar: guled,
      name: "Prof. Dr. Ahmed Y Guled",
      occ: [
          {id: 1, title: "Sr. Consultant in Chest Medicine", place: "Benadir University"},
      ]
  }
];
