import React, { useState } from 'react'
import paner from '../../../assets/images/profle/diversity/1.jpg'
import women from '../../../assets/images/profle/diversity/2.jpg'
import ReactPlayer from "react-player"

import { BiNews } from "react-icons/bi";
import _ from "lodash";


const backet =
    "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";
const img1 = backet + "/news/news01/1.jpg";

const Diversity = () => {

    const studentDiversity = [
        {
          id: 1,
          title: "Learning about difference",
          desc: `Learning about differences involves exploring and understanding the diverse facets of human experience, including but not limited to culture, ethnicity, religion, gender, sexual orientation, and ability. It's an essential journey towards empathy, acceptance, and inclusivity, fostering a richer understanding of the world around us. By actively seeking to comprehend and appreciate differences, we not only celebrate the unique identities of individuals but also cultivate a more harmonious and respectful society where everyone's voice is heard and valued`,
          img: img1,
        },
        {
          id: 2,
          title: "Making change, Transforming lives",
          desc: `Making change and transforming lives is about harnessing the power of compassion, innovation, and collective action to address societal challenges and uplift individuals and communities. It involves advocating for equity, justice, and opportunity for all, regardless of background or circumstance. Whether through grassroots activism, policy reform, or social entrepreneurship, making change and transforming lives requires empathy, resilience, and a commitment to long-term impact. By empowering people to realize their full potential, creating inclusive systems, and dismantling barriers to progress, we can create a more equitable and compassionate world where everyone has the opportunity to thrive.`,
          img: img1,
        },
        {
          id: 3,
          title: "World changing research",
          desc: `World-changing research embodies the relentless pursuit of knowledge and innovation, pushing the boundaries of human understanding and transforming the way we perceive and interact with the world. It encompasses groundbreaking discoveries that revolutionize industries, redefine scientific paradigms, and address pressing global challenges. From advancements in medicine that save lives and alleviate suffering to technological breakthroughs that reshape economies and societies, world-changing research is driven by curiosity, collaboration, and a commitment to improving the human condition. It serves as a catalyst for progress, inspiring future generations to push further, dream bigger, and envision a brighter future for all.`,
          img: img1,
        },
       
      ];


  return (
    <div className='flex flex-col justify-center items-center'>
       <div>
        <img src={paner} alt="" className='h-full w-full'/>
       </div>
       <div className='md:absolute xl:absolute relative md:w-[50%] xl:w-[50%] h-[40%] bg-[#0b62a9] top-[40%] md:opacity-70 xl:opacity-70  left-0 py-10 text-white px-10 rounded-r-3xl'> 
       <p className='md:text-5xl xl:text-5xl text-3xl font-bold my-2'>Explore Diversity</p>
       <p className='md:text-xl xl:text-xl text-sm font-normal font-sans'>Benadir University fosters an inclusive community, embracing diversity in its student body and faculty. With individuals from various backgrounds and cultures, the university promotes dialogue and mutual respect. This commitment to diversity enriches the educational experience and prepares students for a globalized world.</p>
       </div>
        {/* card one */}
       <div className=' grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 md:w-[80%] xl:w-[80%] my-10'> 
                <img src={women} alt="" className='md:w-[500px] xl:w-[500px] w-full' />
                <div className='mx-5 text-black place-content-center'>
                    <p className='my-5 text-2xl font-semibold'>Women human rights</p>
                    <p className='my-2 text-xl text-justify'>Ensuring women students' human rights on campus is vital for fostering a safe, inclusive learning environment. This includes access to education free from discrimination, harassment, and violence. Upholding women's rights entails addressing systemic barriers and biases that impede their educational journey. By championing women's rights, educational institutions contribute to the empowerment and advancement of women in society.</p>
                </div>
       </div>
            {/* card two */}
            <div className=' grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 md:w-[80%] xl:w-[80%] my-10'> 
                <div className='mx-5 text-black place-content-center col-span-1'>
                    <p className='my-5 text-2xl font-semibold'>Staff Diversity</p>
                    <p className='my-2 text-xl text-justify'>Benadir University values diversity among its staff, recognizing the strength that comes from varied backgrounds and perspectives. From faculty to administrative roles, the institution prioritizes inclusivity in its hiring practices. This commitment not only enriches the academic environment but also enhances the university's ability to address complex challenges. By supporting professional development and equal opportunities, Benadir University ensures all staff members can contribute to its mission of academic excellence and community engagement.</p>
                </div>
                <div className='col-span-1 h-[430px] '>
                <ReactPlayer
                    url='https://www.youtube.com/watch?v=HaNCVAiuVyE&t=945s'
                    width='100%'
                    height='100%'
                />
                </div>
            </div>
            {/* card two */}
            <p className='my-5 text-2xl font-semibold md:hidden xl:hidden'>Student Diversity</p>
            <div className=' grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 md:w-[80%] xl:w-[80%] my-10'> 
            <div className='col-span-1 h-[430px] '>
                <ReactPlayer
                    url='https://www.youtube.com/watch?v=ClX_810xKX8'
                    width='100%'
                    height='100%'
                />
                </div>
                <div className='mx-5 text-black place-content-center col-span-1'>
            <p className='my-5 text-2xl font-semibold xs:hidden sm:hidden'>Student Diversity</p>
                    
                    <p className='my-2 text-xl text-justify'>Student diversity enriches educational environments by fostering a tapestry of perspectives, experiences, and talents. It encompasses differences in culture, ethnicity, race, socioeconomic status, gender identity, abilities, and more. Embracing student diversity cultivates empathy, understanding, and respect among peers, preparing them to thrive in a globally interconnected world. Moreover, it promotes critical thinking and problem-solving skills as students engage with varied viewpoints and navigate complex societal issues collaboratively. Ultimately, celebrating student diversity not only enhances academic achievement but also nurtures inclusive communities where every individual feels valued and empowered to succeed.</p>
                </div>
                
            </div>

       <div className="grid grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 gap-10 justify-center   md:w-[80%] xl:w-[80%] my-10">
          {/* card one */}
          {studentDiversity.map((n) => (
            <div key={n.id} className="flex flex-col  relative bg-white hover:cursor-pointer ">
              <img src={n.img} alt="not found" className="xl:w-[500px]  md:w-[500px] " />
            <div>
              <p className='text-2xl font-bold text-black my-3'>{n.title}</p>
              <p className='text-justify text-base text-black'>{n.desc}</p>
            </div>
            </div>
          ))}
        </div>

</div>
)
}

export default Diversity