import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { announcements } from "../../../data/erusmas";
import parse from 'html-react-parser';
import _ from "lodash"


const ErasmusStudent = (props) => {
   
    const {id} = useParams()
   
    const announcement = _.find(announcements, ["link", id.toString()]);


  return (

    // panel
    <div className='pt-20'>

        <div className='relative w-full xl:h-[558px] md:h-[558px]  group'>
          <img src={announcement.cover} className='w-full object-cover xl:h-[558px] md:h-[558px]' alt="cover"/>
        </div>
        <div className='flex  my-10'>

            {
                   parse(announcement.desc)
            }
      </div>


    </div>
  )
}

export default ErasmusStudent;